import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Button, Form, Input, Layout} from 'element-react';
import { withNamespaces } from 'react-i18next';

function CancelFeeModal({t, isOpen, toggle, submit, update, cancelFee}) {
	return (
		<React.Fragment>
			<Form model={{}} rules={{}} labelWidth="160">
				<Modal backdrop="static" size="lg" isOpen={isOpen}>
					<ModalHeader>
						{t('factoryEvaluation.editCancelFee')}
					</ModalHeader>
					<ModalBody className="user-container scrollable-modal">
						<Layout.Row>
							<Form.Item label={t('factoryEvaluation.cancelFee')}>
								<Input value={cancelFee} onChange={value => update(value)}/>
							</Form.Item>
						</Layout.Row>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'center'}}>
						<Button onClick={toggle}>
							{t('global.cancel')}
						</Button>
						<Button type="primary" nativeType="submit" onClick={submit}>
							{t('global.submit')}
						</Button>
					</ModalFooter>
				</Modal>
			</Form>
		</React.Fragment>
	)
}

export default withNamespaces()(CancelFeeModal);
