import { apiGetVendorCompany, apiFindVendorCompany } from '../../api/vendor-company';
import { FETCH_VENDOR_COMPANY, FETCH_ONE_VENDOR_COMPANY, FILTER_VENDOR_COMPANY } from './vendor-company.action.types';

const fetchVendorCompanySuccess = (result) => ({
    type: FETCH_VENDOR_COMPANY,
    vendorCompanies: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchVendorCompany = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetVendorCompany(filterData);
            dispatch(fetchVendorCompanySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const fetchOneVendorCompanySuccess = (result) => ({
    type: FETCH_ONE_VENDOR_COMPANY,
    vendorCompanies: [result.data]
});

export const fetchOneVendorCompany = (id) => {
    return async dispatch => {
        try {
            const result = await apiFindVendorCompany(id);
            dispatch(fetchOneVendorCompanySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterVendorCompany = (filterData) => ({
    type: FILTER_VENDOR_COMPANY,
    filterData
});
