import axios from '../config/axios';

export function apiGetCountry(params) {
  return axios.get('countries', { params });
};

export function apiDeleteCountry(id) {
  return axios.delete(`countries/${id}`);
};

export function apiSaveCountry(data) {
  if (data.id) {
    return axios.put(`countries/${data.id}`, data);
  } else {
    return axios.post('countries', data);
  }
};

export function apiGetAllCountry() {
  return axios.get('fetch-data/countries');
};
