import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Checkbox } from 'element-react';
import { withNamespaces } from 'react-i18next';
import { Form } from '../Common';
import { apiGetPreference, apiResetPreference } from '../../api/preferences.js';
import ConfigOrderModal from './ConfigOrderModal';

class ConfigModal extends Component {
    _common = this.props.columns.filter((el) => el.type === 'common').length
    _csr = this.props.columns.filter((el) => el.type === 'csr').length
    _factoryExamination = this.props.columns.filter((el) => el.type === 'factory-examination').length
    _mfgProcess = this.props.columns.filter((el) => el.type === 'mfg-process').length
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            type: 'csr',
            nextStep: false,
        }
    }
    componentDidMount() {
        this.initData();
    }
    getColumnNumber = (type, index) => {
        if (type === 'common') {
            return index + 0
        } else if (type === 'csr') {
            return index + 100 - this._common
        } else if (type === 'factory-examination') {
            return index + 200 - (this._csr + this._common)
        } else if (type === 'mfg-process') {
            return index + 300 - (this._csr + this._common + this._factoryExamination)
        }
    }
    initData = async () => {
        this.setState({isOpen: this.props.isOpen})
        const  data  = await apiGetPreference('evaluation_listing');
        const columns = this.props.columns
        let preferences = Object.values(data)
        preferences.pop()
        let properArray = [];
        preferences.map((item, idx) => (
            columns.map((el, index) => {
                if (item.id === el.id) {
                    return properArray[idx] = {Number: this.getColumnNumber(el.type, index) + 1, id: el.id, type: el.type, Header: el.Header, skip: el.skip, checked: item.is_visible}
                } else {
                    return null
                }
            })
        ))
        this.setState({ data: properArray })
    }

    goBack = () => {
        this.setState({ nextStep: false, isOpen: true })
    }

    onChangeHandler = (item, e) => {
        item.checked = !item.checked;
    }

    handleReset = async () => {
        await apiResetPreference('evaluation_listing');
        this.initData();
    }

    nextStep = (e) => {
        e.preventDefault();
        this.setState({nextStep: true, isOpen: false})
    }

    onSectionSelect = (section) => {
        this.setState({ type: section });
    }

    render() {
        const { t, close } = this.props;
        return (
            <React.Fragment>
                <Modal backdrop="static" size="xl" isOpen={this.state.isOpen}>
                    <Form onSubmit={this.nextStep} ref="form" labelWidth="150">
                        <ModalHeader>
                            {t('configuration.columnsVisibilityTitle')}
                            <div style={{ position: 'absolute', top: '20px', right: '20px'}}>
                            <Button onClick={() => this.onSectionSelect('common')}>{t('configuration.common')}</Button>
                            <Button onClick={() => this.onSectionSelect('csr')}>{t('configuration.csr')}</Button>
                            <Button onClick={() => this.onSectionSelect('factory-examination')}>{t('configuration.factoryExam')}</Button>
                            <Button onClick={() => this.onSectionSelect('mfg-process')}>{t('configuration.mfgProcess')}</Button>
                            </div>
                        </ModalHeader>
                        <ModalBody className="user-container scrollable-modal">
                            {this.state.data ?
                                <table style={{width: '100%'}}>
                                    <tbody className='config-body config-display'>
                                    {this.state.data.map((item, value) => (
                                        !item.skip && item.type === this.state.type &&
                                        <React.Fragment key={value}>
                                        <tr className="config-row">
                                            <td className="config-data-num">{item.Number}</td>
                                            <td className="config-data-name">
                                            <Checkbox
                                                key={value}
                                                checked={item.checked}
                                                label={item.Header}
                                                onChange={(e) => this.onChangeHandler(item, e)} />
                                            </td>
                                        </tr>
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </table>
                                :
                                <p  style={{textAlign: 'center'}}><i className="el-icon-loading"/></p>
                            }
                        </ModalBody>
                        <ModalFooter>
                        <Button onClick={this.handleReset}>
                            {t('global.reset')}
                        </Button>
                        <Button onClick={() => close()}>
                            {t('global.cancel')}
                        </Button>
                        <Button type="primary" nativeType="submit">
                            {t('configuration.goToOrderScreen')}
                        </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
                {this.state.nextStep && <ConfigOrderModal
                    nextStep={this.state.nextStep}
                    data={this.state.data}
                    close={this.props.close}
                    goBack={this.goBack}
                />}
            </React.Fragment>
        )
    }
}

export default withNamespaces()(ConfigModal);
