import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Loading, Layout } from 'element-react';
import { DatePicker } from '../../components/Common';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from 'react-draggable';
import * as moment from 'moment';
import { getStepIds } from '../../utils/certificates';

class CreateDraftModal extends Component {

    fillStar = (e) => {
        let stars = document.getElementsByClassName('cert-star')
        stars = [].slice.call(stars);
        stars && stars.forEach(star => {
            if (star.dataset.value <= e.target.dataset.value) {
                star.innerHTML = '&#9733;'
            } else {
                star.innerHTML = '&#9734;'
            }
        })
    }

    render() {
        const { dataModel, t } = this.props;
        return (
            <React.Fragment>
              <Modal backdrop="static" isOpen={this.props.isOpen} className='draftmodal' id='section-to-print'>
                  <ModalHeader>
                    {t('certificationIssuance.createDraft')}
                    <Button style={{ position: 'absolute', right: '10px' }} onClick={() => this.props.toggle()}>{t('certificationIssuance.close')}</Button>
                  </ModalHeader>
                    <Loading loading={this.props.loading}>
                    <div className="buttons-container">
                        <Button
                            onClick={() => this.props.createPdf()}
                            style={{ flex: 1 }} type='primary'
                            disabled={
                                getStepIds(this.props.config, 'cert_draft_person').includes(this.props.user.id) ? false : true
                            }
                        >
                        原本作成/署名1依頼(TUV)
                        </Button>
                        <Button
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.sign')}
                        </Button>
                        <Button
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.handInDraft')}
                        </Button>
                        <Button
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.requestSignature')}
                        </Button>
                        <Button
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.sendConfirmation')}
                        </Button>
                    </div>
                    <div className="buttons-container">
                        {this.props.getScapFile(this.props.data) !== undefined ?
                            <Button type='primary'>
                                <span
                                    style={{ flex: 1 }}
                                    onClick={this.props.getScapFile(this.props.data) !== undefined ? () => this.props.openScapPreview(this.props.data) : null}>SCAP</span>
                            </Button> :
                            <Button disabled style={{ margin: 0}} type='primary'>SCAP</Button>
                        }
                        <Button
                            onClick={() => {}}
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.decline')}
                        </Button>
                        <Button
                            onClick={() => {}}
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.pullback')}
                        </Button>
                        <Button
                            onClick={() => {}}
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.remind')}
                        </Button>
                        <Button
                            onClick={() => {}}
                            style={{ flex: 1 }} type='primary'
                            disabled={true}
                        >
                        {t('certificationIssuance.cancel')}
                        </Button>
                    </div>
                    </Loading>
                  <ModalBody id="printable" className="user-container scrollable-modal">
                      <Layout.Row style={{ height: '20%', backgroundColor: '#d1e9ff', marginBottom: '10px' }}>
                          <div className='draft-header'>
                              <div style={{ position: 'relative', flex: 1 }}><img style={{ position: 'absolute', height: '66%', transform: 'translate(-40%, 15px)' }} src="img/header_logo_en.png" alt="logo" /></div>
                              <h1 style={{ flex: 1, margin: 0, letterSpacing: '5px', lineHeight: 2 }}>Certificate</h1>
                              <h1 style={{ flex: 1, margin: 0, letterSpacing: '2px', lineHeight: 2 }}>適合認証書</h1>
                          </div>
                      </Layout.Row>
                      <div className='section' style={{ height: '68%', backgroundColor: '#f0f8ff', position: 'relative', backgroundImage: "url('img/TUVRheinlandTriangle_802.png')", backgroundSize: '88%', backgroundRepeat: 'no-repeat' }}>

                      <Layout.Row style={{ width: '100%', textAlign: 'center' }}>
                              <div className='draft-header'>
                              <p style={{ order: 1, fontSize: '3em', fontWeight: '100', margin: '-15px 0 -10px 0', color: '#938953'}}>
                                  <span className='cert-star' data-value='1' onClick={(e) => this.fillStar(e)}>{[1, 2, 3].includes(dataModel.certification_issuance_id) ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                                  <span className='cert-star' data-value='2' onClick={(e) => this.fillStar(e)}>{[2, 3].includes(dataModel.certification_issuance_id) ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                                  <span className='cert-star' data-value='3' onClick={(e) => this.fillStar(e)}>{[3].includes(dataModel.certification_issuance_id) ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                              </p>
                              <p style={{ order: dataModel.is_domestic ? 2 : 3}}>"セブン＆アイグループお取引先サステナブル行動指針"</p>
                              <p style={{ order: dataModel.is_domestic ? 3 : 2}}>"SEVEN&i Group Business Partner Sustainable Action Guidelines"</p>
                              </div>
                      </Layout.Row>
                      <Layout.Row style={{  width: '100%', padding: '0 20px' }}>
                          <Layout.Col sm='9'>
                              <Draggable>
                                  <div style={{ height: '53px' }} className='flex-container'>
                                      <p style={{ order: dataModel.is_domestic ? 1 : 2}}>認証取得組織：</p>
                                      <p style={{ order: dataModel.is_domestic ? 2 : 1}}>Certificate Holder:</p>
                                  </div>
                              </Draggable>
                          </Layout.Col>
                          <Layout.Col sm='15'>
                              <Draggable>
                              {dataModel.is_domestic ?
                                  <div>
                                      <p contenteditable='true'>{dataModel ? dataModel.transaction_company_name_jp : ''}</p>
                                      <p contenteditable='true'>{dataModel ? dataModel.address_jp : ''}</p>
                                  </div> :
                                  <div>
                                      <p contenteditable='true'>{dataModel ? dataModel.transaction_company_name_en : ''}</p>
                                      <p contenteditable='true'>{dataModel ? dataModel.address_en : ''}</p>
                                  </div>
                              }
                              </Draggable>
                              <Draggable>
                              {dataModel.is_domestic ?
                                  <div>
                                      <p contenteditable='true'></p>
                                      <p contenteditable='true'></p>
                                  </div> :
                                  <div>
                                      <p contenteditable='true'>{dataModel ? dataModel.transaction_company_name_ch : ''}</p>
                                      <p contenteditable='true'>{dataModel ? dataModel.address_ch : ''}</p>
                                  </div>
                              }
                              </Draggable>
                          </Layout.Col>
                      </Layout.Row>
                      <Layout.Row style={{ width: '100%', padding: '0 20px' }}>
                          <Layout.Col sm='9'>
                              <Draggable>
                                  <div className='flex-container'>
                                      <p style={{ order: dataModel.is_domestic ? 1 : 2}}>要求事項：</p>
                                      <p style={{ order: dataModel.is_domestic ? 2 : 1}}>Requirement:</p>
                                  </div>
                              </Draggable>
                          </Layout.Col>
                          <Layout.Col sm='15' className='flex-container'>
                              <Draggable>
                                  <div style={{ order: dataModel.is_domestic ? 1 : 2}}>
                                      <p>評価の結果、貴組織の文書化及び実施状況において、ISO  26000:2010を適用したセブン＆アイグループお取引先サステナブル行動指針にもとづく要求事項を満たす証拠が提示されたことを証明します。</p>
                                  </div>
                              </Draggable>
                              <Draggable>
                                  <div style={{ order: dataModel.is_domestic ? 2 : 1}}>
                                      <p >An assessment was performed, and it was determined that the requirements according to Seven&i Group Business Partner Sustainable Action Guidelines for its application of ISO 26000:2010 have been satisfied with respect to documentation and practical implementation.</p>
                                  </div>
                              </Draggable>
                          </Layout.Col>
                      </Layout.Row>
                      <Layout.Row style={{ width: '100%', padding: '0 20px' }}>
                          <Layout.Col sm='9'>
                              <Draggable>
                                  <div className='flex-container'>
                                      <p style={{ order: dataModel.is_domestic ? 1 : 2}}>認証書番号：</p>
                                      <p style={{ order: dataModel.is_domestic ? 2 : 1}}>Certificate No.:</p>
                                  </div>
                              </Draggable>
                          </Layout.Col>
                          <Layout.Col sm='15'>
                              <Draggable>
                                  <div><input autoComplete="off" onChange={(e) => this.props.onChangeHandler(e.currentTarget.value, 'audit_number')} type='text' value={dataModel ? dataModel.audit_number : ''} className='draft-input' /></div>
                              </Draggable>
                          </Layout.Col>
                      </Layout.Row>
                      <Layout.Row style={{ width: '100%', padding: '0 0 0 20px' }}>
                          <Layout.Col sm='5'>
                              <Draggable>
                                  <div className='flex-container'>
                                      <p style={{ order: dataModel.is_domestic ? 1 : 2}}>監査年:</p>
                                      <p style={{ order: dataModel.is_domestic ? 2 : 1}}>Audit Year:</p>
                                  </div>
                              </Draggable>
                          </Layout.Col>
                          <Layout.Col sm='7'>
                              <Draggable>
                                  <div><DatePicker selectionMode="year" onChange={(value) => this.props.onChangeHandler(value, 'audit_year')} className='single-date' value={dataModel && dataModel.audit_year ? new Date(moment({ year: dataModel.audit_year })) : new Date(moment().year())}/></div>
                              </Draggable>
                          </Layout.Col>
                          <Layout.Col sm='6'>
                              <Draggable>
                                  <div className='flex-container'>
                                      <p style={{ order: dataModel.is_domestic ? 1 : 2}}>認証書発行日：</p>
                                      <p style={{ order: dataModel.is_domestic ? 2 : 1}}>Certificate Issue Date:</p>
                                  </div>
                              </Draggable>
                          </Layout.Col>
                          <Layout.Col sm='6'>
                              <Draggable>
                                  <div><DatePicker onChange={(value) => this.props.onChangeHandler(value, 'cert_issuance_date')} className='single-date' value={dataModel && dataModel.cert_issuance_date ? dataModel.cert_issuance_date : new Date(moment())}/></div>
                              </Draggable>
                          </Layout.Col>
                      </Layout.Row>
                      <Layout.Row style={{ position: 'absolute', bottom: '0', width: '100%' }}>
                          <Layout.Col sm='12'>
                              <div style={{ textAlign: 'left', width: '80%', margin: 'auto' }} className='flex-container'>
                                  <div className='signature-line'></div>
                                  <p style={{ order: dataModel.is_domestic ? 2 : 3}}>株式会社セブン＆アイ・ホールディングス</p>
                                  <p style={{ order: dataModel.is_domestic ? 3 : 2}}>SEVEN&i Holdings Co., Ltd.</p>
                              </div>
                          </Layout.Col>
                          <Layout.Col sm='12'>
                              <div style={{ textAlign: 'right', width: '80%', margin: 'auto' }} className='flex-container'>
                                  <div className='signature-line'></div>
                                  <p style={{ order: dataModel.is_domestic ? 2 : 3}}>テュフラインランドジャパン株式会社</p>
                                  <p style={{ order: dataModel.is_domestic ? 3 : 2}}>TÜV Rheinland Japan Ltd.</p>
                              </div>
                          </Layout.Col>
                      </Layout.Row>
                      </div>
                      <Layout.Row style={{ height: '12%' }}>
                          <div style={{ position: 'relative', width: '100%', height: '100%' }}><img style={{ position: 'absolute', height: '100%', right: '20px' }} src="img/tuv-rheinland-logo-vector.png" alt="tuv logo" /></div>
                      </Layout.Row>
                  </ModalBody>
                  <ModalFooter></ModalFooter>
              </Modal>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(CreateDraftModal);
