import { Notification } from 'element-react';

export default class Message {
	static info(message, title) {
		Notification({
			title: title,
			message: message,
			type: 'info',
			duration: 20000
		});
	}
	static error(message, title) {
		Notification({
			title: title,
			message: message,
			type: 'error',
			duration: 20000
		});
	}
	static success(message, title) {
		Notification({
			title: title,
			message: message,
			type: 'success',
			duration: 1000
		});
	}
}
