import React from 'react';
import { withNamespaces } from "react-i18next";
import { Popover as BSPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { apiFindOtherBusinessDetails } from '../../api/other-business';
import File from './File/File';

class OtherBusinessModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			evaluation: false,
			popoverOpen: false,
		};
		this.request = false;
	}

	componentWillUpdate(nextProps, nextState, nextContext) {
		if (!this.popoverOpen && nextState.popoverOpen && !this.request) {
			this.request = true;
			apiFindOtherBusinessDetails(this.props.id, this.props.evaluationId, this.props.lng).then(response => {
				this.setState({evaluation: response.data});
			});
		}
	}

	toggle = async () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	render() {
		const { id, t } = this.props;
		const { evaluation } = this.state;
		return (
			<React.Fragment>
                <span onClick={this.toggle}>
                    {this.props.children}
                </span>
				<BSPopover className="edit-content-container" placement={'bottom'} isOpen={this.state.popoverOpen} target={'testPopover-' + id} toggle={this.toggle}>
					<PopoverHeader>
						{evaluation && evaluation.business_company}
					</PopoverHeader>
					<PopoverBody className={'el-table-edit'} style={{padding: '20px', minWidth: '1000px'}}>
						{evaluation ?
							(Object.keys(evaluation).length ? <table>
								<tbody>
									<tr>
										<td>{t('otherBusiness.rootBusinessCompany')}:</td>
										<td>{evaluation.business_company ? evaluation.business_company : 'none'}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.auditNumber')}:</td>
										<td>{evaluation.audit_number ? evaluation.audit_number : 'none'}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.totalStatus')}:</td>
										<td>{evaluation.total_status ? evaluation.total_status : 'none'}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.latestEvaluationScore')}:</td>
										<td>{evaluation.latest_evaluation_score ? evaluation.latest_evaluation_score : 'none'}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.evaluationResult')}:</td>
										<td>{evaluation.evaluation_result ? evaluation.evaluation_result : 'none'}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.saqFile')}:</td>
										<td>{evaluation.saq_files.map(item => <File name={item.name} link={item.path} t={t} />)}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.scapFile')}:</td>
										<td>{evaluation.scap_files.map(item => <File name={item.name} link={item.path} t={t} />)}</td>
									</tr>
									<tr>
										<td>{t('otherBusiness.certificateFile')}:</td>
										<td>{evaluation.certificate_files.map(item => <File name={item.name} link={item.path} t={t} />)}</td>
									</tr>
								</tbody>
							</table> : 'No data')
						: 'Loading ...'}
					</PopoverBody>
				</BSPopover>
			</React.Fragment>
		)
	}
}

export default withNamespaces()(OtherBusinessModal);
