import * as actionTypes from './contact.actions';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    contacts: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTACT: return getContact(state, action);
    case actionTypes.FILTER_CONTACT: return filterContact(state, action);
    default: return state;
  }
};

const getContact = (state, action) => updateObject(state, { 
    contacts: action.contacts,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterContact = (state, action) => updateObject(state, { 
    filterData: action.filterData
});

export default reducer;
