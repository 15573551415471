import { updateObject, paginationData } from '../../utils/utility';
import { FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE, FILTER_PRODUCT } from './product.action.types';

const initialState = {
    filterData: {
        ...paginationData
    },
    loading: true,
    products: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_PRODUCT_SUCCESS:
        return getProduct(state, action);
    case FETCH_PRODUCT_FAILURE:
        return {
            ...state,
            loading: false,
            products: [],
            error: action.payload,
        }
    case FILTER_PRODUCT:
        return filterProduct(state, action);
    default: return state;
  }
};

const getProduct = (state, action) => updateObject(state, {
    products: action.payload,
    loading: false,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterProduct = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
