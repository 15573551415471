import {
    FETCH_FACTORY_EVALUATION,
    FILTER_FACTORY_EVALUATION
} from './factory-evaluation.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    staticDataLoaded: false,
    filterData: {
        ...paginationData
    },
    factoryEvaluations: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FACTORY_EVALUATION: return getFactoryEvaluation(state, action);
    case FILTER_FACTORY_EVALUATION: return filterFactoryEvaluation(state, action);
    default: return state;
  }
};

const getFactoryEvaluation = (state, action) => updateObject(state, { 
    factoryEvaluations: action.factoryEvaluations,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterFactoryEvaluation = (state, action) => updateObject(state, { 
    filterData: action.filterData
});

export default reducer;
