import axios from '../config/axios';
import { convertFormData } from '../utils/utility';

export function apiGetFactoryEvaluation(params) {
    let map = [
        {source: 'factory_evaluations_$table.vendor_company_id:name_jp[]', target: 'factory_evaluations_$table.vendor_company_id[]'},
        {source: 'factory_evaluations_$table.vendor_company_id:name_ch[]', target: 'factory_evaluations_$table.vendor_company_id[]'},
        {source: 'factory_evaluations_$table.vendor_factory_id:name_jp[]', target: 'factory_evaluations_$table.vendor_factory_id[]'},
        {source: 'factory_evaluations_$table.vendor_factory_id:name_ch[]', target: 'factory_evaluations_$table.vendor_factory_id[]'},
    ];

    map.forEach(item => {
        if (params.hasOwnProperty(item.source)) {
            params[item.target] = params[item.source];
            delete params[item.source];
        }
    });

    return axios.get('factory-evaluations', { params });
};

export function apiFindFactoryEvaluation(id) {
    return axios.get(`factory-evaluations/${id}`);
};

export function apiDeleteFactoryEvaluation(id) {
    return axios.delete(`factory-evaluations/${id}`);
};

export function apiQuickUpdateFactoryEvaluation(id, data) {
    return axios.put(`factory-evaluations/${id}/quick-update`, data);
};

export function apiSaveFactoryEvaluation(data) {
    if (data.id) {
        const formData = convertFormData(data);
        formData.append('_method', 'PUT');
        return axios.post(`factory-evaluations/${data.id}`, formData);
    } else {
        return axios.post('factory-evaluations', convertFormData(data));
    }
};

export function apiSendEmail(id) {
    return axios.post(`factory-evaluations/${id}/send-email`);
};

export function apiGetFilters(name, params) {
    return axios.get(`factory-evaluations/filters/${name}`, {params});
};
