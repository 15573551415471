import ContactModel from './ContactModel';

export default class VendorCompanyModel {
	constructor(data = {}) {
		this.id = data.id;
		this.name_jp = data.name_jp;
		this.name_en = data.name_en;
		this.name_ch = data.name_ch;
		this.address = data.address;
		this.country_id = data.country_id;

   	this.business_companies = this.removeEmptyObjects(data.business_companies || []);
    this.product_categories = this.removeEmptyObjects(data.product_categories || []);
		this.contacts = data.contacts || [ new ContactModel() ];
	}

	removeEmptyObjects(data) {
        return data.filter(item => {
            return Object.keys(item).length;
        });
    }
}
