import React, { Component } from 'react';
import {
    Popover as BSPopover,
    PopoverHeader,
    PopoverBody
} from 'reactstrap';
import { Input } from './';


class NumberPopup extends Component {
    grid = document.querySelector('.scroll-grid');
    state = {
        popoverOpen: false,
        id: this.props.id,
        value: this.props.value,
        error: false,
    }

    componentDidUpdate() {
      if (this.state.popoverOpen) {
        this.grid.style.overflow = 'hidden';
      } else {
        this.grid.style.overflow = '';
        let height = window.innerHeight - 140;
        this.grid.style.height = height + 'px';
      }
    }
    toggle = async () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen,
            value: this.props.value,
            error: false,
        });
    }

    handleChange(value) {
        this.setState({ value: value});
    }

    onConfirm = async () => {
        if (this.state.value >= 0 && this.state.value <= 100) {
          await this.props.onConfirm(this.state.value)
          this.toggle();
        } else {
          this.setState({ error: true })
        }
    }

    onCancel = () => {
        this.setState({ popoverOpen: false })
    }

    render() {
        const { id, t } = this.props;
        return (
            <React.Fragment>
                <span onClick={typeof this.props.children === 'object' ? this.toggle : undefined}>
                    {this.props.children}
                </span>
            {typeof this.props.children === 'object' &&
                <BSPopover className="edit-content-container" placement={'bottom'} isOpen={this.state.popoverOpen} target={'numberPopover-' + id} toggle={this.toggle}>
                    <PopoverHeader></PopoverHeader>
                    <PopoverBody className={'el-table-edit'} style={{padding: '20px', maxWidth: '250px'}}>
                        <Input
                          type="number"
                          value={this.state.value}
                          min={0}
                          max={100}
                          onChange={(value) => this.handleChange(value)}
                        />
                        {this.state.error && <p style={{color: 'red'}}>{t('factoryEvaluation.validTotalEvaluation')}</p>}
                        <div className="el-table-edit__bottom" key="bottom">
                            <button onClick={this.onCancel}>
	                            {t('global.cancel')}
                            </button>
                            <button onClick={this.onConfirm}>
                              {t('global.submit')}
                            </button>
                        </div>
                    </PopoverBody>
                </BSPopover>}
            </React.Fragment>
        )
    }
}

export default NumberPopup;
