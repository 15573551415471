import axios from '../config/axios';

export function apiGetProductCategory(params) {
  return axios.get('product-categories', { params });
};

export function apiDeleteProductCategory(id) {
  return axios.delete(`product-categories/${id}`);
};

export function apiSaveProductCategory(data) {
  if (data.id) {
    return axios.put(`product-categories/${data.id}`, data);
  } else {
    return axios.post('product-categories', data);
  }
};

export function apiGetAllProductCategory() {
  return axios.get('fetch-data/product-categories');
};
