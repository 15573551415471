import { updateObject, paginationData } from '../../utils/utility';
import { FETCH_VENDOR_COMPANY, FETCH_ONE_VENDOR_COMPANY, FILTER_VENDOR_COMPANY } from './vendor-company.action.types';

const initialState = {
    filterData: {
        ...paginationData
    },
    vendorCompanies: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_COMPANY: return getVendorCompany(state, action);
    case FETCH_ONE_VENDOR_COMPANY: return getVendorCompany(state, action);
    case FILTER_VENDOR_COMPANY: return filterVendorCompany(state, action);
    default: return state;
  }
};

const getVendorCompany = (state, action) => updateObject(state, {
    vendorCompanies: action.vendorCompanies,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterVendorCompany = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
