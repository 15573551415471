import ContactModel from './ContactModel';

export default class CompanyModel {
	constructor(data = {}) {
		this.id = data.id;
		this.name = data.name;
		this.address = data.address;
		this.country_id = data.country_id;
		this.contacts = data.contacts || [ new ContactModel() ];
	}
}
