import axios from '../config/axios';

export function apiGetPreference(name) {
    return axios.get(`preference/${name}`);
}
export function apiSavePreference(data, name) {
    return axios.put(`preference/${name}`, data);
}
export function apiResetPreference(name) {
    return axios.put(`preference/${name}`);
}