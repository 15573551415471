import { FETCH_PRODUCT_CATEGORY, FILTER_PRODUCT_CATEGORY } from './product-category.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    productCategories: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_CATEGORY: return getProductCategory(state, action);
    case FILTER_PRODUCT_CATEGORY: return filterProductCategory(state, action);
    default: return state;
  }
};

const getProductCategory = (state, action) => updateObject(state, { 
    productCategories: action.productCategories,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterProductCategory = (state, action) => updateObject(state, { 
    filterData: action.filterData
});

export default reducer;
