import axios from '../config/axios';

export function apiGetRootProductCategory(params) {
  return axios.get('root-product-categories', { params });
};

export function apiDeleteRootProductCategory(id) {
  return axios.delete(`root-product-categories/${id}`);
};

export function apiSaveRootProductCategory(data) {
  if (data.id) {
    return axios.put(`root-product-categories/${data.id}`, data);
  } else {
    return axios.post('root-product-categories', data);
  }
};