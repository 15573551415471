import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loading, Button } from 'element-react';
import Draggable from 'react-draggable';
import { getStepEmails, b64toBlob } from '../../utils/certificates';

const CertPreviewModal = (props) => {
    const { t } = props;
    const steps = ['cert_draft', 'signature_1', 'hand_in_draft', 'signature_2_request', 'signature_2', 'confirmation', 'cert_receipt'];
    let emails = [
        getStepEmails(props.config, 'cert_draft_person'),
        getStepEmails(props.config, 'signature_one_person'),
        getStepEmails(props.config, 'hand_in_draft_person'),
        getStepEmails(props.config, 'request_signature_person'),
        getStepEmails(props.config, 'signature_two_person'),
        getStepEmails(props.config, 'confirmation_send_person')
    ];
    let idx = steps.indexOf(props.currentStep);
    return (
        <React.Fragment>
          <Draggable handle='.modal-header'>
          <Modal size='xl' backdrop="static" isOpen={props.isOpen} className="cert-preview">
              <ModalHeader>
                {t('certificationIssuance.certificatePreview')}
                <Button style={{ position: 'absolute', right: '10px' }} onClick={(e) => props.toggle(e)}>{t('certificationIssuance.close')}</Button>
              </ModalHeader>
                  {props.previewButtons &&
                  <Loading loading={props.loading}>
                  <div className='buttons-container'>
                      <Button
                          style={{ flex: 1 }} type='primary'
                          disabled={true}
                      >
                      原本作成/署名1依頼(TUV)
                      </Button>
                      <Button
                          style={{ flex: 1 }} type='primary'
                          onClick={() => props.addSignature(props.type)}
                          disabled={
                              props.type === 1 && props.data.signature_1_status === 2 ? true :
                              props.type === 2 && props.data.signature_2_status === 2 ? true :
                              props.type === 1 && !props.data.signature_1 ? false :
                              props.type === 2 && !props.data.signature_2 && props.data.signature_2_request ? false :
                              true
                          }
                      >
                      署名
                      </Button>
                      <Button
                          onClick={() => props.handInDraft()}
                          style={{ flex: 1 }} type='primary'
                          disabled={
                              !getStepEmails(props.config, 'hand_in_draft_person').includes(props.userEmail) ? true :
                              getStepEmails(props.config, 'hand_in_draft_person').includes(props.userEmail) && !props.data.signature_1 ? true :
                              props.data.hand_in_draft ? true : false
                          }
                      >
                      {t('certificationIssuance.handInDraft')}
                      </Button>
                      <Button
                          onClick={() => props.requestSignature()}
                          style={{ flex: 1 }} type='primary'
                          disabled={
                              !getStepEmails(props.config, 'request_signature_person').includes(props.userEmail) ? true :
                              getStepEmails(props.config, 'request_signature_person').includes(props.userEmail) && !props.data.hand_in_draft ? true :
                              props.data.signature_2_request ? true : false
                          }
                      >
                      {t('certificationIssuance.requestSignature')}
                      </Button>
                      <Button
                          onClick={() => props.sendConfirmation()}
                          style={{ flex: 1 }} type='primary'
                          disabled={
                              !getStepEmails(props.config, 'confirmation_send_person').includes(props.userEmail) ? true :
                              getStepEmails(props.config, 'confirmation_send_person').includes(props.userEmail)  && !props.data.signature_2_request ? true :
                              props.data.confirmation ? true : false
                          }
                      >
                      {t('certificationIssuance.sendConfirmation')}
                      </Button>
                  </div>
                  <div className='buttons-container'>
                      {props.getScapFile(props.data) !== undefined ?
                          <Button type='primary'>
                              <span
                                  style={{ flex: 1 }}
                                  onClick={props.getScapFile(props.data) !== undefined ? () => props.openScapPreview(props.data) : null}>SCAP</span>
                          </Button> :
                          <Button disabled style={{ margin: 0}} type='primary'>SCAP</Button>
                      }
                      <Button
                          onClick={() => props.decline(props.data, props.currentStep)}
                          style={{ flex: 1 }} type='primary'
                          disabled={['hand_in_draft', 'confirmation','cert_receipt'].includes(props.currentStep) ? true : emails[idx] && emails[idx].includes(props.userEmail) ? false : true}
                      >
                      {t('certificationIssuance.decline')}
                      </Button>
                      <Button
                          onClick={() => props.pullback(props.data, props.currentStep)}
                          style={{ flex: 1 }} type='primary'
                          disabled={['confirmation','cert_receipt'].includes(props.currentStep) ? true : emails[idx] && emails[idx-1].includes(props.userEmail) ? false : true}
                      >
                      {t('certificationIssuance.pullback')}
                      </Button>
                      <Button
                          onClick={() => props.remind(props.data, props.currentStep)}
                          style={{ flex: 1 }} type='primary'
                          disabled={emails[idx] && props.currentStep === 'signature_2' && emails[idx-1].includes(props.userEmail) ? false : true}
                      >
                      {t('certificationIssuance.remind')}
                      </Button>
                      <Button
                          onClick={() => props.cancel(props.data, props.currentStep)}
                          style={{ flex: 1 }} type='primary'
                          disabled={props.currentStep === 'cert_receipt' && emails[emails.length-1].includes(props.userEmail) && props.currentStep === 'cert_receipt' ? false : true}
                      >
                      {props.confirmationModal.isOpening ? <em className="fa fa-circle-notch fa-spin"></em> : t('certificationIssuance.cancel')}
                      </Button>
                  </div>
                  </Loading>
                  }
              <ModalBody className="user-container scrollable-modal">
                  <div style={{ width: '100%', height: '100%', minHeight: '600px' }}>
                  {props.data && props.b64Data && props.data.certificate_file && <iframe
	                  title="some_unique_name_2"
                      style={{ minHeight: '1200px' }}
                      src={URL.createObjectURL(b64toBlob(props.b64Data, 'application/pdf')) + '#toolbar=0&navpanes=0&scrollbar=0'}
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                      toolbar='0'
                      id="cert-preview"
                  />}
                  </div>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
          </Modal>
          </Draggable>
        </React.Fragment>
    );
}

export default CertPreviewModal;
