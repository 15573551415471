import { REHYDRATE } from 'redux-persist';

import { TOGGLE_SETTING, CHANGE_SETTING, CHANGE_LANGUAGGE, CHANGE_EVALUATION_FREEZE_PANE } from '../actions/actions';

const initialSettings = {
    /* Layout fixed. Scroll content only */
    isFixed: true,
    /* Sidebar collapsed */
    isCollapsed: false,
    /* Boxed layout */
    isBoxed: false,
    /* Floating sidebar */
    isFloat: false,
    /* Sidebar show menu on hover only */
    asideHover: false,
    /* Show sidebar scrollbar (dont' hide it) */
    asideScrollbar: false,
    /* Sidebar collapsed with big icons and text */
    isCollapsedText: false,
    /* Toggle for the offsidebar */
    offsidebarOpen: false,
    /* Toggle for the sidebar offcanvas (mobile) */
    asideToggled: false,
    /* Toggle for the sidebar user block */
    showUserBlock: false,
    /* Enables layout horizontal */
    horizontal: false,
    /* Full size layout */
    useFullLayout: false,
    /* Hide footer */
    hiddenFooter: false,
    /* language */
    language: 'jp',
    /* freeze pane index of evaluation table */
    freezePaneIndex: 6
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case REHYDRATE:
            return {
                ...state,
                ...action.payload ? action.payload.settings : {}
            };
        case TOGGLE_SETTING:
            return {
                ...state,
                [action.name]: !state[action.name]
            }
        case CHANGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        case CHANGE_LANGUAGGE:
            return {
                ...state,
                language: action.language
            };
        case CHANGE_EVALUATION_FREEZE_PANE:
            return {
                ...state,
                freezePaneIndex: action.freezePaneIndex
            };
        default:
            return state;
    }
}

export default settingsReducer;