import { apiGetCertificationIssuance } from '../../api/certification-issuance';
import {
    FETCH_CERTIFICATION_ISSUANCE,
    FETCH_ALL_CERTIFICATION_ISSUANCE,
    FILTER_CERTIFICATION_ISSUANCE
} from './certification-issuance.action.types';

const fetchCertificationIssuanceSuccess = (result) => ({
    type: FETCH_CERTIFICATION_ISSUANCE,
    certificationIssuances: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchCertificationIssuance = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetCertificationIssuance(filterData);
            dispatch(fetchCertificationIssuanceSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const fetchAllCertificationIssuanceSuccess = (result) => ({
    type: FETCH_ALL_CERTIFICATION_ISSUANCE,
    allCertificationIssuances: result.data
});

export const fetchAllCertificationIssuance = () => {
    return async dispatch => {
        try {
            const result = await apiGetCertificationIssuance({ limit: 'unlimited' });
            dispatch(fetchAllCertificationIssuanceSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterCertificationIssuance = (filterData) => ({
    type: FILTER_CERTIFICATION_ISSUANCE,
    filterData
});
