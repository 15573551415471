export default class InvoiceNumberModel {
	constructor(data = {}) {
		this.year = data.year;
		this.month = data.month;
		this.invoice_number = data.invoice_number;
		this.order_domestic_number = data.order_domestic_number;
		this.order_overseas_number = data.order_overseas_number;
		this.approval_domestic_number = data.approval_domestic_number;
		this.approval_overseas_number = data.approval_overseas_number;
		this.custom_year = data.custom_year || null;
	}
}
