import { apiGetStaticData, apiGetRolePage } from '../../api/static-data';

export const FETCH_STATIC_DATA = 'FETCH_STATIC_DATA';
export const FETCH_ROLE_PAGE = 'FETCH_ROLE_PAGE';
export const SET_STATIC_DATA_LOADED = 'SET_STATIC_DATA_LOADED';

export const setStaticDataLoaded = () => ({
    type: SET_STATIC_DATA_LOADED
});

const fetchStaticDataSucess = (result, table) => ({
    type: FETCH_STATIC_DATA,
    data: result.data,
    table
});

export const fetchStaticData = (table) => {
    return async dispatch => {
        try {
            const result = await apiGetStaticData(table);
            dispatch(fetchStaticDataSucess(result, table));
            return Promise.resolve(result.data);
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const fetchRolePageSuccess = (result) => ({
    type: FETCH_ROLE_PAGE,
    role_pages: result.data
});

export const fetchRolePage = () => {
    return async dispatch => {
        try {
            const result = await apiGetRolePage();
            dispatch(fetchRolePageSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};
