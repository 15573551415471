import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'element-react';
import { withNamespaces } from 'react-i18next';
import { Form } from '../Common';
import {apiSavePreference } from '../../api/preferences.js';

class ConfigOrderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            type: 'common',
            order: null
        }
    }
    componentDidMount() {
        this.initData();
    }

    initData = async () => {
        const  preferences  = this.props.data
        let order = preferences.filter(item => ((item.checked === true || item.checked === 1) && !item.skip))
        this.setState({ data: preferences, order: order })
    }

    convertData = () => {
        let newData = [];
        this.state.data.map((item, idx) => {
            if(!item.skip) {
                return newData[idx] = {id: item.id, is_visible: item.checked}
            } else {
                return newData[idx] = {id: item.id, is_visible: !item.skip}
            }
        })
        return newData
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let newData = this.convertData();
        await apiSavePreference(newData, `evaluation_listing`);
        this.props.close()
        window.location.reload();
    }

    handleClick = (item) => {
        if (!this.state.selected) {
            this.setState({ selected: item });
        } else if (this.state.selected && this.state.selected.id === item.id) {
            this.setState({ selected: null });
        } else if (this.state.selected && this.state.selected.id !== item.id) {
            this.replace(this.state.selected, item);
        } else {
            return;
        }
    }

    replace = (selected, target) => {
        let data = this.state.data;
        let order = this.state.order;
        let selected_in_order = order.indexOf(selected);
        let target_in_order = order.indexOf(target);
        let selected_in_data = data.indexOf(selected);
        let target_in_data = data.indexOf(target);

        let removed = data.splice(selected_in_data, 1)[0];
        data.splice(target_in_data, 0, removed);
        let r = order.splice(selected_in_order, 1)[0];
        order.splice(target_in_order, 0, r);

        this.setState({ data: data, order: order, selected: null });
    }

    onSectionSelect = (section) => {
        this.setState({ type: section });
    }

    render() {
        const { t, close } = this.props;
        const { selected } = this.state;
        return (
            <React.Fragment>
                <Modal backdrop="static" size="xl" isOpen={this.props.nextStep}>
                    <Form onSubmit={this.handleSubmit} ref="form" labelWidth="150">
                        <ModalHeader>
                            {t('configuration.columnsOrderTitle')}
                            <div style={{ position: 'absolute', top: '20px', right: '20px'}}>
                            <Button onClick={() => this.onSectionSelect('common')}>{t('configuration.common')}</Button>
                            <Button onClick={() => this.onSectionSelect('csr')}>{t('configuration.csr')}</Button>
                            <Button onClick={() => this.onSectionSelect('factory-examination')}>{t('configuration.factoryExam')}</Button>
                            <Button onClick={() => this.onSectionSelect('mfg-process')}>{t('configuration.mfgProcess')}</Button>
                            </div>
                        </ModalHeader>
                        <ModalBody className="user-container scrollable-modal">
                            {this.state.data ?
                                <table style={{width: '100%'}}>
                                    <tbody className='config-body config-order'>
                                    {this.state.order.map((item, value) => (
                                        item && item.id && item.type === this.state.type &&
                                        <React.Fragment key={value}>
                                        <tr className={ selected && selected.id === item.id ? 'config-row selected' : selected && selected.id !== item.id ? 'config-row awaiting' : 'config-row' } onClick={() => this.handleClick(item)}>
                                            <td className="config-data-num">{item.Number}</td>
                                            <td className="config-data-name">{item.Header}</td>
                                        </tr>
                                        </React.Fragment>
                                        ))
                                    }
                                    </tbody>
                                </table>
                                :
                                <p  style={{textAlign: 'center'}}><i className="el-icon-loading"/></p>
                            }
                        </ModalBody>
                        <ModalFooter>
                        <Button onClick={() => close()}>
                            {t('global.cancel')}
                        </Button>
                        <Button onClick={this.props.goBack}>
                            {t('configuration.previousScreen')}
                        </Button>
                        <Button type="primary" nativeType="submit">
                            {t('global.submit')}
                        </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(ConfigOrderModal);
