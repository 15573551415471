import { apiGetProductCategory } from '../../api/product-category';
import { FETCH_PRODUCT_CATEGORY, FILTER_PRODUCT_CATEGORY } from './product-category.action.types';

const fetchProductCategorySuccess = (result) => ({
    type: FETCH_PRODUCT_CATEGORY,
    productCategories: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchProductCategory = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetProductCategory(filterData);
            dispatch(fetchProductCategorySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterProductCategory = (filterData) => ({
    type: FILTER_PRODUCT_CATEGORY,
    filterData
});