import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input } from '../Common';
import { apiSaveProduct } from "../../api/product";
import Message from '../../utils/Message';

export class ProductEditForm extends Component {
    state = {
        isSubmitting: false,
        data: {
            id: this.props.product.id,
            name: this.props.product.name
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            let data = { ...this.state.data };
            data.id = this.props.product.id;
            data.name = this.props.product.name;
            await this.setState({ data })
        }
    }

    onChangeName = (value) => {
        const data = { ...this.state.data };
        data.name = value;
        this.setState({ data })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ isSubmitting: true });

	      await apiSaveProduct(this.state.data).then((res) => {
            this.setState({
                ...this.state,
                isSubmitting: false
            })
            Message.success(this.props.t('global.success'))
        })
        await this.props.onCancel();
        await this.props.refresh('products', true);
    }

    render() {
        const { t } = this.props;
        const data = this.state.data;

        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.isOpen} toggle={this.props.onCancel}>
                <ModalHeader toggle={this.props.onCancel}>Edit Product</ModalHeader>
                <ModalBody>
                    <Layout.Row>
                        <Layout.Col span="18" offset="2">
                            <Input
                                value={data.name ? data.name : ''}
                                onChange={(value) => this.onChangeName(value)}
                            />
                        </Layout.Col>
                    </Layout.Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.onCancel}>{t('global.cancel')}</Button>
                    <Button type="primary" onClick={this.handleSubmit}>
                        {this.state.isSubmitting ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.submit')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withNamespaces()(ProductEditForm);
