import { apiGetCompany } from '../../api/company';
import { FETCH_COMPANY, FILTER_COMPANY } from './company.action.types';

const fetchCompanySuccess = (result) => ({
    type: FETCH_COMPANY,
    companies: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchCompany = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetCompany(filterData);
            dispatch(fetchCompanySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterCompany = (filterData) => ({
    type: FILTER_COMPANY,
    filterData
});