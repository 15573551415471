import axios from '../config/axios';

export function apiGetContact(params) {
  return axios.get('contacts', { params });
};

export function apiDeleteContact(id) {
  return axios.delete(`contacts/${id}`);
};

export function apiSaveContact(data) {
  if (data.id) {
    return axios.put(`contacts/${data.id}`, data);
  } else {
    return axios.post('contacts', data);
  }
};