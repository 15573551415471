import { FETCH_COMPANY, FILTER_COMPANY } from './company.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    companies: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY: return getCompany(state, action);
    case FILTER_COMPANY: return filterCompany(state, action);
    default: return state;
  }
};

const getCompany = (state, action) => updateObject(state, { 
    companies: action.companies,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterCompany = (state, action) => updateObject(state, { 
    filterData: action.filterData
});

export default reducer;
