import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, Form, Input } from '../Common';
import { validateLength } from '../../utils/utility';
import { apiGetWorkingGroup, apiSaveProductSubCategory } from "../../api/product-sub-category";
import { ProductSubCategoryModel } from '../../models';
import Message from '../../utils/Message';

export class ProductSubCategoryForm extends Component {
    state = {
        rules: {
            name_jp: [
                { required: true, message: 'please input name', trigger: 'change' }
            ],
            name_en: [
                { required: true, message: 'please input name', trigger: 'change' }
            ],
        },
        isLoading: false,
        isSubmitting: false,
        data: {
            workingGroups: [],
        		parent_id: null,
            id: null,
            name_jp: '',
            name_en: ''
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            let data = { ...this.state.data };
            data.parent_id = null;
            data.id = null;
            data.name_jp = '';
            data.name_en = '';
            await this.setState({ data })
            if (!this.state.data.workingGroups.length) {
                await this.fetchWorkingGroups();
            }
        }
    }

    fetchWorkingGroups = async () => {
        this.setState({ isLoading: true });
    		const data = { ...this.state.data };
        let workingGroups = await apiGetWorkingGroup({ limit: 'unlimited' });
        data.workingGroups = workingGroups.data;

        this.setState({ data, isLoading: false });
    }

    selectWorkingGroup = (value) => {
    		const data = { ...this.state.data };
    		data.parent_id = value;
        this.setState({ data })
    }

    onChangeId = (value) => {
        const data = { ...this.state.data };
        data.id = value;
        this.setState({ data })
    }

    onChangeName = (value, lang) => {
        const data = { ...this.state.data };
        data[`name_${lang}`] = value;
        this.setState({ data })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ isSubmitting: true });

        this.refs.form.validate(async (valid) => {
            if (valid) {
                await apiSaveProductSubCategory(new ProductSubCategoryModel(this.state.data, true)).then((res) => {
                    let { data } = { ...this.state };
                		data.parent_id = null;
                    data.id = null;
                    data.name_jp = '';
                    data.name_en = '';
                    this.setState({
                        ...this.state,
                        data,
                        isSubmitting: false
                    })
                    Message.success(this.props.t('global.success'))
                })
                await this.props.onCancel();
                await this.props.refresh();
            } else {
                this.setState({ isSubmitting: false })
            }
        });
    }

    render() {
        const { t } = this.props;
        const data = this.state.data;

        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.isOpen} toggle={this.props.onCancel}>
                <Form onSubmit={this.handleSubmit} model={data} ref="form" rules={this.state.rules} labelWidth="160">
                    <ModalHeader toggle={this.props.onCancel}>Add Product Sub Category</ModalHeader>
                    <ModalBody>
                        <Layout.Row>
                            <Layout.Col span="18" offset="2">
                                <Form.Item label='working group' prop="parent_id">
                                    <Select
                                        value={data.parent_id ? data.parent_id : null}
                                        onChange={(value) => this.selectWorkingGroup(value)}
                                        options={this.state.data.workingGroups}
											                  label={`name_${this.props.lang}`}
                                        placeholder={this.state.data.workingGroups.length ? '' : 'loading...'}
                                    />
                                </Form.Item>
                                <Form.Item label='id' prop="id">
                                    <Input
                                        value={data.id ? data.id : ''}
                                        onChange={(value) => this.onChangeId(value)}
                                    />
                                </Form.Item>
                                <Form.Item label={t('global.name_jp')} prop="name_jp">
                                    <Input
                                        value={data.name_jp ? data.name_jp : ''}
                                        onChange={(value) => this.onChangeName(value, 'jp')}
                                    />
                                </Form.Item>
                                <Form.Item label={t('global.name_en')} prop="name_en">
                                    <Input
                                        value={data.name_en ? data.name_en : ''}
                                        onChange={(value) => this.onChangeName(value,'en')}
                                    />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Form.Item>
                            <Button onClick={this.props.onCancel}>{t('global.cancel')}</Button>
                            <Button type="primary" nativeType="submit">
                                {this.state.isSubmitting ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.submit')}
                            </Button>
                        </Form.Item>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(ProductSubCategoryForm);
