import { FETCH_BUSINESS_COMPANY, FILTER_BUSINESS_COMPANY } from './business-company.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    businessCompanies: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_COMPANY: return getBusinessCompany(state, action);
    case FILTER_BUSINESS_COMPANY: return filterBusinessCompany(state, action);
    default: return state;
  }
};

const getBusinessCompany = (state, action) => updateObject(state, { 
    businessCompanies: action.businessCompanies,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterBusinessCompany = (state, action) => updateObject(state, { 
    filterData: action.filterData
});

export default reducer;
