import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, Form, Input } from '../Common';
import { validateLength } from '../../utils/utility';
import { apiGetProductSubCategory, apiGetWorkingGroup } from "../../api/product-sub-category";
import { apiSaveProduct } from "../../api/product";
import { ProductModel } from '../../models';
import Message from '../../utils/Message';

export class ProductForm extends Component {
    state = {
        rules: {
            name: [
                { required: true, message: 'please input name', trigger: 'change' }
            ],
        },
        isSubmitting: false,
        data: {
            workingGroups: [],
            productSubCategories: [],
            subCategoriesLoading: false,
        		working_group_id: null,
        		product_sub_category_id: null,
            id: null,
            name: ''
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            let data = { ...this.state.data };
            data.productSubCategories = [];
            data.working_group_id = null;
            data.product_sub_category_id = null;
            data.id = null;
            data.name = '';
            await this.setState({ data })
            if (!this.state.data.workingGroups.length) {
                await this.fetchWorkingGroups();
            }
        }
    }

    fetchWorkingGroups = async () => {
    		const data = { ...this.state.data };
        let workingGroups = await apiGetWorkingGroup({ limit: 'unlimited' });
        console.log(workingGroups)
        data.workingGroups = workingGroups.data;

        this.setState({ data });
    }

    fetchProductSubCategories = async (parentId) => {
    		const data = { ...this.state.data };
        let productSubCategories = await apiGetProductSubCategory({ limit: 'unlimited', parent_id: parentId });
        console.log(productSubCategories);
        data.productSubCategories = productSubCategories.data;

        this.setState({ data });
    }

    selectWorkingGroup = (value) => {
    		const data = { ...this.state.data };
    		data.product_sub_category_id = null;
    		data.working_group_id = value;
        this.setState({ data }, async () => {
      			if (value) {
              await this.setState({ subCategoriesLoading: true })
      				await this.fetchProductSubCategories(value);
              await this.setState({ subCategoriesLoading: false })
      			}
        })
    }

    selectProductSubCategory = (value) => {
        const data = { ...this.state.data }
        data.product_sub_category_id = value;
        this.setState({ data });
    }

    onChangeId = (value) => {
        const data = { ...this.state.data };
        data.id = value;
        this.setState({ data })
    }

    onChangeName = (value) => {
        const data = { ...this.state.data };
        data.name = value;
        this.setState({ data })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ isSubmitting: true });

        this.refs.form.validate(async (valid) => {
            if (valid) {
                await apiSaveProduct(new ProductModel(this.state.data, true)).then((res) => {
                    this.setState({
                        ...this.state,
                        isSubmitting: false
                    })
                    Message.success(this.props.t('global.success'))
                })
                await this.props.onCancel();
                await this.props.refresh('products', true);
            }
        });
    }

    render() {
        const { t } = this.props;
        const data = this.state.data;

        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.isOpen} toggle={this.props.onCancel}>
                <Form onSubmit={this.handleSubmit} model={data} ref="form" rules={this.state.rules} labelWidth="160">
                    <ModalHeader toggle={this.props.onCancel}>Add Product</ModalHeader>
                    <ModalBody>
                        <Layout.Row>
                            <Layout.Col span="18" offset="2">
                                <Form.Item label='working group' prop="working_group_id">
                                    <Select
                                        value={data.working_group_id ? data.working_group_id : null}
                                        onChange={(value) => this.selectWorkingGroup(value)}
                                        options={data.workingGroups}
											                  label={`name_${this.props.lang}`}
                                        placeholder={data.workingGroups.length ? '' : 'loading...'}
                                    />
                                </Form.Item>
                                <Form.Item label='prod sub cat' prop="product_sub_category_id">
                                    <Select
                                        value={data.product_sub_category_id ? data.product_sub_category_id : null}
                                        onChange={(value) => this.selectProductSubCategory(value)}
                                        options={data.productSubCategories}
              											    label={`name_${this.props.lang}`}
                                        placeholder={!data.working_group_id ? '' : this.state.subCategoriesLoading ? 'loading...' : ''}
                                    />
                                </Form.Item>
                                <Form.Item label='id' prop="id">
                                    <Input
                                        value={data.id ? data.id : ''}
                                        onChange={(value) => this.onChangeId(value)}
                                    />
                                </Form.Item>
                                <Form.Item label={t('global.name')} prop="name">
                                    <Input
                                        value={data.name ? data.name : ''}
                                        onChange={(value) => this.onChangeName(value)}
                                    />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Form.Item>
                            <Button onClick={this.props.onCancel}>{t('global.cancel')}</Button>
                            <Button type="primary" nativeType="submit">
                                {this.state.isSubmitting ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.submit')}
                            </Button>
                        </Form.Item>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(ProductForm);
