import React from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from 'react-i18next';
import {Button} from 'element-react';
import {apiGetCsv} from '../../api/export';

class DownloadCsv extends React.Component {
	constructor(props) {
		super(props);
		this.state = {loading: false};
	}

	createBlob = (response) => {
		return new Blob(['\uFEFF' + response.data], {type: 'text/csv; charset=utf-18'});
	}

	download = () => {
		this.setState({loading: true}, () => {
			this.props.filters.page && delete this.props.filters.page;
			this.props.filters.limit && delete this.props.filters.limit;
			apiGetCsv(this.props.subject, this.props.lang, this.props.filters).then(response => {
				const fileName = this.props.subject + '.csv';
				const blob = this.createBlob(response);

				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, fileName);
					this.setState({loading: false});
				} else {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					this.setState({loading: false});
				}

			});
		});
	}

	render() {
		return (
			<Button
				type="default"
				disabled={this.props.disabled}
				onClick={this.download}
				loading={this.state.loading}>
				{this.props.t('global.exportToCsv')}
			</Button>
		)
	}
}

DownloadCsv.propTypes = {
	subject: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};

DownloadCsv.defaultProps = {
	lang: 'jp',
	disabled: false
};

export default withNamespaces()(DownloadCsv);
