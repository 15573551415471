import axios from 'axios';
import {getToken} from '../utils/auth';
import Message from '../utils/Message';

// create an axios instance
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API, // url = base url + request url
    // baseURL: 'http://knj-csr.ipsip-dev.net/backend/public/api/', // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 90000, // request timeout
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    // paramsSerializer: function(params) {
    //     return Object.keys(params).map(key => params[key] ? key + '=' + params[key] : '')
    //         .filter((key) => key !== '' && key !== undefined).join('&');
    // }
});

// request interceptor
instance.interceptors.request.use(
    config => {
        // do something before request is sent
        config.headers['Authorization'] = `Bearer ${getToken()}`;

        return config;
    },
    error => {
        // do something with request error
        return Promise.reject(error);
    }
);

// response interceptor
instance.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
	    const res = response.data;
	    return {
	    	...res,
		    status: response.status
	    };
    },
    error => {
        let showMsg = false;
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data) {
                error.message = error.response.data.message;
            }
            if (error.message) {
                const notify = typeof(error.message) === 'object' ? Object.values(error.message) : [error.message];
                if (notify && notify.length) {
                    showMsg = true;
					Message.error(notify.join('\n'), 'エラー');
                }
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
        } else {
            // Something happened in setting up the request that triggered an Error
            showMsg = true;
	        Message.error(error.message, 'エラー');
        }
        if(!showMsg) {
	        Message.error('何かがうまくいきませんでした！', 'エラー');
        }
        return Promise.reject(error);
    }
);

export default instance;
