import React from 'react';

const Document = (props) => {

    const {
        baseUrl,
        link,
        name,
        direct,
	    isWarning
    } = props;

    return (
        <a href={direct ? `${baseUrl}/download?file=${link}`: `/scap?file=/pdf?file=${link}`}
            target="_blank"
            rel="noopener noreferrer"
            className="file-item">
            <i className="el-icon-document" /> {name}
	        {isWarning && <i className="el-icon-warning file-warning invalid"/>}
        </a>
    );
};

export default Document;
