import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'element-react';
import { withNamespaces } from 'react-i18next';
import { Form, Dropzone, FileList } from '../Common';
import { apiSaveFactoryEvaluation, apiFindFactoryEvaluation } from '../../api/factory-evaluation';
import { FactoryEvaluationModel } from '../../models';
import AuditFile from '../../utils/AuditFile';

class UploadFileModal extends Component {
	constructor(props) {
		super(props);
		this.fileRef  = React.createRef();
		this.state = {
			data: null,
			saving: false,
			fileScanning: false,
			isLoading: true,
			isCertificate: false
		}
	}

	async componentDidMount() {
		await this.initData(this.props.id);
		this.isCertificate(this.props.type);
	}

	initData = async (id) => {
		const { data } = await apiFindFactoryEvaluation(id);
		this.setState({ data: new FactoryEvaluationModel(data), isLoading: false})
	}

	isCertificate = (type) => {
		if (type === 'audit_cert_files') {
			this.setState({isCertificate: true})
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		let scanning = false;
		if ((this.state.data.audit_cert_files && this.state.data.audit_cert_files.length) ||
			(this.state.data.audit_related_files && this.state.data.audit_related_files.length)) {
			scanning = true;
		}
		this.refs.form.validate(async (valid) => {
			if (valid) {
				try {
					this.setState({ saving: true, fileScanning: scanning });
					await apiSaveFactoryEvaluation(new FactoryEvaluationModel(this.state.data, true));
					this.props.toggleUploadModal();
					this.props.refresh()
					this.setState({ saving: false, fileScanning: scanning });
				} catch (error) {
					this.setState({ saving: false, fileScanning: scanning });
				}
			}
		});
	};

	onChangeHandler = (value, dataKey) => {
		let data = { ...this.state.data };
		data[dataKey] = value;
		this.setState({ data });
	};

	onRemoveAttachment = (id) => {
		const data  = { ...this.state.data };
		const index = data.audit_files.findIndex(item => item.id === id);
		data.audit_files.splice(index, 1);
		this.setState({ data });
	};

	render() {
		const { t, toggleUploadModal, type, modalTitle, isOpen } = this.props;
		const { saving, fileScanning, isLoading, isCertificate } = this.state;
		return (
			<React.Fragment>
				<Modal backdrop="static" size="md" isOpen={isOpen} toggle={toggleUploadModal}>
					<Form onSubmit={this.handleSubmit} ref="form" labelWidth="150">
						<ModalHeader toggle={toggleUploadModal}>
							{modalTitle}
						</ModalHeader>
						<ModalBody className="user-container scrollable-modal" style={{textAlign: 'center'}}>
							<Dropzone
								fileType="*"
								onFilesAdded={(file) => this.onChangeHandler(file, type)}
								ref={this.fileRef}
								multiple >
								<i className="el-icon-upload"/>
								<div className="el-upload__text">
									{t('factoryEvaluation.dropFilesHere')}
									<em>{t('factoryEvaluation.clickToUpload')}</em>
								</div>
							</Dropzone>
							{!isLoading ? <FileList
								t={t}
								files={AuditFile.filter(this.state.data.audit_files, isCertificate ? 'is_certificate' : 'is_related_material')}
								hrefName="path"
								onRemoveFile={id => this.onRemoveAttachment(id)}
								keyId="id"
								name="name"
							/> : <i className="el-icon-loading"/>}
						</ModalBody>
						<ModalFooter>
							<div style={{marginLeft: 0}}>
								<Button onClick={toggleUploadModal}>
									{t('global.cancel')}
								</Button>
								<Button type="primary" nativeType="submit" loading={saving}>
									{saving  && fileScanning ? t('global.antivirusScanning'): t('global.submit')}
								</Button>
							</div>
						</ModalFooter>
					</Form>
				</Modal>
			</React.Fragment>
		)
	}
}

export default withNamespaces()(UploadFileModal);
