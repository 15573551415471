import React, { Component } from 'react';
import { components } from 'react-select';
import RNAsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

const DropdownIndicator = (
    props
) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="el-icon-search"></i>
        </components.DropdownIndicator>
    );
};

class AsyncSelect extends Component {
    
    state = {
        selected: this.props.selected,
        label: this.props.label ? this.props.label : 'name',
        uniqueKey: this.props.uniqueKey ? this.props.uniqueKey : 'id'
    }

    // componentWillReceiveProps(nextProps) {
    //     if (!nextProps.value && this.props.value) {
    //         this.setState({ selected: null });
    //     }
    //     if (((nextProps.selected && !this.props.selected) || (!nextProps.selected && this.props.selected))
    //         || (this.props.isMulti 
    //             && (nextProps.selected && nextProps.selected.length !== this.props.selected.length ))) {
    //         // this.setState({ selected: this.props.isMulti && !nextProps.selected ? [] : nextProps.selected });
    //     }
    // }

    componentDidUpdate(prevProps) {
        if (!this.props.value && prevProps.value) {
            this.setState({ selected: null });
        }
        if (((this.props.selected && !prevProps.selected) || (!this.props.selected && prevProps.selected))
            || (this.props.isMulti && this.props.selected && this.props.selected.length !== prevProps.selected.length )) {
            this.setState({ selected: this.props.isMulti && !this.props.selected ? [] : this.props.selected });
        }
    }

    changeHander = (selected) => {
        const { form } = this.context;
        form && form.onFieldChange();
        
        this.setState({ selected });
        
        let value = this.props.isMulti ? [] : null;
        if (selected) {
            if (this.props.isMulti) {
                value = selected.map(item => item[this.state.uniqueKey]);
            } else {
                value = selected[this.state.uniqueKey];
            }
        } else {
            if (this.props.isMulti) {
                selected = [];
            }
        }
        this.props.onChange(value, selected);
    }

    render() {
        const { label, uniqueKey } = this.state;
        const { 
            onChange, 
            value, 
            selected, 
            remoteMethod, 
            subLabel, 
            placeholder, 
            isMulti, 
            isDisabled, 
            isClearable = true,
            isFixed, 
            ...otherProps
        } = this.props;
        return (
            <RNAsyncSelect
                {...otherProps}
                placeholder={placeholder || ""}
                className="async-select"
                menuShouldBlockScroll={isFixed}
                loadOptions={remoteMethod}
                value={this.state.selected}
                getOptionValue={option => option[uniqueKey]}
                getOptionLabel={option => option[label]}
                onChange={this.changeHander}
                isClearable={isClearable}
                isMulti={isMulti}
                menuPosition={isFixed ? 'fixed' : 'absolute'}
                isDisabled={isDisabled}
                // menuPosition={'absolute'}
                menuPlacement="auto"
                components={{
                    Option: props => {
                        if (subLabel) {
                            return (
                                <components.Option {...props}>
                                    <span>{props.data[label]}</span>
                                    <span className={`right-part ${props.isSelected ? 'selected' : ''}`}>
                                        {props.data[subLabel]}
                                    </span>
                                </components.Option>
                            )
                        }
                        return <components.Option {...props} />
                    },
                    DropdownIndicator
                }}
                styles={{
                    control: base => ({
                        ...base,
                        boxShadow: "none"
                    }),
                    menu: base => ({
                        ...base,
                        zIndex: 2
                    }),
                    multiValue: (styles) => ({
                        ...styles,
                        color: '#00a83f',
                        backgroundColor: 'rgba(0, 168, 63, 0.1)',
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        color: '#00a83f'
                    }),
                    multiValueRemove: (styles) => ({
                        ...styles,
                        ':hover': {
                            backgroundColor: '#00a83f',
                            color: '#fff',
                        },
                    })
                }}
            />
        )
    }
}

AsyncSelect.contextTypes = {
    form: PropTypes.any
};

export default AsyncSelect;
