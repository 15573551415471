import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { connect } from 'react-redux';
import { Button, Loading, Layout, Form, Input } from 'element-react';
import { withFilter } from '../../hoc';
import { Pagination } from '../../components/Common';
import * as actions from '../../store/actions/actions';
import { apiDeleteVendorFactory, apiSaveVendorFactory } from '../../api/vendor-factory';
import { VendorCompanyForm } from '../../components/VendorCompany/VendorCompanyForm';
import { VendorFactoryForm, VendorFactoryList } from '../../components/VendorFactory';
import { VendorFactoryModel, VendorCompanyModel } from '../../models';
import permission from '../../utils/permission';
import { apiSaveVendorCompany } from '../../api/vendor-company';
import DownloadCsv from '../../components/Common/DownloadCsv';
import Message from '../../utils/Message';

class VendorFactory extends Component {
    _isMounted = false;
    _id = this.props.match.params.id;
    state = {
	    filters: {},
        firstLoading: true,
        vendorCompanyDialogVisible: false,
        vendorCompany: new VendorCompanyModel()
    }

    componentDidMount() {
        this._isMounted = true;
        console.log(this._id)
        this._id ?
        this.props.fetchOneVendorFactory(this._id) :
        this.filter();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSubmitVendorCompany = async () => {
        const { t } = this.props;
        try {
            const result = await apiSaveVendorCompany(new VendorCompanyModel(this.state.vendorCompany));
            this.setState({ vendorCompanyDialogVisible: false });
            Message.success(
	            t('global.saveSuccess'),
	            t('global.success')
            );
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    onDelete = async (id) => {
        await this.props.onDelete(id, apiDeleteVendorFactory);
        this.filter();
    }

    onSubmit = async () => {
        await this.props.onSubmit(VendorFactoryModel, apiSaveVendorFactory);
        this.filter();
    }

    filter = async (params) => {
        const filterColumns = [
            'name_en',
            'name_jp',
            'name_ch',
            'factory_name',
            'address',
            'vendor_company',
            'business_company',
            'product_category',
            'country_id'
        ];

        const newFilter = { ...this.props.filterData, ...params };
        let filter = {
            limit: newFilter.limit,
            page: newFilter.page,
            ...this.props.likeFilter('name', newFilter.name)
        };
        filterColumns.forEach(column => filter = {
            ...filter,
            ...this.props.whereInFilter(column, newFilter[column])
        });
        await this.props.fetchVendorFactory(filter);
        if (this._isMounted) {
            this.setState({
                filters: filter,
                firstLoading: false
            });
        }
    }

    onAddVendorCompany = () => {
        this.setState({
            vendorCompany: new VendorCompanyModel(),
            vendorCompanyDialogVisible: true
        });
    }

    onChangeHandler = (value, key, variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem[key] = value;
        this.setState({ [variableKey]: selectedItem });
    }

    onChangeContactHandler = (value, contactKey, contactIndex, variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem.contacts[contactIndex][contactKey] = value;
        this.setState({ [variableKey]: selectedItem });
    }

    dialogHandler = (modal) => {
        this.setState({ [modal]: !this.state[modal] });
    }

    onAddContact = (variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem.contacts.push({});
        this.setState({ [variableKey]: selectedItem });
    }

    onChangeWithFilter = (value, key) => {
        this.props.onChangeWithFilterAndResetPage(value, key);
        this.setState(prevState => ({
            isRenderList: !prevState.isRenderList
        }));
    };

    render() {
        const { filterData, vendorFactories, t } = this.props;
        return (
            <ContentWrapper>
                <VendorCompanyForm
                  t={t}
                  newBusinessCompanyButton={true}
                  data={this.state.vendorCompany}
                  visible={this.state.vendorCompanyDialogVisible}
                  onChangeHandler={(value, key) => this.onChangeHandler(value, key, 'vendorCompany')}
                  onSubmit={this.onSubmitVendorCompany}
                  onCancel={() => this.dialogHandler('vendorCompanyDialogVisible')}
                  onAddContact={() => this.onAddContact('vendorCompany')}
                  onRemoveContact={(index) => this.onRemoveContact(index, 'vendorCompany')}
                  onChangeContactHandler={(value, contactKey, contactIndex) => this.onChangeContactHandler(value, contactKey, contactIndex, 'vendorCompany')}
                />
                <VendorFactoryForm
                    canAddVendorCompany={true}
                    data={this.props.selectedItem}
                    visible={this.props.dialogVisible}
                    onChangeHandler={this.props.onChangeHandler}
                    onChangeState={this.props.onChangeState}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.dialogHandler}
                    onAddVendorCompany={this.onAddVendorCompany}
                    onAddContact={this.props.onAddContact}
                    onRemoveContact={this.props.onRemoveContact}
                    onChangeContactHandler={this.props.onChangeContactHandler}
                />
                <div className="content-heading">
                    <div>
	                    {t('sidebar.nav.vendorFactoryMaster')}
                    </div>
	                {permission('vendor-factory-export:view') &&
		                <DownloadCsv
			                lang={this.props.lng}
			                subject={'vendor_factories'}
			                filters={this.state.filters}
		                />
	                }
                </div>
                <Layout.Row>
                    <Layout.Col sm="12">
                        <Form inline={true}>
                            <Form.Item>
                                <Input
	                                value={this.props.filterData.name}
	                                icon="search"
	                                placeholder={t('vendorFactory.search')}
	                                onChange={this.props.onTextSearch}
                                />
                            </Form.Item>
                            <Form.Item>
                                {permission('vendor-factory:create') &&
                                    <Button type="primary" onClick={() => this.props.onCreate(VendorFactoryModel)}>
	                                    {t('vendorFactory.add')}
                                    </Button>
                                }
                            </Form.Item>
                        </Form>
                    </Layout.Col>
                    <Layout.Col sm="12">
                        <div className="pagination-right">
                            <Pagination
                                pageCount={filterData.totalPage}
                                total={filterData.total}
                                pageSize={filterData.limit}
                                initialPage={filterData.page}
                                onPageChange={this.props.onPageChange}
                            />
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Loading loading={this.state.firstLoading && !vendorFactories.length}>
                    <VendorFactoryList
                        vendorFactories={vendorFactories}
                        filterData={filterData}
                        onSelect={this.props.onSelect}
                        onDelete={this.onDelete}
                        onChangeWithFilter={this.onChangeWithFilter}
                    />
                </Loading>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        vendorFactories: state.vendorFactory.vendorFactories,
        filterData: state.vendorFactory.filterData
    };
};

const mapDispatchToProps = dispatch => ({
    fetchVendorFactory: (params) => dispatch(actions.fetchVendorFactory(params)),
    fetchOneVendorFactory: (params) => dispatch(actions.fetchOneVendorFactory(params)),
    setReduxFilter: (params) => dispatch(actions.setFilterVendorFactory(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(VendorFactory)));
