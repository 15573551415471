import axios from '../config/axios';

export function apiSearchCountry(params) {
    return axios.get('remote-search/country', { params });
};

export function apiSearchProductCategory(params) {
    return axios.get('remote-search/product-category', { params });
};

export function apiSearchCompany(params) {
    return axios.get('remote-search/company', { params });
};

export function apiSearchBusinessCompany(params) {
    return axios.get('remote-search/business-company', { params });
};

export function apiSearchVendorCompany(params) {
    return axios.get('remote-search/vendor-company', { params });
};

export function apiSearchVendorFactory(params) {
    return axios.get('remote-search/vendor-factory', { params });
};

export function apiSearchRole(params) {
    return axios.get('remote-search/role', { params });
};
