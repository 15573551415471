import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout, Tree } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Input } from '../Common';

export class RoleForm extends Component {
    state = {
        rules: {
            name: [
                { required: true, message: this.props.t('country.pleaseInputName'), trigger: 'change' },
                {
                    validator: (_, value, callback) => {
                        if (value && value.length > 30) {
                            callback(new Error(this.props.t('country.validMaxNameLength')));
                        }
                        callback();
                    }
                }
            ],
            code: [
                { required: true, message: this.props.t('country.pleaseInputCode'), trigger: 'change' },
                {
                    validator: (_, value, callback) => {
                        if (value && value.length > 2) {
                            callback(new Error(this.props.t('country.validMaxCodeLength')));
                        }
                        callback();
                    }
                }
            ]
        },
        options: {
            children: 'actions',
            label: 'label'
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.refs.form.validate((valid) => {
            if (valid) {
                this.props.onSubmit(this.tree.getCheckedNodes());
            }
        });
    }

    render() {
        const { t, data, rolePages, checkedKeys } = this.props;
        const {options } = this.state;
        if (!data) return null;

        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.visible} toggle={this.props.onCancel}>
                <Form onSubmit={this.handleSubmit} model={data} ref="form" rules={this.state.rules} labelWidth="100">
                    <ModalHeader toggle={this.props.onCancel}>{data.id ? t('role.edit') : t('role.add')}</ModalHeader>
                    <ModalBody>
                        <Layout.Row>
                            <Layout.Col span="18" offset="2">
                                <Form.Item label={t('global.name')} prop="name">
                                    <Input
	                                    value={data.name}
	                                    onChange={(value) => this.props.onChangeHandler(value, 'name')}
                                    />
                                </Form.Item>
                                <Form.Item label={t('role.description')} prop="description">
                                    <Input
	                                    type="textarea"
	                                    value={data.description}
	                                    onChange={(value) => this.props.onChangeHandler(value, 'description')}
                                    />
                                </Form.Item>
                                { rolePages.length &&
                                    <Form.Item label={t('role.permission')}>
                                        <Tree
                                            ref={e=>this.tree = e}
                                            data={rolePages}
                                            options={options}
                                            className="role-tree"
                                            isShowCheckbox={true}
                                            defaultCheckedKeys={checkedKeys}
                                            nodeKey="key_id"
                                        />
                                    </Form.Item>
                                }
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Form.Item>
                            <Button onClick={this.props.onCancel}>
	                            {t('global.cancel')}
                            </Button>
                            <Button type="primary" nativeType="submit">
	                            {t('global.submit')}
                            </Button>
                        </Form.Item>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(RoleForm);
