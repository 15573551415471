import * as actionTypes from './activity.actions';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    activities: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACTIVITY: return getActivity(state, action);
        case actionTypes.FILTER_ACTIVITY: return filterActivity(state, action);
        default: return state;
    }
};

const getActivity = (state, action) => updateObject(state, {
    activities: action.activities,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterActivity = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
