import axios from '../config/axios';

export function apiGetCompany(params) {
  return axios.get('companies', { params });
};

export function apiDeleteCompany(id) {
  return axios.delete(`companies/${id}`);
};

export function apiSaveCompany(data) {
  if (data.id) {
    return axios.put(`companies/${data.id}`, data);
  } else {
    return axios.post('companies', data);
  }
};