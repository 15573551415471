import { parseDate } from '../utils/utility';

export default class UserModel {
	constructor(data = {}, isSavingFormat = false) {
		this.id = data.id;
		this.first_name = data.first_name;
		this.last_name = data.last_name;
		this.email = data.email;
		this.phone = data.phone;
		this.gender = data.gender;
		this.image = data.image;
		this.image_binary = data.image_binary;
		this.ignore_userable = data.ignore_userable;
		this.birthday = parseDate(data.birthday, isSavingFormat);
		this.password = data.password;
		this.password_confirmation = data.password_confirmation;
		this.role_id = data.role_id;
		this.product_category_notify_ids = data.product_category_notify_ids || [];
		this.main_company_id = data.main_company_id;
		if (isSavingFormat) {
			this.business_company_ids = data.business_company_ids || [];
			this.vendor_company_ids = data.vendor_company_ids || [];
			this.vendor_factory_ids = data.vendor_factory_ids || [];
			this.product_category_ids = data.product_category_ids || [];
		} else {
			this.assigned_roles = [
				{
					business_company_id: null,
					vendor_companies: [],
					vendor_company_ids: [],
					vendor_factories: [],
					vendor_factory_ids: [],
					product_categories: [],
					product_category_ids: []
				}
			]
		}
	}
}
