import { updateObject, paginationData } from '../../utils/utility';
import {
    FETCH_CERTIFICATION_ISSUANCE,
    FETCH_ALL_CERTIFICATION_ISSUANCE,
    FILTER_CERTIFICATION_ISSUANCE
} from './certification-issuance.action.types';

const initialState = {
    filterData: {
        ...paginationData
    },
    certificationIssuances: [],
    allCertificationIssuances: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CERTIFICATION_ISSUANCE: return getCertificationIssuance(state, action);
    case FETCH_ALL_CERTIFICATION_ISSUANCE: return getAllCertificationIssuance(state, action);
    case FILTER_CERTIFICATION_ISSUANCE: return filterCertificationIssuance(state, action);
    default: return state;
  }
};

const getCertificationIssuance = (state, action) => updateObject(state, {
    certificationIssuances: action.certificationIssuances,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const getAllCertificationIssuance = (state, action) => updateObject(state, {
    allCertificationIssuances: action.allCertificationIssuances
});

const filterCertificationIssuance = (state, action) => updateObject(state, {
    filterData: action.filterData
});


export default reducer;
