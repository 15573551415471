import { parseDate } from '../utils/utility';
import permission from '../utils/permission';

export default class FactoryEvaluationModel {
    constructor(data = {}, save = false) {
    	this.id                  = data.id;
        this.business_company_id = data.business_company_id || null;
        this.vendor_company_id   = data.vendor_company_id || null;
        this.vendor_factory_id   = data.vendor_factory_id || null;
        this.product_category_id = data.product_category_id || null;
        this.product_sub_category_id = data.product_sub_category_id || null;
        this.working_group_id = data.working_group_id || null;
        this.product_id = data.product_id || null;
        this.md_name = data.md_name || null;
	    this.is_new = data.is_new || 0;
	      this.company_cp1_id = data.company_cp1_id || null;
	      this.factory_cp1_id = data.factory_cp1_id || null;
        this.vendor_co_certificate_recipient = data.vendor_co_certificate_recipient || null;
        this.vendor_co_certificate_recipient_name = data.vendor_co_certificate_recipient_name || null;

	    // product_category_id
        // working_group_id
	    // product_id
	    // product_sub_category_id

	    this.securedField(data, 'latest_evaluation_score_id', 'latest-evaluation-score', save);

	    this.audit_date          = parseDate(data.audit_date, save);
	    this.audit_number        = data.audit_number || null;
      this.audit_number_previous_id = data.audit_number_previous_id || null;
      this.audit_number_previous = data.audit_number_previous || null;
      this.audit_number_regular_id = data.audit_number_regular_id || null;
      this.audit_number_regular = data.audit_number_regular || null;
      this.audit_number_continuing = data.audit_number_continuing || null;
	    this.auditor_in_charge   = data.auditor_in_charge || null;
	    this.scap_received_date  = parseDate(data.scap_received_date, save);
	    this.scap_deadline_date  = parseDate(data.scap_deadline_date, save);
	    this.no_of_major         = this.normalizeWithZero(data.no_of_major);
	    this.no_of_minor         = this.normalizeWithZero(data.no_of_minor);
	    this.no_of_quality       = this.normalizeWithZero(data.no_of_quality);
	    this.no_of_c1            = this.normalizeWithZero(data.no_of_c1);
	    this.no_of_c2            = this.normalizeWithZero(data.no_of_c2);

	    // evaluation_result_id

	    this.securedField(data, 'evaluation_status_id', 'evaluation-status', save);
	    this.securedField(data, 'comment_tuv_hd', 'comment-tuv-hd', save);
	    this.securedField(data, 'comment_co_tuv', 'comment-co-tuv', save);
	    this.securedField(data, 'mfg_comment_hd_bus', 'mfg-comment-hd-bus', save);
	    this.securedField(data, 'mfg_memo', 'mfg-memo', save);

	    this.previous_evaluation_score = data.previous_evaluation_score || null;
	    this.audit_year = data.audit_year || null;
	    // audit_date_required

	    this.no_of_evidences = this.normalizeWithZero(data.no_of_evidences);
	    // comment_hd_co
	    // judgement_due_date
	    this.re_audit_planned_date = parseDate(data.re_audit_planned_date, save);
	    // comment_co_tuv

	    this.securedField(data, 'audit_type_id', 'audit-type', save);
	    this.securedField(data, 'audit_category_id', 'audit-category', save);
	    this.securedField(data, 'exception_flag_id', 'exception-flag', save);

	    // post_audit_status_id
	    // deadline_id

	    this.securedField(data, 'certification_issuance_id', 'certification-issuance', save);

	    this.effective_date = parseDate(data.effective_date, save);
	    this.expiration_date = parseDate(data.expiration_date, save);

	    this.securedField(data, 'memo', 'memo', save);

	    // status_changed_date
	    // nc
      this.total_status_id = data.total_status_id || null;

	    this.securedField(data, 'preferred_audit_date', 'preferred-audit-date', save, parseDate);
	    this.securedField(data, 'certificate_mail_date', 'certificate-mail-date', save, parseDate);

	    // certificate_mail_receipt

        this.exam_factory_examination_date = parseDate(data.exam_factory_examination_date, save);
        this.exam_mfg_process_auditor = data.exam_mfg_process_auditor || null;

        this.securedField(data, 'exam_total_evaluation', 'total-evaluation', save);
        this.securedField(data, 'exam_judgement_rank_id', 'judgement-rank', save);

        this.num_of_cert = data.num_of_cert || null;

        this.securedField(data, 'mfg_total_status_id', 'mfg-total-status', save);

        this.cert_issuance_date = parseDate(data.cert_issuance_date, save);
        this.cert_effective_date = parseDate(data.cert_effective_date, save);
        this.cert_expiration_date = parseDate(data.cert_expiration_date, save);
        this.mfg_audit_year = data.mfg_audit_year || null;

        this.securedField(data, 'mfg_institution_id', 'mfg-institution', save);

        this.mfg_planned_month = parseDate(data.mfg_planned_month, save);
        this.mfg_audit_date = parseDate(data.mfg_audit_date, save);

        this.securedField(data, 'curr_year_total_eval', 'current-total-evaluation', save);

        this.curr_year_judgement_rank_id = data.curr_year_judgement_rank_id || null;

        this.audit_saq_files  = data.audit_saq_files || [];
        this.audit_scap_files = data.audit_scap_files || [];
        this.audit_evid_files = data.audit_evid_files || [];
        this.audit_cert_files = data.audit_cert_files || [];
        this.audit_related_files = data.audit_related_files || [];
        this.audit_exam_evid_sheet_files = data.audit_exam_evid_sheet_files || [];
        this.audit_exam_evid_rep_files = data.audit_exam_evid_rep_files || [];
        this.audit_mfg_evid_sheet_files = data.audit_mfg_evid_sheet_files || [];
        this.audit_mfg_evid_rep_files = data.audit_mfg_evid_rep_files || [];
        this.audit_evid_cert_files = data.audit_evid_cert_files || [];
		    this.contacts = data.contacts || [];
        if (save) {
            this.audit_files = JSON.stringify(data.audit_files.flat());
        } else {
            this.audit_files = data.audit_files || [];
        }

        if (!save && data.id) {
		    this.business_company = data.business_company || null;
		    this.vendor_company = data.vendor_company || null;
		    this.vendor_factory = data.vendor_factory || null;
		    this.product_sub_category = data.product_sub_category || null;
		    this.working_group = data.working_group || null;
		    this.product = data.product || null;
        this.vendor_factory.business_companies_others = data.vendor_factory.business_companies_others || null;
        this.main_sub_audit_numbers = data.main_sub_audit_numbers || null;
	    }
    }

    securedField(data, name, role, save, func) {
	    if (!save && permission(`${role}:view`)) {
		    this[name] = func ? func(data[name], save) : data[name];
	    }
	    if (save && permission(`${role}:update`)) {
		    this[name] = func ? func(data[name], save) : data[name];
	    }
	}

	normalizeWithZero(value) {
		if (0 === value) {
			return '0';
		}
		return value || null;
	}

    static getScores = () => {
        return [
            {id: '初回', name: '初回'},
            {id: 'A', name: 'A'},
            {id: 'B', name: 'B'},
            {id: 'C', name: 'C'},
            {id: 'D', name: 'D'},
            {id: 'E', name: 'E'}
        ];
    }

    static getAuditYears = () => {
        if (Array.isArray(this.auditYears)) {
            return this.auditYears;
        }
        this.auditYears = [];
        for (let i = 2012 ; i < 2032 ; i++ ) {
            this.auditYears.push({id: i, name: i});
        }
        return this.auditYears;
    }
}
