import { updateObject } from '../../utils/utility';
import { FETCH_STATIC_DATA, SET_STATIC_DATA_LOADED, FETCH_ROLE_PAGE } from '../actions/actions';

const initialState = {
    isLoaded: false,
    evaluation_statuses: [],
    evaluation_results: [],
    role_pages: [],
    root_product_categories: [],
    deadlines: [],
    audit_types: [],
    audit_categories: [],
    exception_flags: [],
    post_audit_status: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATIC_DATA: return fetchStaticData(state, action);
        case FETCH_ROLE_PAGE: return getRolePage(state, action);
        case SET_STATIC_DATA_LOADED: return isLoaded(state);
        default: return state;
    }
};

const fetchStaticData = (state, action) => updateObject(state, {
    [action.table]: action.data
});

const getRolePage = (state, action) => updateObject(state, {
    role_pages: action.role_pages
});

const isLoaded = (state) => updateObject(state, { isLoaded: true });

export default reducer;
