import Form from './Form';
import FormItem from './FormItem';
import Input from './Input';
import DatePicker from './DatePicker';

Form.Item = FormItem;

export {
   Form,
   Input,
   DatePicker
}