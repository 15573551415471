import React from 'react';

const CertificateIssuanceCell = (props) => {
    const { status, certIssuance } = props;
    return (
      props.isPullback ?
      <td
          onClick={status === 1 ? (e) => props.preview(e, certIssuance, true, props.step) : null}
          style={{
              cursor: status === 1 ? 'pointer' : 'default',
              backgroundColor: status === 1 ? 'orange' : status === 2 ? '#ccc' : status === 3 ? '#ccc' : '#fff'
              }}>
          {
              status === 1 ? props.activeMsg :
              status === 2 ?
                  <div>
                      <span>{props.awaitingMsg}</span>
                  </div> :
              status === 3 ? `${props.column} ${props.assignee}` : ''
          }
      </td> :
      props.step === 'cert_receipt' ?
      <td
          onClick={[1,3].includes(status) ? (e) => props.preview(e, certIssuance, true, props.step) : null}
          style={{
              cursor: [1,3].includes(status) ? 'pointer' : 'default',
              backgroundColor: status === 1 ? '#99ff99'  : status === 3 ? '#ccc' : '#fff'
              }}>
          {
              status === 1 ? props.activeMsg :
              status === 3 ? props.column : ''
          }
      </td> :
      <td
          onClick={props.isDraft && status === 1 ? (e) => props.openDraft(e, certIssuance) :  status === 1 ? (e) => props.preview(e, certIssuance, true, props.step) : status === 2 ? (e) => props.preview(e, certIssuance, false) : null}
          style={{
              cursor: status === 1  || status === 2 ? 'pointer' : 'default',
              backgroundColor: status === 1 ? '#99ff99' : status === 2 ? '#00afef' : status === 3 ? '#ccc' : '#fff'
              }}>
          {
              status === 1 ? props.activeMsg :
              status === 2 ?
                <div>
                    <span>{props.awaitingMsg}</span>
                    {!props.isSignature && <input className='awaiting-cert' type='checkbox' checked={props.isChecked(certIssuance)} onChange={(e) => props.toggle(e, certIssuance)} />}
                </div> :
              status === 3 ? `${props.column} ${props.assignee}` : ''
          }
      </td>
    )
}

export default CertificateIssuanceCell;
