import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { connect } from 'react-redux';
import { Button, Layout, Loading } from 'element-react';

import { withFilter } from '../../hoc';
import { Pagination, Form, Input } from '../../components/Common';
import { apiDeleteRole, apiSaveRole } from '../../api/role';
import { RoleForm, RoleList } from '../../components/Role';
import { RoleModel } from '../../models';
import {
    fetchRole,
    setFilterRole,
    fetchRolePage
} from './../../store/actions/actions';
import permission from 'utils/permission';

class Role extends Component {
    _isMounted = false;

    state = {
        firstLoading: true,
        rolePages: [],
        roles: [],
        checkedKeys: []
    }

    componentDidMount() {
        this._isMounted = true;
        this.filter();
        this.generateRoleTree();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    generateRoleTree = async () => {
        await this.props.fetchRolePage();
        const rolePages = this.generateRoleTreeLabel(this.props.rolePages);
        if (this._isMounted) {
            this.setState({ rolePages });
        }
    }

    generateRoleTreeLabel(tree, parent) {
        const roleTree = tree.map(route => {
            route.label = this.props.t(`role.${route.slug}`);
            route.name = route.slug;
            if (route.actions) {
                route.key_id = route.slug;
                route.actions = this.generateRoleTreeLabel(route.actions, route);
            } else {
                route.parent = parent.slug;
                route.key_id = `${parent.slug}-${route.permission}`;
            }
            return route;
        });

        return roleTree;
    }

    onDelete = async (id) => {
        await this.props.onDelete(id, apiDeleteRole);
        this.filter();
    }

    onSubmit = (roleTree) => {
        const rolePermissions = this.getCheckedRoles(roleTree);

        this.props.onChangeHandler(rolePermissions, 'role_permissions', async () => {
            await this.props.onSubmit(RoleModel, apiSaveRole);
            this.filter();
        });
    }

    onSelect = (row) => {
        this.initRoleTree(row.role_permissions);
        this.props.onSelect(row);
    }

    onCreate = () => {
        this.initRoleTree();
        this.props.onCreate(RoleModel);
    }

    initRoleTree = (roles) => {
        const checkedKeys = [];
        if (roles) {
            roles.forEach(role => {
                if (role.permission & 1) {
                    checkedKeys.push(`${role.slug}-1`);
                }
                if (role.permission & 2) {
                    checkedKeys.push(`${role.slug}-2`);
                }
                if (role.permission & 4) {
                    checkedKeys.push(`${role.slug}-4`);
                }
                if (role.permission & 8) {
                    checkedKeys.push(`${role.slug}-8`);
                }
            });
        }
        this.setState({ checkedKeys });
    }

    getCheckedRoles = (tree) => {
        const role_permissions = [];
        tree.forEach(role => {
            if (role.permission && !role.actions) {
                const roleIndex = role_permissions.findIndex(item => item.slug === role.parent);
                if (roleIndex !== -1) {
                    role_permissions[roleIndex].permission += role.permission;
                } else {
                    role_permissions.push({ ...role, slug: role.parent });
                }
            }
        });
        return role_permissions;
    }

    filter = async (params) => {
        const newFilter = { ...this.props.filterData, ...params };
        const filter = {
            limit: newFilter.limit,
            page: newFilter.page,
            ...this.props.likeFilter('name', newFilter.name)
        };
        await this.props.fetchRole(filter);
        if (this._isMounted) {
            this.setState({ firstLoading: false });
        }
    }

    render() {
        const { filterData, roles, t } = this.props;
        const { rolePages, checkedKeys } = this.state;

        return (
            <ContentWrapper>
                <RoleForm
                    data={this.props.selectedItem}
                    visible={this.props.dialogVisible}
                    onChangeHandler={this.props.onChangeHandler}
                    checkedKeys={checkedKeys}
                    rolePages={rolePages}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.dialogHandler}
                />
                <div className="content-heading">
                    <div>{t('sidebar.nav.roleMaster')}</div>
                </div>
                <Layout.Row>
                    <Layout.Col sm="12">
                        <Form inline={true}>
                            <Form.Item>
                                <Input value={this.props.filterData.name} icon="search" placeholder={t('global.search')} onChange={this.props.onTextSearch} />
                            </Form.Item>
                            <Form.Item>
                                {permission('role:create') &&
                                    <Button type="primary" 
                                        disabled={!rolePages.length} 
                                        onClick={this.onCreate}>
                                    {t('role.add')}
                                    </Button>
                                }
                            </Form.Item>
                        </Form>
                    </Layout.Col>
                    <Layout.Col sm="12">
                        <div className="pagination-right">
                            <Pagination
                                pageCount={filterData.totalPage}
                                total={filterData.total}
                                pageSize={filterData.limit}
                                initialPage={filterData.page}
                                onPageChange={this.props.onPageChange}
                            />
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Loading loading={this.state.firstLoading && !this.props.roles.length}>
                    <RoleList
                        roles={roles}
                        filterData={filterData}
                        onSelect={this.onSelect}
                        onDelete={this.onDelete}
                    />
                </Loading>
            </ContentWrapper>
        );
    }

}

const mapStateToProps = state => {
    return {
        roles: state.role.roles,
        rolePages: state.staticData.role_pages,
        filterData: state.role.filterData
    };
};

const mapDispatchToProps = dispatch => ({
    fetchRole: (params) => dispatch(fetchRole(params)),
    fetchRolePage: () => dispatch(fetchRolePage()),
    setReduxFilter: (params) => dispatch(setFilterRole(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(Role)));
