import { apiGetVendorFactory, apiFindVendorFactory } from '../../api/vendor-factory';
import { FETCH_VENDOR_FACTORY, FETCH_ONE_VENDOR_FACTORY, FILTER_VENDOR_FACTORY } from './vendor-factory.action.types';

const fetchVendorFactorySuccess = (result) => ({
    type: FETCH_VENDOR_FACTORY,
    vendorFactories: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchVendorFactory = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetVendorFactory(filterData);
            dispatch(fetchVendorFactorySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const fetchOneVendorFactorySuccess = (result) => ({
    type: FETCH_ONE_VENDOR_FACTORY,
    vendorFactories: [result.data]
});

export const fetchOneVendorFactory = (id) => {
    return async dispatch => {
        try {
            const result = await apiFindVendorFactory(id);
            dispatch(fetchOneVendorFactorySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterVendorFactory = (filterData) => ({
    type: FILTER_VENDOR_FACTORY,
    filterData
});
