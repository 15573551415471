import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => ({
	...oldObject,
	...updatedProperties
});

export const paginationData = {
	page: 1,
	limit: 15,
	totalPage: 0,
	total: 0,
	freeze: [],
};

export const validateLength = (value, callback, lengthNum, message) => {
	if (value && value.length > lengthNum) {
		callback(new Error(message));
	}
	callback();
}

export const validateRequired = (value, callback, message) => {
	if (!value) {
		callback(new Error(message));
	}
	callback();
}

export const validateLeastOneModel = (value, callback, message) => {
    if (typeof value === 'object' && value.length > 0 && value[0].id) {
        callback();
    }
    callback(new Error(message));
}

export const debounce = (func, wait = 250, immediate) => {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export function convertFormData(data) {
	const formData = new FormData();
	for (const key in data) {
		if (data[key] === undefined) continue;

		if (data[key] === null) {
			data[key] = '';
		}
		if (Array.isArray(data[key]) && key !== 'contacts') {
			data[key].forEach(value => formData.append(`${key}[]`, value));
		} else if (Array.isArray(data[key]) && key === 'contacts') {
				data[key].forEach(value => formData.append(`${key}[]`, JSON.stringify(value)));
		} else {
			formData.append(key, data[key]);
		}
	}
	return formData;
}

export const formatDateTime = (time, format = null) => {
	return time ? moment(time).format(format ? format : 'YYYY-MM-DD HH:mm:ss') : null;
}

export const formatDate = (date, format = null) => {
	return date ? moment(date).format(format ? format : 'YYYY-MM-DD') : null;
}

export const dateObject = (time) => {
	return time ? moment(time).toDate() : null;
}

export const parseDate = (date, isFormat = false, format = null) => {
	return date ? (isFormat ? moment(date).format(format ? format : 'YYYY-MM-DD') : moment(date).toDate()) : null;
}

export const parseDateTime = (time, isFormat = false, format = null) => {
	return time ? (isFormat ? moment(time).format(format ? format : 'YYYY-MM-DD HH:mm:ss') : moment(time).toDate()) : null;
}

export const data2blob = (data, mime = "image/jpeg") => {
	data = data.split(',')[1]
	data = window.atob(data)
	var ia = new Uint8Array(data.length)
	for (var i = 0; i < data.length; i++) {
		ia[i] = data.charCodeAt(i)
	}
	// canvas.toDataURL 返回的默认格式就是 image/png
	return new Blob([ia], {
		type: mime
	})
}

export const likeFilter = (name, value) => {
	if (value) {
		return { [name]: `*${value}*` };
	}
}

export const whereFilter = (name, value) => {
	if (value) {
		return { [name]: value };
	}
}

export const gtFilter = (name, value) => {
	if (value) {
		return { [`${name}[gt]`]: value };
	}
}

export const gteFilter = (name, value) => {
	if (value) {
		return { [`${name}[gte]`]: value };
	}
}

export const ltFilter = (name, value) => {
	if (value) {
		return { [`${name}[lt]`]: value };
	}
}

export const lteFilter = (name, value) => {
	if (value) {
		return { [`${name}[lte]`]: value };
	}
}

export const whereInFilter = (name, array) => {
	if (array && array.length) {
		return { [`${name}[]`]: JSON.stringify(array) };
	}
}

export const whereNotInFilter = (name, array) => {
	if (array && array.length) {
		return { [`${name}[notin]`]: JSON.stringify(array) };
	}
}

export const orderBy = (item) => {
	if (item) {
		return { "order_by": `${item.column},${item.value}` };
	}
}

export const isPdf = (name) => {
	return /(.pdf)$/i.test(name);
}

export const isImage = (name) => {
    return /(.png|.jpg|.jpeg|.gif)$/i.test(name);
}

export const isExplorer = () => {
    let userAgent = navigator.userAgent;
    return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
}

const europeOrUs = ["AT","BE","BG","HR","CY","CZ","DK","EE","FI","FR","DE","GR","HU","IE","IT","LV","LT","LU","MT","NL","PL","PT","RO","SK","SI","ES","SE","US"];
export function isEuropeOrUs(country) {
		return europeOrUs.includes(country) ? true : false;
}

export function isCertificateValid(expirationDate) {
		return new Date(expirationDate) > new Date();
}

export function isDomestic(evaluation) {
		return evaluation.vendor_factory.country.code === "JP";
}
