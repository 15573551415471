import { apiGetUser } from '../../api/user';
import { FETCH_USER, FILTER_USER } from './user.action.types';

const fetchUserSuccess = (result) => ({
    type: FETCH_USER,
    users: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchUser = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetUser(filterData);
            dispatch(fetchUserSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterUser = (filterData) => ({
    type: FILTER_USER,
    filterData
});