import React from 'react';
import { Table } from 'reactstrap';
import { TableActions, Pagination } from '../Common';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from "../../components/Layout/ContentWrapper";
import ParentPopover from './Items/ParentPopover';
import { Button } from 'element-react';
import permission from 'utils/permission';
import { apiGetFilters } from '../../api/product-sub-category';
import FilterContent from '../../components/Common/FixedTable/FilterContent';

export const ProductSubCategoryList = (props) => {
    const { productSubCategories, t } = props;
    return (
        <div style={{ 'marginTop': '45px' }}>
        <Button type="primary" className="mb-sm" onClick={ props.onOpenAddModal }>Add</Button>
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th style={{ position: 'relative' }}>{t('factoryEvaluation.workingGroup')}
                        <FilterContent
                            id={1}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('working_group')}
                            filterOptions={{
                              id: 'id',
                              label: `name_${props.lang}`
                            }}
                            initData={[]}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'working_group')}
                            i18n={t}
                        >
                        <span className="product-filters" id={'Popover-' + 1}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['working_group'] && props.filterData['working_group'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>{t('global.name_en')}</th>
                    <th>{t('global.name_jp')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {productSubCategories.map((productSubCategory, idx) => (
                    <tr key={idx}>
                        <td align="center">{productSubCategory.id}</td>
                        <td>
                            <ParentPopover
                                id={productSubCategory.id}
                                parent_id={productSubCategory.parent_id}
                                t={t}
                            >
                                <span className="clickable" style={{ 'color': 'blue' }} id={ 'parentPopover-' + productSubCategory.id }>{productSubCategory.parent_id}</span>
                            </ParentPopover>
                        </td>
                        <td>{productSubCategory.name_en}</td>
                        <td>{productSubCategory.name_jp}</td>
                        <td align="center">
                            <TableActions
                                roleName="product-sub-category"
                                onSelect={() => props.onOpenEditModal(productSubCategory)}
                                onDelete={() => props.onDelete(productSubCategory.id)}
                            />
                        </td>
                    </tr>
                ))}
                {!productSubCategories.length &&
                    <tr>
                        <td colSpan="5" align="center">{t('global.notMatchingRecordsFound')}</td>
                    </tr>
                }
            </tbody>
        </Table>
        </div>
    )
}

export default withNamespaces()(ProductSubCategoryList);
