import { apiGetFactoryEvaluation } from '../../api/factory-evaluation';
import { 
    FETCH_FACTORY_EVALUATION, 
    FILTER_FACTORY_EVALUATION
} from './factory-evaluation.action.types';

const fetchFactoryEvaluationSuccess = (result) => ({
    type: FETCH_FACTORY_EVALUATION,
    factoryEvaluations: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchFactoryEvaluation = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetFactoryEvaluation(filterData);
            dispatch(fetchFactoryEvaluationSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterFactoryEvaluation = (filterData) => ({
    type: FILTER_FACTORY_EVALUATION,
    filterData
});