export function filterTotalStatuses(item, original) {
    switch (true) {
        /*****Audit Category: Regular Audit (First time) && Regular Audit (Renewal)******/
        case (([1, 2].includes(original.audit_category_id)) && ([1, 3].includes(original.audit_type_id)) && !isAuditEnacted(original)):
            return [original.total_status_id, 1, 2, 3, 4, 5, 6, 7, 41].indexOf(item.id) >= 0 ? true : false;
        case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && (![208, 211, 212, 213].includes(original.evaluation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 8, 9, 10, 11, 12, 13, 14, 41].indexOf(item.id) >= 0 ? true : false;
       case (([1, 2].includes(original.audit_category_id)) && (3 === original.audit_type_id) && !original.evaluation_result_id && isAuditEnacted(original)):
           return [original.total_status_id, 8, 9, 10, 11, 12, 13, 14, 41].indexOf(item.id) >= 0 ? true : false;
        case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && ([208, 211].includes(original.evaluation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 19, 20, 41].indexOf(item.id) >= 0 ? true : false;
        case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && ([208, 212].includes(original.evaluation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 17, 18, 41].indexOf(item.id) >= 0 ? true : false;
        case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && ([208, 213].includes(original.evaluation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 15, 16, 41].indexOf(item.id) >= 0 ? true : false;

        case (([1, 2].includes(original.audit_category_id)) && (3 === original.audit_type_id) && original.evaluation_result_id && isAuditEnacted(original)):
            return [original.total_status_id, 14, 41].indexOf(item.id) >= 0 ? true : false;
        /*****Audit Category: Re-audit *****/
        case ((3 === original.audit_category_id) && [1, 3].includes(original.audit_type_id) && !isAuditEnacted(original)):
            return [original.total_status_id, 22, 24, 25, 26, 41].indexOf(item.id) >= 0 ? true : false;
        case ((3 === original.audit_category_id) && (1 === original.audit_type_id) && ![208, 211, 212, 213].includes(original.evaluation_result_id) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 26, 27, 28, 29, 30, 31, 41].indexOf(item.id) >= 0 ? true : false;
        case ((3 === original.audit_category_id) && (3 === original.audit_type_id) && !original.evaluation_result_id && isAuditEnacted(original)):
            return [original.total_status_id, 14, 26, 27, 28, 29, 30, 31, 41].indexOf(item.id) >= 0 ? true : false;
        case ((3 === original.audit_category_id) && (1 === original.audit_type_id) && [208, 211, 212, 213].includes(original.evaluation_result_id) && isAuditEnacted(original)):
            return [original.total_status_id, 15, 16, 41].indexOf(item.id) >= 0 ? true : false;
        case ((3 === original.audit_category_id) && (3 === original.audit_type_id) && original.evaluation_result_id && isAuditEnacted(original)):
            return [original.total_status_id, 14, 41, 42].indexOf(item.id) >= 0 ? true : false;
        /*****Audit Category: Re-re-audit *****/
        case ((4 === original.audit_category_id) && 1 === original.audit_type_id && !isAuditEnacted(original)):
            return [original.total_status_id, 31, 32, 33, 34, 41].indexOf(item.id) >= 0 ? true : false;
        case ((4 === original.audit_category_id) && 1 === original.audit_type_id && ![208, 211, 212, 213, 237].includes(original.evaluation_result_id) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 35, 36, 39, 41].indexOf(item.id) >= 0 ? true : false;
        case ((4 === original.audit_category_id) && 1 === original.audit_type_id && [208, 211, 212, 213, 237].includes(original.evaluation_result_id) && isAuditEnacted(original)):
            return [original.total_status_id, 15, 16, 41].indexOf(item.id) >= 0 ? true : false;
        default:
            return;
    }
}

function isAuditEnacted(original) {
    let hasCap = false;
    let auditDatePassed = original.audit_date && original.audit_date !== null ? new Date(original.audit_date) < new Date() : false;
    original.audit_files.forEach((file) => {
        if (file.is_scap) {
            hasCap = true;
        }
    });

    if (!hasCap || !auditDatePassed) {
        return false;
    } else {
        return true;
    }
}
