import React from 'react';
import Message from '../utils/Message';

import {
    debounce,
    likeFilter,
    whereFilter,
    whereInFilter,
    whereNotInFilter,
    gtFilter,
    gteFilter,
    ltFilter,
    lteFilter,
    orderBy
} from '../utils/utility';
import { MessageBox } from '../components/Common';
import { ContactModel } from '../models';

export default function withFilter(WrappedComponent) {
    return class extends React.Component {
        state = {
            dialogVisible: false,
            selectedItem: null
        }

        onSubmit = async (dataModel, saveFn) => {
            const { t } = this.props;
            try {
                const result = await saveFn(new dataModel(this.state.selectedItem, true));
                this.setState({ dialogVisible: false });
	            Message.success(
	            	t('global.saveSuccess'),
		            t('global.success')
	            );
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }

        onDelete = async (id, deleteFn) => {
            const { t } = this.props;
            return MessageBox.confirm(t('global.areYouSureWantToDelete'), t('global.confirm'), {
                confirmButtonText: t('global.ok'),
                cancelButtonText: t('global.cancel'),
            }).then(async () => {
                try {
                    const result = await deleteFn(id);
	                Message.success(
	                	t('global.deleteSuccess'),
		                t('global.success')
	                );
                    return Promise.resolve(result);
                } catch (error) {
                    return Promise.reject(error);
                }
            })
        }

        onCreate = (dataModel) => {
            this.setState({ selectedItem: new dataModel(), dialogVisible: true });
        }

        onSelect = (selectedItem) => {
            this.setState({
                selectedItem: { ...selectedItem },
                dialogVisible: true
            });
        }

        onChangeHandler = (value, key, callback) => {
            const selectedItem = { ...this.state.selectedItem };
            selectedItem[key] = value;
            this.setState({ selectedItem }, callback);
        }

        onChangeContactHandler = (value, key, contactIndex) => {
            const contacts = [...this.state.selectedItem.contacts];
            contacts[contactIndex][key] = value;
            this.onChangeHandler(contacts, 'contacts');
        }

        onAddContact = () => {
            const contacts = [...this.state.selectedItem.contacts];
            contacts.push(new ContactModel());
            this.onChangeHandler(contacts, 'contacts');
        }

        onRemoveContact = (index) => {
            const contacts = [...this.state.selectedItem.contacts];
            if (contacts[index].id) {
                contacts[index].isDeleted = true;
            } else {
                contacts.splice(index, 1);
            }
            this.onChangeHandler(contacts, 'contacts');
        }

        onChangeState = (stateData, stateKey, callback) => {
            this.setState({ [stateKey]: stateData }, callback);
        }

        setFilter = (value, name) => {
            const filterData = { ...this.props.filterData };
            filterData[name] = value;
            this.props.setReduxFilter(filterData);
            return filterData;
        }

        setAllFilters = (params) => {
            const filterData = { limit: 15, page: 1, freeze: this.props.filterData.freeze ? this.props.filterData.freeze : [] };
            Object.keys(params).forEach(param => {
                filterData[param] = params[param];
            });
            this.props.setReduxFilter(filterData);
            return filterData;
        }

        setFilterWithResetPage = (value, name) => {
            const filterData = { ...this.props.filterData };
            filterData[name] = value;
            filterData.page = 1;
            this.props.setReduxFilter(filterData);
            return filterData;
        }

        onChangeWithFilter = (value, name) => {
            if (this.props.filterData['search_panel']) {
                this.setFilter(value, name);
                this.cmp.filterBar({ [name]: value });
            } else {
                this.setFilter(value, name);
                this.cmp.filter({ [name]: value });
            }
        }

        onChangeWithFilterAndResetPage = async (value, name) => {
            await this.setFilter(false, 'search_panel')
            const filterData = this.setFilterWithResetPage(value, name);
            this.cmp.filter(filterData);
        }

        onSortByWithFilter = (column, value) => {
            if (this.props.filterData.orderBy
                && this.props.filterData.orderBy.column === column
                && this.props.filterData.orderBy.value === value) {
                    this.onChangeWithFilter(null, "orderBy");
            } else {
                this.onChangeWithFilter({ column, value }, "orderBy");
            }
        }

        onPageChange = (page) => {
            this.onChangeWithFilter(page, 'page');
        }

        onTextSearch = (name) => {
            const filterData = this.setFilterWithResetPage(name, 'name');
            this.debounceFilter(filterData);
        }

        debounceFilter = debounce((params) => {
            this.cmp.filter(params);
        })

        dialogHandler = () => {
            this.setState(prevState => ({ dialogVisible: !prevState.dialogVisible }));
        }

        render() {
            return <WrappedComponent
                ref={(ref) => this.cmp = ref}
                {...this.props}
                {...this.state}
                setFilter={this.setFilter}
                setAllFilters={this.setAllFilters}
                onChangeWithFilter={this.onChangeWithFilter}
                onChangeWithFilterAndResetPage={this.onChangeWithFilterAndResetPage}
                onSortByWithFilter={this.onSortByWithFilter}
                onTextSearch={this.onTextSearch}
                onPageChange={this.onPageChange}
                likeFilter={likeFilter}
                gtFilter={gtFilter}
                gteFilter={gteFilter}
                ltFilter={ltFilter}
                lteFilter={lteFilter}
                whereFilter={whereFilter}
                whereInFilter={whereInFilter}
                whereNotInFilter={whereNotInFilter}
                orderBy={orderBy}
                debounce={debounce}
                selectedItem={this.state.selectedItem}
                onSelect={this.onSelect}
                onCreate={this.onCreate}
                dialogHandler={this.dialogHandler}
                onChangeHandler={this.onChangeHandler}
                onChangeState={this.onChangeState}
                onAddContact={this.onAddContact}
                onRemoveContact={this.onRemoveContact}
                onChangeContactHandler={this.onChangeContactHandler}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
            />
        }
    }
}
