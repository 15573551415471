import axios from '../config/axios';

export function apiGetRole(params) {
  return axios.get('roles', { params });
};

export function apiDeleteRole(id) {
  return axios.delete(`roles/${id}`);
};

export function apiSaveRole(data) {
  if (data.id) {
    return axios.put(`roles/${data.id}`, data);
  } else {
    return axios.post('roles', data);
  }
};