import React, {Component} from 'react';
import {withNamespaces} from 'react-i18next';
import FormCollection from '../Common/FormCollection';
import {Button, Layout} from 'element-react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Form, Input, AsyncSelect, Contacts} from '../Common';
import {validateLength, likeFilter, validateLeastOneModel} from '../../utils/utility';
import permission from '../../utils/permission';
import {apiGetVendorCompanyContacts} from '../../api/vendor-factory';
import {
	apiSearchProductCategory,
	apiSearchCountry,
	apiSearchBusinessCompany,
	apiSearchVendorCompany
} from '../../api/remote-search';

class VendorFactoryForm extends Component {
    _isMounted = false;
    state = {
        rules: {
	        name_jp: [
		        { validator: (_, value, callback) => validateLength(value, callback, 40, this.props.t('businessCompany.validMaxNameLength')) }
	        ],
			vendor_companies: [
				{ required: true, validator: (_, value, callback) => validateLeastOneModel(value, callback, this.props.t('global.pleaseSelectVendorCompany')) }
			],
        	business_companies: [
				{ required: true, validator: (_, value, callback) => validateLeastOneModel(value, callback, this.props.t('global.pleaseSelectBusinessCompany')) }
			],
			product_categories: [
				{ required: true, validator: (_, value, callback) => validateLeastOneModel(value, callback, this.props.t('global.pleaseSelectProductCategory')) }
			],
        },
        productCategories: [],
        businessCompanies: [],
        vendorCompanies: [],
	    vendorCompanyContacts: [],
        searching: false
    }

    componentDidMount() {
	    this._isMounted = true;
	    this.fetchData(apiSearchVendorCompany, 'vendorCompanies');
	    this.fetchData(apiSearchProductCategory, 'productCategories');
	    this.fetchData(apiSearchBusinessCompany, 'businessCompanies');
    }

    componentWillUnmount() {
	    this._isMounted = false;
    }

	componentDidUpdate(prevProps) {
	    if (this.props.visible && !prevProps.visible) {
	    	const {data} = this.props;
		    data.id && this.fetchVendorCompanyContacts(data);
				if (this.props.canAddVendorCompany) {
		    !data.vendor_companies.length && this.onCollectionAdd(null, 'vendor_companies');
		    !data.business_companies.length && this.onCollectionAdd(null, 'business_companies');
		    !data.product_categories.length && this.onCollectionAdd(null, 'product_categories');
		    !data.vendor_company_contacts.length && this.onCollectionAdd(null, 'vendor_company_contacts');
				}
	    }
    }

		handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await this.validateName(this.props.data)
          .then(() => {
            this.refs.form.validate((valid) => {
                if (valid) {
                    this.props.onSubmit();
                    this.setState({ err: null })
                }
            });
          })
        } catch (err) {
            this.setState({ err: err.message })
        }
    }

    validateName = async (data) => {
        if (!data.name_jp && !data.name_en && !data.name_ch) {
            throw new Error(this.props.t('vendorFactory.pleaseInputName'));
        }
        return true;
    }

    onCancel = () => {
        this.setState({ err: null });
        this.props.onCancel();
    }

    fetchVendorCompanyContacts = (data) => {
	    apiGetVendorCompanyContacts(data.vendor_companies.map(item => item.id)).then(response => {
		    this.setState({
			    vendorCompanyContacts: response.data
		    });
	    });
    }

    fetchData = async (searchFn, stateKey) => {
        this.setState({ [stateKey]: [] });
        let optionFilter = {};
        const { data } = await searchFn({ ...optionFilter, limit: 'unlimited' });
        if (this._isMounted) {
            this.setState({ [stateKey]: data });
        }
    }

    onSearch = async (query, searchFn) => {
        return new Promise(async (resolve) => {
            const { data } = await searchFn({ ...likeFilter('name', query) });
            resolve(data);
        });
    }

	onCollectionAdd = (event, field, callback) => {
		event && event.preventDefault();
		this.props.data[field].push({});
		this.props.onChangeHandler(this.props.data[field], field, callback);
	}

	onCollectionChange = (id, item, index, field) => {
		let data = this.props.data[field];
		data[index] = item;
		this.props.onChangeHandler(data, field);
		this.onCollectionWasChanged(field);
	}

	onCollectionRemove = (event, index, field) => {
		event.preventDefault();
		const data = this.props.data[field];
		data.splice(index, 1);
		this.props.onChangeHandler(data, field);
		this.onCollectionWasChanged(field);
	}

	onCollectionWasChanged = (field) => {
    	if ('vendor_companies' === field && this.props.data) {
		    this.fetchVendorCompanyContacts(this.props.data);
	    }
	}

    render() {
        const {
        	t,
	        data,
	        onAddContact,
	        onRemoveContact,
	        onChangeContactHandler
        } = this.props;
        if (!data) return null;
        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.visible} toggle={this.onCancel}>
                <Form model={data} onSubmit={this.handleSubmit} ref="form" rules={this.state.rules} labelWidth="160">
                    <ModalHeader toggle={this.onCancel}>{data.id ? t('vendorFactory.edit') : t('vendorFactory.add')}</ModalHeader>
                    <ModalBody className="scrollable-modal">
                        <Layout.Row>
                            <Layout.Col span="22">
                                <Layout.Row>
                                    <Form.Item label={t('global.name_jp')} prop="name_jp">
                                        <Input
                                            value={data.name_jp}
                                            onChange={(value) => this.props.onChangeHandler(value, 'name_jp')}
                                        />
                                    </Form.Item>
                                    <Form.Item label={t('global.name_en')} prop="name_en">
                                        <Input
                                            value={data.name_en}
                                            onChange={(value) => this.props.onChangeHandler(value, 'name_en')}
                                        />
                                    </Form.Item>
                                    <Form.Item label={t('global.name_ch')} prop="name_ch">
                                        <Input
                                            value={data.name_ch}
                                            onChange={(value) => this.props.onChangeHandler(value, 'name_ch')}
                                        />
                                        {this.state.err && <div className="el-form-item__error">{this.state.err}</div>}
                                    </Form.Item>
                                    {this.props.canAddVendorCompany &&
                                    <Form.Item label={t('global.businessCompany')} prop="business_companies">
                    <FormCollection
											field={'business_companies'}
											items={data.business_companies}
											options={this.state.businessCompanies}
											onAdd={this.onCollectionAdd}
											onChange={this.onCollectionChange}
											onRemove={this.onCollectionRemove}
										/>
                                    </Form.Item>}
                                    {this.props.canAddVendorCompany &&
                                    <Form.Item label={t('global.vendorCompany')} prop="vendor_companies">
										<FormCollection
											field={'vendor_companies'}
											items={data.vendor_companies}
											options={this.state.vendorCompanies}
											onAdd={this.onCollectionAdd}
											onChange={this.onCollectionChange}
											onRemove={this.onCollectionRemove}
										/>
                                    </Form.Item>}
                                </Layout.Row>
                              {this.props.canAddVendorCompany &&
	                            <Form.Item label={t('vendorFactory.vendorCompanyContact')} prop="test">
		                            <FormCollection
			                            field={'vendor_company_contacts'}
			                            items={data.vendor_company_contacts}
			                            options={this.state.vendorCompanyContacts}
			                            onAdd={this.onCollectionAdd}
			                            onChange={this.onCollectionChange}
			                            onRemove={this.onCollectionRemove}
			                            required={false}
		                            />
	                            </Form.Item>}
                                <Layout.Row>
                                    {permission('vendor-company:create') &&
										this.props.canAddVendorCompany &&
										<Layout.Col md="24">
											<Button className="pull-right" onClick={this.props.onAddVendorCompany}>
												{t('vendorCompany.add')}
											</Button>
										</Layout.Col>
                                    }
                                </Layout.Row>
																{this.props.canAddVendorCompany &&
                                <Form.Item label={t('global.productCategory')} prop="product_categories">
									<FormCollection
										field={'product_categories'}
										items={data.product_categories}
										options={this.state.productCategories}
										onAdd={this.onCollectionAdd}
										onChange={this.onCollectionChange}
										onRemove={this.onCollectionRemove}
									/>
                                </Form.Item>}
                                <Layout.Row>
                                    <Form.Item label={t('global.address_jp')} prop="address_jp">
                                        <Input
                                            value={data.address_jp}
                                            onChange={(value) => this.props.onChangeHandler(value, 'address_jp')}
                                        />
                                    </Form.Item>
                                </Layout.Row>
                                <Layout.Row>
                                    <Form.Item label={t('global.address_en')} prop="address_en">
                                        <Input
                                            value={data.address_en}
                                            onChange={(value) => this.props.onChangeHandler(value, 'address_en')}
                                        />
                                    </Form.Item>
                                </Layout.Row>
                                <Layout.Row>
                                    <Form.Item label={t('global.address_ch')} prop="address_ch">
                                        <Input
                                            value={data.address_ch}
                                            onChange={(value) => this.props.onChangeHandler(value, 'address_ch')}
                                        />
                                    </Form.Item>
                                </Layout.Row>
                                {this.props.canAddVendorCompany &&
                                <Layout.Row>
                                    <Form.Item label={t('company.country')}>
                                        <AsyncSelect
                                            selected={data.country}
                                            value={data.country_id}
                                            remoteMethod={(value) => this.onSearch(value, apiSearchCountry, 'countries')}
                                            onChange={(value) => this.props.onChangeHandler(value, 'country_id')}
                                            subLabel="code"
                                        />
                                    </Form.Item>
                                </Layout.Row>
                                }
                              {this.props.canAddVendorCompany &&
	                            <Contacts
		                            contacts={data.contacts}
		                            onAdd={onAddContact}
		                            onRemove={onRemoveContact}
		                            onChange={onChangeContactHandler}
																isTypeRequired={false}
	                            />}
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onCancel}>
                            {t('global.cancel')}
                        </Button>
                        <Button type="primary" nativeType="submit">
                            {t('global.submit')}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(VendorFactoryForm);
