import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const ResultsNumber = (props) => {
    const { t, count, allData } = props;
    return (
        <p className="results-number">
	        {allData ? t('factoryEvaluation.allResults', {count: count}) : t('factoryEvaluation.extractedResults', {count: count})}
        </p>
    )
};

ResultsNumber.propTypes = {
    count: PropTypes.number.isRequired,
    allData: PropTypes.bool
};

export default withNamespaces()(ResultsNumber);
