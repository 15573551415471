import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { connect } from 'react-redux';
import { Layout, Input, Form, Loading } from 'element-react';
import { withRouter } from 'react-router-dom';
import permission from '../../utils/permission';
import DownloadCsv from '../../components/Common/DownloadCsv';
import { withFilter } from '../../hoc';
import { Pagination, Select } from '../../components/Common';
import { apiSaveFactoryEvaluation, apiQuickUpdateFactoryEvaluation } from '../../api/factory-evaluation';
import { apiGetPreference } from '../../api/preferences.js';
import { FactoryEvaluationList } from '../../components/FactoryEvaluation';
import SearchPanel from '../../components/Common/SearchPanel';
import { FactoryEvaluationModel } from '../../models';
import ResultsNumber from '../../components/Common/ResultsNumber';
import ConfigModalWrapper from '../../components/Common/ConfigModalWrapper';
import FrozenRows from '../../components/Common/FrozenRows';
import Websocket from '../../config/echo';
import hasTag from '../../utils/hasTag';
import { apiGetFilters } from '../../api/factory-evaluation';

import {
    fetchFactoryEvaluation,
    fetchStaticData,
    setStaticDataLoaded,
    setFilterFactoryEvaluation,
    changeFreezePane
} from './../../store/actions/actions';

class FactoryEvaluation extends Component {
    _isMounted = false;
    state = {
	    filters: {},
        isRenderList: false,
        firstLoading: true,
        freezePaneColumns: [
            { value: 1, name: this.props.t('factoryEvaluation.businessCo') },
	        { value: 2, name: this.props.t('factoryEvaluation.category') },
	        { value: 3, name: this.props.t('factoryEvaluation.businessCoOther') },
	        { value: 4, name: this.props.t('factoryEvaluation.subCategory') },
	        { value: 5, name: this.props.t('factoryEvaluation.workingGroup') },
	        { value: 6, name: this.props.t('factoryEvaluation.productItem') },
	        { value: 7, name: this.props.t('factoryEvaluation.factory_asia') },
	        { value: 8, name: this.props.t('factoryEvaluation.factory_ch') },
	        { value: 9, name: this.props.t('factoryEvaluation.auditNumber') },
	        { value: 12, name: this.props.t('factoryEvaluation.certificateMailDate') },
	        { value: 13, name: this.props.t('factoryEvaluation.certificateMailReceipt') },
            { value: 14, name: this.props.t('factoryEvaluation.effectiveDate') },
            { value: 15, name: this.props.t('factoryEvaluation.expirationDate') },
        ]
    };

    async componentDidMount() {
        this._isMounted = true;
        await this.getStaticData();
        await this.getPulldownData();
        await this.getPref();

	    if (this.props.filterData.search_panel) {
		    await this.filterBar(this.props.filterData);
	    } else {
		    await this.filter();
	    }

        this.props.location.state && this.props.location.state.section && this.goToSection(this.props.location.state.section);
        this.commentListen();
    }

    commentListen = () => {
	    Websocket.run(core => {
		    this.props.factoryEvaluations.forEach(item => {
			    core
				    .private(`evaluation_editing_any.${item.id}`)
				    .listen('EvaluationFieldEdited', () => this.filter());
		    });
	    });
	}

    componentWillUnmount() {
        this._isMounted = false;
    }

    goToSection = (section) => {
      let grid = document.getElementsByClassName('scroll-grid')[0];
      let item = section === 'qc' ? document.getElementsByClassName(`factory-examination-item`)[0] : document.getElementsByClassName(`csr-item`)[0];
      item && grid.scroll(item.offsetLeft - 150, 0);
    }

    getPulldownData = async() => {
      let countries = await apiGetFilters('country_name');
      let auditYears = await apiGetFilters('audit_year');
      auditYears = auditYears.data.map((el) => {
        return {name: el.name, id: el.name}
      });
      let productCategories = await apiGetFilters('product_category')
      let businessCompanies = await apiGetFilters('business_company');
      this.setState({
          countries: countries.data,
          auditYears: auditYears,
          businessCompanies: businessCompanies.data,
          productCategories: productCategories.data
      })
    }

    filter = async (params) => {
        const filterColumns = [
        	'freeze',
        	'id',
	        'audit_date',
	        're_audit_due_date',
	        're_audit_planned_date',
	        'preferred_audit_date',
	        'total_status_id',
        	'effective_date',
	        'expiration_date',
	        'country',
			'vendor_company_name',
	        'vendor_company_name_en',
			'vendor_factory_name',
	        'vendor_factory_name_en',
          'factory_address_local',
          'factory_address_en',
	        'factory_evaluations_$table.business_company_id',
            'factory_evaluations_$table.product_category_id',
	        'factory_evaluations_$table.vendor_company_id:name_ch',
            'factory_evaluations_$table.vendor_factory_id:name_ch',
            'audit_year',
	        'audit_saq_file',
	        'audit_scap_file',
	        'audit_evidence_file',
	        'audit_cert_file',
	        'audit_related_file',
            'audit_number',
            'no_of_major',
            'no_of_minor',
            'no_of_quality',
            'no_of_c1',
            'no_of_c2',
            'status_changed_date',
            'evaluation_status_id',
            'evaluation_result_id',
            'audit_category_id',
            'audit_type_id',
            'deadline_id',
            'exception_flag_id',
            'post_audit_status_id',
            'certification_issuance_id',
	        'product_id',
	        'working_group_id',
	        'product_sub_category_id',
	        'latest_evaluation_score_id',
            'previous_evaluation_score',
	        'nc',
	        'no_of_evidences',
	        'scap_received_date',
	        'scap_deadline_date',
			'comment_hd_co',
			'comment_tuv_hd',
			'comment_co_tuv',
	        'certificate_mail_date',
	        'certificate_mail_receipt',
            'judgement_due_date',
			'judgement_decision',
	        'business_company_other',
	        'auditor_in_charge',
			'exam_factory_examination_date',
			'exam_mfg_process_auditor',
			'exam_total_evaluation',
			'audit_exam_evid_sheet_file',
			'audit_exam_evid_rep_file',
			'exam_judgement_rank_id',
			'num_of_cert',
			'mfg_total_status_id',
			'cert_issuance_date',
			'cert_effective_date',
			'cert_expiration_date',
			'mfg_audit_year',
			'mfg_institution_id',
			'mfg_planned_month',
			'mfg_audit_date',
			'curr_year_total_eval',
			'curr_year_judgement_rank_id',
			'audit_mfg_evid_sheet_file',
			'audit_mfg_evid_rep_file',
			'mfg_comment_hd_bus',
			'md_name',
	        'evaluation_result_previous',
	        'is_new',
	        'certificate_fee',
	        'audit_fee',
	        'cancel_fee',
	        'reaudit_fee',
	        'misc_fee',
	        'care_fee',
	        'audit_fee',
	        'misc_date_fee',
	        'care_date_fee',
	        'audit_date_fee',
	        'cancel_date_fee',
	        'certificate_date_fee',
	        'reaudit_date_fee',
			'scap_file_warning',
			'evidence_file_warning',
			'factory_code',
			'jan_code',
			'division_number',
			'organization_name',
      'audit_number_previous',
        ];

        const newFilter = { ...this.props.filterData, ...params };
        let filter = {
            limit: newFilter.limit,
            page: newFilter.page,
            ...this.props.likeFilter('vendor_factory', newFilter.name),
            ...this.props.orderBy(newFilter.orderBy),
        };

		if (this.props.match.params.id) {
			filter.from = this.props.match.params.id;
		}

        filterColumns.forEach(column => filter = {
            ...filter,
            ...this.props.whereInFilter(column, newFilter[column])
        });

        await this.props.fetchFactoryEvaluation({ ...filter });
        if (this._isMounted) {
            this.setState(prevState => ({
                filters: filter,
                firstLoading: false,
                isRenderList: !prevState.isRenderList
            }));
        }
    };

    filterBar = async ( params ) => {
      const newFilter = { ...this.props.filterData, ...params }
      let filter = {
        limit: newFilter.limit,
        page: newFilter.page,
        search_panel: true,
    };

      for ( let i = 0; i < Object.keys(newFilter).length; i++) {
          if (Object.keys(newFilter)[i] === 'total' || Object.keys(newFilter)[i] === 'totalPage') {
              continue
          } else if (Object.keys(newFilter)[i] === 'freeze') {
              filter = {
                  ...filter,
                  ...this.props.whereInFilter('freeze', newFilter.freeze)
              }
          } else {
              filter = {
                  ...filter,
                  ...this.props.whereFilter(Object.keys(newFilter)[i], newFilter[Object.keys(newFilter)[i]])
              }
          }
      }
      this.props.setAllFilters(filter)
      await this.props.fetchFactoryEvaluation(filter);
      if (this._isMounted) {
          this.setState(prevState => ({
              filters: filter,
              firstLoading: false,
              isRenderList: !prevState.isRenderList
          }));
      }
    }
    resetFilterBar = async (params) => {
      let filter = {
        limit: 15,
        page: 1,
        search_panel: false,
      };
      this.props.setAllFilters(filter)
      await this.props.fetchFactoryEvaluation(filter);
      if (this._isMounted) {
          this.setState(prevState => ({
              filters: filter,
              firstLoading: false,
              isRenderList: !prevState.isRenderList
          }));
      }
    }

    onSubmit = async () => {
        await this.props.onSubmit(FactoryEvaluationModel, apiSaveFactoryEvaluation);
        this.filter();
    };

    filterFreezePaneColumns(columns) {
        return columns.filter(item => {
	        if (item.name === this.props.t('factoryEvaluation.preferredAuditDate')) {
		        return permission('preferred-audit-date:view');
	        }
	        if (item.name === this.props.t('factoryEvaluation.certificateMailDate')) {
		        return permission('certificate-mail-date:view');
	        }
	        if (item.name === this.props.t('factoryEvaluation.certificateMailReceipt')) {
		        return permission('certificate-mail-receipt:view');
	        }
	        if (item.name === this.props.t('factoryEvaluation.subCategory')) {
		        return hasTag(this.props.user, '7P');
	        }
	        if (item.name === this.props.t('factoryEvaluation.workingGroup')) {
		        return hasTag(this.props.user, '7P');
	        }
	        if (item.name === this.props.t('factoryEvaluation.productItem')) {
		        return hasTag(this.props.user, '7P');
	        }
            return true;
        });
    }

    addFreezeId = (id) => {
	    if (!this.props.filterData.freeze.includes(id)) {
		    this.props.filterData.freeze.push(id);
	    }
	    this.props.onChangeWithFilter(this.props.filterData.freeze, 'freeze');
    };

	clearFreezeId = () => {
		this.props.filterData.freeze = [];
		this.props.onChangeWithFilter(this.props.filterData.freeze, 'freeze');
	};

    onChangeHandler = async (value, key, id) => {
	    await apiQuickUpdateFactoryEvaluation(id, {[key]: value});
      if (!this.props.filterData['search_panel']) {
	        this.addFreezeId(id);
          this.filter();
      } else {
          this.filterBar();
      }
    };

    onChangeWithFilter = (value, key) => {
        this.props.onChangeWithFilterAndResetPage(value, key);
        this.setState(prevState => ({
            isRenderList: !prevState.isRenderList
        }));
    };

    getStaticData = () => {
        if (!this.props.staticDataLoaded) {
            Promise.all([
                this.props.fetchStaticData('evaluation_statuses'),
                this.props.fetchStaticData('evaluation_results'),
                this.props.fetchStaticData('root_product_categories'),
                this.props.fetchStaticData('deadlines'),
                this.props.fetchStaticData('audit_types'),
                this.props.fetchStaticData('audit_categories'),
                this.props.fetchStaticData('exception_flags'),
                this.props.fetchStaticData('post_audit_statuses'),
                this.props.fetchStaticData('certification_issuances'),
	            this.props.fetchStaticData('total_statuses'),
	            this.props.fetchStaticData('product_sub_categories'),
	            this.props.fetchStaticData('products'),
	            this.props.fetchStaticData('latest_evaluation_scores'),
	            this.props.fetchStaticData('judgement_ranks'),
	            this.props.fetchStaticData('mfg_total_statuses'),
	            this.props.fetchStaticData('mfg_institutions'),
            ]).then(() => {
                this.props.setStaticDataLoaded();
                if (this._isMounted) {
                    this.setState(prevState => ({
	                    isRenderList: !prevState.isRenderList
                    }));
                }
            })
        }
    };

    gotoEdit = (id) => {
	    if (this.props.match.params.id) {
		    this.props.onTextSearch('');
	    }
        this.props.history.push(`/factory-evaluation/${id}`);
    };

    changeFreezePaneHandler = (value) => {
        this.props.changeFreezePane(value);
        this.setState(prevState => ({ isRenderList: !prevState.isRenderList }));
    };

	filterProducts = () => {
		let { products } = this.props;
		return products ? products : [];
	};

    filterWorkingGroups = () => {
    	let { product_sub_categories } = this.props;
	    if (typeof product_sub_categories === 'object') {
		    return product_sub_categories.filter(i => i.parent_id);
	    }
	    return [];
    };

	filterProductSubCategories = () => {
		let { product_sub_categories } = this.props;
		if (typeof product_sub_categories === 'object') {
			return product_sub_categories.filter(i => !i.parent_id);
		}
		return [];
	};

	getPref = async () => {
		let preferences = await apiGetPreference('evaluation_listing');
		preferences = Object.values(preferences);
		preferences.pop();
		this.setState({
			preferences: preferences,
			freezePaneColumns: [
	            { value: preferences.map(e => e.id).indexOf('business_company') + 1, name: this.props.t('factoryEvaluation.businessCo') },
	            { value: preferences.map(e => e.id).indexOf('product_category') + 1, name: this.props.t('factoryEvaluation.category') },
				{ value: preferences.map(e => e.id).indexOf('business_company_other') + 1, name: this.props.t('factoryEvaluation.businessCoOther') },
		        { value: preferences.map(e => e.id).indexOf('working_group') + 1, name: this.props.t('factoryEvaluation.workingGroup') },
		        { value: preferences.map(e => e.id).indexOf('product_sub_category') + 1, name: this.props.t('factoryEvaluation.subCategory') },
		        { value: preferences.map(e => e.id).indexOf('product') + 1, name: this.props.t('factoryEvaluation.productItem') },
		        { value: preferences.map(e => e.id).indexOf('vendor_factory') + 1, name: this.props.t('factoryEvaluation.factory_asia') },
		        { value: preferences.map(e => e.id).indexOf('vendor_factory_en') + 1, name: this.props.t('factoryEvaluation.factory_en') },
		        { value: preferences.map(e => e.id).indexOf('audit_number') + 1, name: this.props.t('factoryEvaluation.auditNumber') },
		        { value: preferences.map(e => e.id).indexOf('certificate_mail_date') + 1, name: this.props.t('factoryEvaluation.certificateMailDate') },
		        { value: preferences.map(e => e.id).indexOf('certificate_mail_receipt') + 1, name: this.props.t('factoryEvaluation.certificateMailReceipt') },
	            { value: preferences.map(e => e.id).indexOf('effective_date') + 1, name: this.props.t('factoryEvaluation.effectiveDate') },
	            { value: preferences.map(e => e.id).indexOf('expiration_date') + 1, name: this.props.t('factoryEvaluation.expirationDate') },
			]
		})
	};

	openConfigModal = () => {
		this.setState({isOpenConfigModal: true})
	};

	closeConfigModal = () => {
		this.setState({isOpenConfigModal: false})
	};

  showFilters = () => {
      this.setState({filtersShown: true})
  }
  hideFilters = () => {
      this.setState({filtersShown: false})
  }

	isFilterApplied = () => {
		let filters = Object.assign({}, this.state.filters);
		delete filters['freeze[]'];
		delete filters['search_panel'];
		return Object.keys(filters).length > 2;
	}

    render() {
        let { filterData, factoryEvaluations, language, t } = this.props;
        let isFilterApplied = this.isFilterApplied();

        return (
            <ContentWrapper>
            {this.state.isOpenConfigModal &&
                <ConfigModalWrapper
                    isOpen={this.state.isOpenConfigModal}
                    close={this.closeConfigModal}
                    data={this.state.preferences}
                    is7p={hasTag(this.props.user, '7P')}
                    isHldgs={hasTag(this.props.user, 'hldgs')}
                />
            }
                <div className="factory-evaluation">
                    <Layout.Row>
                        <Layout.Col sm="12" style={{display: 'flex'}}>
                            <Form inline={true}>
                                <Form.Item>
                                    <Input
                                        value={this.props.filterData.name}
                                        icon="search"
                                        placeholder={t('factoryEvaluation.search')}
                                        onChange={this.props.onTextSearch}
                                    />
                                </Form.Item>
                            </Form>
                            {isFilterApplied && <ResultsNumber count={filterData.total} />}
	                        {this.props.filterData.freeze &&
	                            <FrozenRows
		                            count={filterData.freeze.length}
		                            clear={this.clearFreezeId}
	                            />
	                        }
                        </Layout.Col>
                        <Layout.Col sm="12">
                            <div className="pagination-right">
                                <label className="el-form-item__label">
                                    {t('factoryEvaluation.freezePanes')}
                                </label>
                                <div className="freezepane-input">
                                    <Select
                                        value={this.props.freezePaneIndex}
                                        isFixed
                                        isClearable={false}
                                        onChange={this.changeFreezePaneHandler}
                                        options={this.filterFreezePaneColumns(this.state.freezePaneColumns)}
                                        label={`name`}
                                        uniqueKey="value"
                                    />
                                </div>
                                <Pagination
                                    pageCount={filterData.totalPage}
                                    total={filterData.total}
                                    pageSize={filterData.limit}
                                    initialPage={filterData.page}
                                    onPageChange={this.props.onPageChange}
                                />
	                            {permission('evaluation-export:view') &&
		                            <div className="ml-2">
			                            <DownloadCsv
				                            lang={this.props.lng}
				                            subject={'evaluations'}
				                            filters={this.state.filters}
			                            />
		                            </div>
	                            }
							<div className='settings-icon' onClick={this.openConfigModal}><img alt="columns settings" src="img/menu/cogwheel.svg" /></div>
                            </div>
                        </Layout.Col>
                    </Layout.Row>
                        {!this.state.filtersShown &&
                        <div onClick={this.showFilters} className='open-search'>{t('factoryEvaluation.searchPanel.title')}<i className='fa fa-caret-down'></i></div>}
                        <div id="newFilters" style={{display: this.state.filtersShown ? 'flex' : 'none', opacity: this.state.filtersShown ? '1' : '0'}}>
                        <button onClick={this.hideFilters} style={{position: 'absolute', right: '0', top: '0'}} type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <SearchPanel
                            t = {t}
                            data={this.props.filterData}
                            language={language}
                            filterBar={this.filterBar}
                            reset={this.resetFilterBar}
                            filters={[
                                {
                                    name: t('factoryEvaluation.searchPanel.keyword'),
                                    accessor: 'keyword',
                                    type: 'text',
                                    width: '200px',
                                    tooltip: true
                                },
                                {
                                    name: t('factoryEvaluation.businessCo'),
                                    accessor: 'business_company_id',
                                    type: 'select',
                                    values: this.state.businessCompanies,
                                    singleName: true,
                                    width: '200px'
                                },
                                {
                                    name: t('factoryEvaluation.category'),
                                    accessor: 'product_category_id',
                                    type: 'select',
                                    values: this.state.productCategories,
                                    singleName: true,
                                    width: '120px'
                                },
                                {
                                    name: t('factoryEvaluation.totalStatus'),
                                    accessor: 'total_status_id',
                                    type: 'select',
                                    values: this.props.total_statuses,
                                    width: '280px'
                                },
                                {
                                    name: t('factoryEvaluation.auditYear'),
                                    accessor: 'audit_year',
                                    type: 'select',
                                    values: this.state.auditYears,
                                    singleName: true,
                                    width: '118px'
                                },
                                {
                                    name:  t('factoryEvaluation.location'),
                                    accessor: 'country_id',
                                    type: 'select',
                                    values: this.state.countries,
                                    singleName: true,
                                    width: '353px'
                                },
                                {
                                    name:  t('factoryEvaluation.preferredAuditMonth'),
                                    accessor: 'preferred_audit_date_range',
                                    type: 'date',
                                    isMonth: true,
                                    width: '270px'
                                },
                                {
                                    name: t('factoryEvaluation.auditDate'),
                                    accessor: 'audit_date_range',
                                    type: 'date',
                                    width: '270px'
                                },
                                {
                                    name: t('factoryEvaluation.latestEvaluation'),
                                    accessor: 'latest_evaluation_score_id',
                                    type: 'select',
                                    values: this.props.latest_evaluation_scores,
                                    width: '200px'
                                },
                                {
                                    name: t('factoryEvaluation.evaluationResult'),
                                    accessor: 'evaluation_result_id',
                                    type: 'select',
                                    values: this.props.evaluation_results,
                                    width: '322px',
	                                withNull: true
                                },
                                {
                                    name:  t('factoryEvaluation.judgementDueDate'),
                                    accessor: 'judgement_due_date_range',
                                    type: 'date',
                                    width: '270px'
                                },
	                            {
		                            name:  t('factoryEvaluation.judgementDate'),
		                            accessor: 'judgement_date_range',
		                            type: 'date',
		                            width: '270px'
	                            },
                                {
                                    name: t('factoryEvaluation.reAuditPlannedDate'),
                                    accessor: 're_audit_planned_date_range',
                                    type: 'date',
                                    width: '270px'
                                },
                                {
                                    name: t('factoryEvaluation.scapDeadline'),
                                    accessor: 'scap_deadline_date_range',
                                    type: 'date',
                                    width: '270px'
                                },
	                            {
		                            name: t('factoryEvaluation.auditCancelDate'),
		                            accessor: 'audit_cancel_date_range',
		                            type: 'date',
		                            width: '270px'
	                            },
	                            {
		                            name: t('factoryEvaluation.certificateIssuanceDate'),
		                            accessor: 'certificate_mail_date_range',
		                            type: 'date',
		                            width: '270px'
	                            }
                            ]}
                        />
                        </div>

                    <Loading loading={this.state.firstLoading && !factoryEvaluations.length}>
                        <FactoryEvaluationList
                            template={this.props.user.factory_evaluation_template}
                            preferences={this.state.preferences}
                            is7p={hasTag(this.props.user, '7P')}
                            isHldgs={hasTag(this.props.user, 'hldgs')}
                            isRender={this.state.isRenderList}
                            language={language}
                            freezePaneIndex={this.props.freezePaneIndex}
                            filterData={this.props.filterData}
                            factoryEvaluations={factoryEvaluations}
                            onChangeWithFilter={this.onChangeWithFilter}
                            statuses={this.props.evaluation_statuses}
                            onChangeHandler={this.onChangeHandler}
                            onSortByWithFilter={this.props.onSortByWithFilter}
                            evaluationResults={this.props.evaluation_results}
                            rootProductCategories={this.props.root_product_categories}
                            auditTypes={this.props.audit_types}
                            auditCategories={this.props.audit_categories}
                            deadlines={this.props.deadlines}
                            exceptionFlags={this.props.exception_flags}
                            postAuditStatuses={this.props.post_audit_statuses}
                            certificationIssuances={this.props.certification_issuances}
                            totalStatuses={this.props.total_statuses}
                            latestEvaluationScores={this.props.latest_evaluation_scores}
                            products={this.filterProducts()}
                            workingGroups={this.filterWorkingGroups()}
                            productSubCategories={this.filterProductSubCategories()}
                            refresh={this.props.filterData.search_panel ? this.filterBar : this.filter}
                            addFreezeId={this.addFreezeId}
                            judgementRanks={this.props.judgement_ranks}
                            mfgTotalStatuses={this.props.mfg_total_statuses}
                            mfgInstitutions={this.props.mfg_institutions}
                            userName={this.props.user.group_name}
                        />
                    </Loading>
                </div>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        factoryEvaluations: state.factoryEvaluation.factoryEvaluations,
        filterData: state.factoryEvaluation.filterData,
        evaluation_statuses: state.staticData.evaluation_statuses,
        evaluation_results: state.staticData.evaluation_results,
        root_product_categories: state.staticData.root_product_categories,
        audit_types: state.staticData.audit_types,
        audit_categories: state.staticData.audit_categories,
        deadlines: state.staticData.deadlines,
        exception_flags: state.staticData.exception_flags,
        post_audit_statuses: state.staticData.post_audit_statuses,
        certification_issuances: state.staticData.certification_issuances,
	    total_statuses: state.staticData.total_statuses,
	    products: state.staticData.products,
	    product_sub_categories: state.staticData.product_sub_categories,
	    latest_evaluation_scores: state.staticData.latest_evaluation_scores,
		judgement_ranks: state.staticData.judgement_ranks,
		mfg_total_statuses: state.staticData.mfg_total_statuses,
		mfg_institutions: state.staticData.mfg_institutions,
        staticDataLoaded: state.staticData.isLoaded,
        user: state.auth.user,
        language: state.settings.language,
        freezePaneIndex: state.settings.freezePaneIndex
    };
};

const mapDispatchToProps = dispatch => ({
    fetchFactoryEvaluation: (params) => dispatch(fetchFactoryEvaluation(params)),
    fetchStaticData: (table) => dispatch(fetchStaticData(table)),
    setStaticDataLoaded: () => dispatch(setStaticDataLoaded()),
    changeFreezePane: (value) => dispatch(changeFreezePane(value)),
    setReduxFilter: (params) => dispatch(setFilterFactoryEvaluation(params))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(FactoryEvaluation))));
