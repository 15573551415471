import React, {Component} from "react";
import Message from "../../utils/Message";
import ContentWrapper from "../../components/Layout/ContentWrapper";
import { Button,Loading } from 'element-react';
import { ProductSubCategoryList, ProductSubCategoryForm, ProductSubCategoryEditForm } from "../../components/ProductSubCategory";
import { Pagination } from '../../components/Common';
import permission from "../../utils/permission";
import * as actions from './product-sub-category.actions';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import { withFilter } from '../../hoc';
import { apiGetAllProductSubCategory, apiDeleteProductSubCategory } from "../../api/product-sub-category"

class ProductSubCategory extends Component {
	_isMounted = false;
	state = {
			isLoading: true,
			productSubCategories: [],
			selectedItem: null,
			filters: {},
			isAddModalOpen: false
	}

	async componentDidMount() {
			this._isMounted = true;
      this.filter();
	}

	onOpenAddModal = () => {
			this.setState({ isAddModalOpen: true });
	}

	onOpenEditModal = (product) => {
			this.setState({ isEditModalOpen: true, selectedItem: product });
	}

	onCloseEditModal = () => {
			this.setState ({ isEditModalOpen: false });
	}

	onCloseAddModal = () => {
			this.setState ({ isAddModalOpen: false });
	}

  onDeleteProductSubCategory = async (id) => {
      await this.props.onDelete(id, apiDeleteProductSubCategory);
			await this.filter();
  }

  filter = async (params) => {
      const filterColumns = [
          'working_group'
      ];

      const newFilter = { ...this.props.filterData, ...params };
      let filter = {
          limit: newFilter.limit,
          page: newFilter.page,
          ...this.props.likeFilter('name', newFilter.name)
      };
      filterColumns.forEach(column => filter = {
          ...filter,
          ...this.props.whereInFilter(column, newFilter[column])
      });
      await this.props.fetchProductSubCategory(filter);
      if (this._isMounted) {
          this.setState({
              filters: filter,
              firstLoading: false
          });
      }
  }

  onChangeWithFilter = (value, key) => {
      this.props.onChangeWithFilterAndResetPage(value, key);
      this.setState(prevState => ({
          isRenderList: !prevState.isRenderList
      }));
  };

	render() {
    const { t, filterData } = this.props;
		return (
			<div>
          <Loading loading={(this.props.isLoading)}>
							<ProductSubCategoryForm
									isOpen={this.state.isAddModalOpen}
									lang={this.props.language}
									onSubmit={this.props.onSubmit}
									onCancel={this.onCloseAddModal}
									refresh={this.filter}
							/>
							{this.state.selectedItem && <ProductSubCategoryEditForm
									isOpen={this.state.isEditModalOpen}
									lang={this.props.language}
									onSubmit={this.props.onSubmit}
									productSubCategory={this.state.selectedItem}
									onCancel={this.onCloseEditModal}
									refresh={this.filter}
							/>}
							<ProductSubCategoryList
	                productSubCategories={this.props.productSubCategories}
                  filterData={filterData}
                  onChangeWithFilter={this.onChangeWithFilter}
									onOpenAddModal={this.onOpenAddModal}
									onOpenEditModal={this.onOpenEditModal}
									onDelete={this.onDeleteProductSubCategory}
									lang={this.props.language}
							/>
              <Pagination
									pageCount={filterData.totalPage}
									total={filterData.total}
									pageSize={filterData.limit}
									initialPage={filterData.page}
									onPageChange={this.props.onPageChange}
							/>
					</Loading>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return {
				isLoading: state.productSubCategory.loading,
        productSubCategories: state.productSubCategory.productSubCategories,
        filterData: state.productSubCategory.filterData,
				language: state.settings.language
    };
};

const mapDispatchToProps = dispatch => ({
    fetchProductSubCategory: (params) => dispatch(actions.fetchProductSubCategory(params)),
    setReduxFilter: (params) => dispatch(actions.setFilterProductSubCategory(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(ProductSubCategory)));
