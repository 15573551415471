import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const FrozenRows = (props) => {
	const { t, count, clear } = props;
	if (!count) {
		return null;
	}
	return (
		<p className="results-number">
			{t('factoryEvaluation.frozenRows', {count: count})}
			<a style={{'paddingLeft' : '10px', 'cursor': 'pointer'}} onClick={() => clear()}>(x)</a>
		</p>
	)
};

FrozenRows.propTypes = {
	clear: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired
};

export default withNamespaces()(FrozenRows);
