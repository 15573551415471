import React from 'react';

import { Component } from './libs';
import FilterContent from './FilterContent';

function getDescendantProp(obj, desc) {
    if (!desc) return;
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
}

function resolveAccessor(accessor, item) {
  return typeof accessor === 'function' ? accessor(item): accessor;
}

function getTrProps(fn, item) {
    return fn && fn(item);
}

let timer;
function columnAction(e, column, item) {
    let list = document.getElementsByClassName('expanded')
    let target = e.currentTarget.firstChild;
    if (e.detail === 1) {
        timer = setTimeout(() => {
            if (list.length > 0) {
                list.forEach((el) => {
                    if (el !== target) {
                        el.classList.remove('expanded')
                    }
                });
            }
            if (target.classList.contains('expanded')) {
                target.classList.remove('expanded')
            } else {
                target.classList.add('expanded')
            }
        }, 300)
    } else {
        clearTimeout(timer);
        column.onDoubleClick && column.onDoubleClick({ ...item });
    }
}

class FixedTable extends Component {
    _common = this.props.columns.filter((el) => el.type === 'common').length
    _csr = this.props.columns.filter((el) => el.type === 'csr').length
    _factoryExamination = this.props.columns.filter((el) => el.type === 'factory-examination').length
    _mfgProcess = this.props.columns.filter((el) => el.type === 'mfg-process').length
    componentDidMount() {
        window.addEventListener('resize', this.improveGridHeight)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.improveGridHeight)
    }
    static getDerivedStateFromProps(props) {
        const {
            data,
            columns,
            columnDecorator,
            preferences
        } = props;
        columnDecorator(preferences, columns);
        const tableBody = (
            <tbody>
                {data.map((item, index) => (
                    <tr key={`${item.id}-${index}`} {...getTrProps(props.getTrProps, item)}>
                        <td className='freeze' style={{boxShadow: '0 0 0 2px #fff', padding: '0 26px 0 0', verticalAlign: 'middle', backgroundColor:'#fff'}}><span style={{textAlign: 'center',position: 'absolute', width: '100%', transform: 'translate(0, -50%)'}}>{index+1}</span></td>
                        {preferences && preferences.map((preference) => (
                            columns.map((column, idx) => column.id === preference.id && true !== column.skip && (true === column.isVisible || 1 === column.isVisible) && (
                            <td key={`${resolveAccessor(column.accessor, item)}-${idx}`}
                                onClick={column.isExpandable ? (e) => columnAction(e, column, item) : () => {} }
                                style={true !== column.skip && (true === column.isVisible || 1 === column.isVisible) && column.fixed ? {left: `${column.left}px`} : {}}
                                className={`${column.className ? column.className : ''} ${column.classNameFunc ? column.classNameFunc(item) : ''} ${column.fixed ? 'freeze' : ''} ${(column.fixed && idx < columns.length && !columns[idx + 1].fixed) ? ' last' : ''} ${column.type && column.type + '-item'}`}>
                                <div style={{width: column.width ? column.width : 100, height: ['vendor_factory.name_en_or_jp', 'total_status_id', 'certification_issuance_id'].includes(column.accessor) ? 'fit-content' : '68px' }}>
                                    {column.Cell ?
                                        column.Cell({
                                            value: getDescendantProp(item, resolveAccessor(column.accessor, item)),
                                            original: { ...item },
                                            index
                                        })
                                        :
                                        <div>{getDescendantProp(item, resolveAccessor(column.accessor, item))}</div>
                                    }
                                </div>
                            </td>
                        ))))
                       }
                    </tr>
                ))}
            </tbody>
        );
        return { tableBody };
    }

    state = {
        tableBody: null
    }

    generateHeader = (isMainTable = false) => {
        const {
            columns,
            filterData,
            columnDecorator,
            preferences
        } = this.props;
        columnDecorator(preferences, columns);
        const tableHeader = (
            <thead>
                <tr><th className='freeze' style={{left: '2px', boxShadow: '0 0 0 2px #fff'}}><span style={{position: 'absolute', width: '33px', height: '21px', left: '-7px', top: '-21px', backgroundColor: '#fff'}}></span></th>
                    {preferences && preferences.map((preference, number) => (
                        columns.map((column, index) => column.id === preference.id && true !== column.skip && (true === column.isVisible || 1 === column.isVisible) && (
                        <React.Fragment key={`${column.accessor}-${index}`}>
                        <th
                            style={true !== column.skip && (true === column.isVisible || 1 === column.isVisible) && column.fixed ? {left: `${column.left}px`} : {}}
                            className={`${column.className ? column.className : ''} ${column.fixed ? 'freeze' : ''} ${column.type && column.type + '-item'}`}>
                            <div className="inner-wrap">
                                <div style={{ width: column.width ? column.width : 100 }}>
                                    <span style={{position: "absolute", top: "-35px", left: "-8px", fontWeight: '100', width: 'calc(100% + 18px)', textAlign: 'center', backgroundColor: '#fff'}}>{this.getColumnNumber(column.type, index) + 1}</span>
                                    {column.Header}
                                    {column.Desc && <p style={{fontWeight: '400', fontSize: '0.77rem'}}>{column.Desc}</p>}
                                </div>
	                            <div className="filter-triggers">
		                            {column.filters && (column.filters.map((item, i) => (
			                            <FilterContent
				                            id={`${index}-${i}`}
				                            key={`${index}-${i}`}
				                            emptyOnEnd={item.emptyOnEnd}
				                            onlyOne={item.onlyOne}
				                            loadDataFn={item.loadDataFn}
				                            filterOptions={item.filterOptions}
				                            initData={item.filteredValue}
				                            onFilterChange={item.onFilterChange}
				                            sortByChange={this.props.sortByHandler}
				                            sortColumn={item.sortColumn}
				                            currentSortData={filterData.orderBy}
				                            onClick={this.loadFilter}
				                            i18n={this.props.i18n}
			                            >
	                                    <span className="el-table__column-filter-trigger" id={`Popover-${index}-${i}`}>
	                                        <i className={`fa fa-caret-square-down ${(item.filteredValue && item.filteredValue.length) || (filterData.orderBy && filterData.orderBy.column === item.sortColumn) ? "filtered" : ""}`} />
	                                    </span>
			                            </FilterContent>
		                            )))}
	                            </div>
                            </div>
                        </th>
                        </React.Fragment>
                    ))))
                    }
                </tr>
            </thead>
        );

        return tableHeader;
    }

    improveGridHeight() {
        let height = window.innerHeight - 140;
        let grid = document.querySelector('.scroll-grid');
        if (grid) {
            grid.style.height = height + 'px';
        }
    }

    setTopScrollbarWidth() {
        let topBar = document.querySelector('.top-scrollbar__content');
        let table = document.querySelector('.table');
        if (topBar && table) {
            topBar.style.width = table.offsetWidth + 'px';
        }
    }

    syncScrollbars() {
      let topBar = document.querySelector('.top-scrollbar');
      let grid = document.querySelector('.scroll-grid');
      if (grid && topBar) {
          topBar.scrollLeft = grid.scrollLeft
      }
    }

    getColumnNumber(type, index) {
        if (type === 'common') {
            return index + 0
        } else if (type === 'csr') {
            return index + 100 - this._common
        } else if (type === 'factory-examination') {
            return index + 200 - (this._csr + this._common)
        } else if (type === 'mfg-process') {
            return index + 300 - (this._csr + this._common + this._factoryExamination)
        }
    }

    render() {
        let element = document.querySelector('.scroll-grid');
        let topBar = document.querySelector('.top-scrollbar')
        let table = document.querySelector('.table');
        const ro = new ResizeObserver(entries => {
        	if (entries) {
		        this.setTopScrollbarWidth();
		        this.syncScrollbars();
	        }
        });
        if(table) { ro.observe(table) };
        if (element) {
            element.addEventListener('scroll', function () {
                topBar.scrollLeft = element.scrollLeft
                if (this.scrollLeft > 0) {
                    let elements = document.querySelectorAll('.freeze');
                    elements.forEach(item => {
                        if (item && !item.classList.contains('dark')) {
                            item.classList.add('dark');
                        }
                    })
                } else {
                    let elements = document.querySelectorAll('.freeze');
                    elements.forEach(item => {
                        if (item && item.classList.contains('dark')) {
                            item.classList.remove('dark');
                        }
                    })
                }
            });
        }

        if (topBar) {
            topBar.addEventListener('scroll', () => {
                element.scrollLeft = topBar.scrollLeft
            })
        }

        const { tableBody } = this.state;
        this.improveGridHeight();
        return (
            <>
            <div className='top-scrollbar' style={{ height: '17px', overflowX: 'scroll '}}><div className='top-scrollbar__content' style={{ height: '20px' }}></div></div>
            <div className="scroll-grid" style={{paddingTop: '20px'}}>
                <table className="table" style={{'borderCollapse': 'separate'}}>
                    {this.generateHeader(true)}
                    {tableBody}
                </table>
            </div>
            </>
        )
    }
}

export default FixedTable;
