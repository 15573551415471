import axios from '../config/axios';

export function apiGetBusinessCompany(params) {
  return axios.get('business-companies', { params });
};

export function apiDeleteBusinessCompany(id) {
  return axios.delete(`business-companies/${id}`);
};

export function apiSaveBusinessCompany(data) {
  if (data.id) {
    return axios.put(`business-companies/${data.id}`, data);
  } else {
    return axios.post('business-companies', data);
  }
};

export function apiGetAllBusinessCompany() {
  return axios.get('fetch-data/business-companies');
};
