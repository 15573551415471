export default class ContactModel {
	constructor(data = {}) {
		this.id = data.id;
		this.name = data.name;
		this.organization = data.organization;
		this.title = data.title;
		this.phone_number = data.phone_number;
        this.mobile_number = data.mobile_number;
		this.email = data.email;
		this.type = data.type;
	}
}
