export function getEvaluationResultRowStyle(item) {
    const id = item.evaluation_result_id;
    if (typeof item.nc === 'number' && typeof item.no_of_evidences === 'number' && item.nc === item.no_of_evidences) {
        return 'status-bg-green';
    }
    switch (id) {
        case 1:
        case 2:
        case 3:
            return 'status-bg-blue';
        case 4:
            return 'status-bg-red';
        case 5:
            return 'status-bg-yellow';
        case 6:
            return 'status-bg-orange';
        case 7:
        case 8:
            return 'status-bg-grey';
        default:
            return 'status-bg-white';
    }
}
