import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Button } from 'element-react';
import { Input, Select, DatePicker } from '../Common';
import { parseDate } from '../../utils/utility';
import PropTypes from 'prop-types';


class SearchPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.getDefaultData()
		}
	}

	getDefaultData = () => {
		return this.props.filters
			.filter(item => this.props.data[item.accessor])
			.reduce((data, item) => ({...data, [item.accessor]: this.props.data[item.accessor]}), {});
	}

	resolveLabelKey = (filter) => {
		return filter.singleName ? `name` : `name_${this.props.language}`;
	}

  onChangeHandler = (value, name) => {
      let data = this.state.data
      data[name] = value
      this.setState({ data: data })
  }
  onFromDateChange = (value, name) => {
    let data = this.state.data
    data[name] = data[name] ? data[name] : {}
		data[name].from = value
    this.setState({ data: data })
  }
  onToDateChange = (value, name) => {
    let data = this.state.data
    data[name] = data[name] ? data[name] : {}
		data[name].to = value
    this.setState({ data: data })
  }

	getDateRange = (from, to) => {
    let arr = [];
		from ? arr.push(parseDate(from, true)) : arr.push(null)
		to ? arr.push(parseDate(to, true)) : arr.push(null)
    return JSON.stringify(arr);
  }

  prepareData = (state) => {
    let filterData = {}
    filterData = Object.assign({}, state)
    for (const [key, value] of Object.entries(state)) {
      if (typeof value === 'object' && value !== null) {
            let dateRange = this.getDateRange(value.from, value.to)
            filterData[key] = dateRange;
      } else {
          filterData[key] = value
      }
    }
    return filterData
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let filterData = this.prepareData(this.state.data)
		this.props.filterBar(filterData)
  }

	handleReset = async (e) => {
    e.preventDefault();
    await this.setState({ data: {} });
		let filterData = await this.prepareData(this.state.data)
		this.props.reset(filterData)
  }

  renderFilter = (filter) => {
    switch (filter.type) {
        case 'text':
          return (
              <Input
                type="text"
                value={this.state.data[filter.accessor]}
                onChange={(value) => this.onChangeHandler(value, filter.accessor)}
              />
          )
        case 'select':
          return (
              <Select
                value={this.state.data[filter.accessor]}
                options={(filter.withNull ? [{id: 'null', [this.resolveLabelKey(filter)]: '未入力'}].concat(filter.values) : filter.values)}
                label={this.resolveLabelKey(filter)}
                onChange={(value) => this.onChangeHandler(value, filter.accessor)}
              />
          )
        case 'date':
          return (
              <div>
              <DatePicker
                value={this.state.data[filter.accessor] && this.state.data[filter.accessor].from ? this.state.data[filter.accessor].from : null}
                onChange={(value) => this.onFromDateChange(value, filter.accessor)}
                selectionMode={filter.isMonth ? "month" : "day"}
                className="search-datepicker"
              />
              -
              <DatePicker
                value={this.state.data[filter.accessor] && this.state.data[filter.accessor].to ? this.state.data[filter.accessor].to : null}
                onChange={(value) => this.onToDateChange(value, filter.accessor)}
                selectionMode={filter.isMonth ? "month" : "day"}
                className="search-datepicker"
              />
              </div>
          )
	    default:
	    	return ('<div></div>')
        }
  }

  render() {
    const { filters, t } = this.props;
    return (
      <React.Fragment>
      {filters.map((filter) => (
          <div key={filter.name} style={{width: filter.width, margin: '10px 0', display: 'inline-block'}}>
              <span style={{marginLeft: '5px'}}>{filter.name}</span>
              {this.renderFilter(filter)}
              {filter.tooltip && <span style={{color:'#000'}}>{t('factoryEvaluation.searchPanel.note')}</span>}
          </div>
      ))
      }
      <div>
          <Button onClick={this.handleReset} style={{height: '40px', marginTop: '27px'}}>{t('global.reset')}</Button>
          <Button type='primary' onClick={this.handleSubmit} style={{height: '40px', marginTop: '27px'}}>{t('global.submit')}</Button>
      </div>
      </React.Fragment>
	  )}
}

// Proptypes: ...

SearchPanel.defaultProps = {
	withNull: false
}

SearchPanel.propTypes = {
	withNull: PropTypes.bool.isRequired
}

export default withNamespaces()(SearchPanel);
