import React from 'react';
import { Table } from 'reactstrap';
import { TableActions } from '../Common';
import { withNamespaces } from 'react-i18next';
import permission from '../../utils/permission';

export const RoleList = (props) => {
    const { roles, filterData, t } = props;
    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th>{t('role.name')}</th>
                    <th>{t('role.type')}</th>
                    <th>{t('role.assigned')}</th>
                    <th>{t('role.description')}</th>
                    {permission('role:update,delete') && <th/>}
                </tr>
            </thead>
            <tbody>
                {roles.map((role, i) => (
                    <tr key={role.id}>
                        <td align="center">{i + 1 + ((filterData.page - 1) * filterData.limit)}</td>
                        <td>{role.name}</td>
                        <th/>
                        <th style={{'fontSize': '10px', 'width': '200px'}}>
                            {role.assigned.join(', ')}
                        </th>
                        <td>{role.description}</td>
                        {permission('role:update,delete') &&
                            <td align="center">
                                <TableActions
                                    roleName={'role'}
                                    onSelect={() => props.onSelect(role)}
                                    onDelete={() => props.onDelete(role.id)}
                                />
                            </td>
                        }
                    </tr>
                ))}
                {!roles.length &&
                    <tr>
                        <td colSpan="4" align="center">
	                        {t('global.notMatchingRecordsFound')}
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}

export default withNamespaces()(RoleList);
