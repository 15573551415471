import React from 'react';
import { Table } from 'reactstrap';
import { TableActions } from '../Common';
import { withNamespaces } from 'react-i18next';
import { filePath } from '../../utils/variables';
import permission from '../../utils/permission';
import FilterContent from '../Common/FixedTable/FilterContent';
import { apiGetFilters } from '../../api/user';

export const UserList = (props) => {
    const { users, t } = props;
    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>
                        {t('user.image')}
                        <FilterContent
                            id={1}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('image')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['image']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'image')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 1}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['image'] && props.filterData['image'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>
                        {t('user.fullName')}
                        <FilterContent
                            id={2}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('user_name')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['user_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'user_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 2}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['user_name'] && props.filterData['user_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>
                        {t('user.companyName')}
                        <FilterContent
                            id={3}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('company_name')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['company_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'company_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 3}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['company_name'] && props.filterData['company_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>
                        {t('user.email')}
                        <FilterContent
                            id={4}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('email')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['email']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'email')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 4}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['email'] && props.filterData['email'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>
                        {t('user.phone')}
                        <FilterContent
                            id={5}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('phone')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['phone']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'phone')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 5}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['phone'] && props.filterData['phone'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>
                        {t('user.gender')}
                        <FilterContent
                            id={6}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('gender')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['gender']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'gender')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 6}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['gender'] && props.filterData['gender'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
	                <th>{t('user.productCategoryView')}</th>
	                <th>{t('user.productCategoryNotify')}</th>
	                <th colSpan="2">{t('user.isLocked')}</th>
                    {permission('user:update,delete') && <th/>}
                </tr>
            </thead>
            <tbody>
                {users.map((user) => (
                    <tr key={user.id}>
                        <td align="center">{user.image && <img className="image-thumb" src={`${filePath}/${user.image}`} alt="" />}</td>
                        <td>{user.full_name}</td>
                        <td>{user.company_name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{user.gender && t(`user.${user.gender}`)}</td>
	                    <td>{user.product_category_viewables.map(item => item.name).join(', ')}</td>
	                    <td>{user.product_category_notifiables.map(item => item.name).join(', ')}</td>
	                    <td>{user.is_locked && t('global.yes')}</td>
	                    <td>{user.is_locked && <a href="#" className="link" onClick={(event) => props.onUnlock(event, user)}>{t('user.unlock')}</a>}</td>
                        {permission('user:update,delete') &&
                            <td align="center">
                                <TableActions
                                    roleName="user"
                                    onSelect={() => props.onSelect(user)}
                                    onDelete={() => props.onDelete(user.id)}
                                />
                            </td>
                        }
                    </tr>
                ))}
                {!users.length &&
                    <tr>
                        <td colSpan="11" align="center">
	                        {t('global.notMatchingRecordsFound')}
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}

export default withNamespaces()(UserList);
