import React from 'react';
import { Pagination as RPagination } from 'element-react';

const Pagination = (props) => {
    return (
        props.total ?
            <RPagination
                layout="prev, pager, next"
                total={props.total}
                pageSize={props.pageSize}
                currentPage={props.initialPage}
                onCurrentChange={props.onPageChange}
            />
            : null
    )
};

export default Pagination;