import React from 'react';
import { Table } from 'reactstrap';
import { TableActions, Pagination } from '../Common';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from "../../components/Layout/ContentWrapper";
import { Button } from 'element-react';
import permission from 'utils/permission';
import { apiGetFilters } from '../../api/product';
import FilterContent from '../../components/Common/FixedTable/FilterContent';

export const ProductList = (props) => {
    const { products, t } = props;
    return (
			  <div style={{ 'marginTop': '45px' }}>
        <Button type="primary" className="mb-sm" onClick={ props.onOpenAddModal }>Add</Button>
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th style={{ position: 'relative' }}>Sub Category ID
                        <FilterContent
                            id={1}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('product_sub_category')}
                            filterOptions={{
                              id: 'id',
                              label: `name_${props.lang}`
                            }}
                            initData={[]}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'product_sub_category')}
                            i18n={t}
                        >
                        <span className="product-filters" id={'Popover-' + 1}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['product_sub_category'] && props.filterData['product_sub_category'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, idx) => (
                    <tr key={idx}>
                        <td align="center">{product.id}</td>
                        <td>{product.product_sub_category_id}</td>
                        <td>{product.name}</td>
                        <td align="center">
                            <TableActions
                                roleName="product"
                                onSelect={() => props.onOpenEditModal(product)}
                                onDelete={() => props.onDelete(product.id)}
                            />
                        </td>
                    </tr>
                ))}
                {!products.length &&
                    <tr>
                        <td colSpan="5" align="center">{t('global.notMatchingRecordsFound')}</td>
                    </tr>
                }
            </tbody>
        </Table>
			  </div>
    )
}

export default withNamespaces()(ProductList)
