import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import AvEditor from 'react-avatar-editor';

import Dropzone from '../Dropzone/Dropzone';
import { debounce, data2blob } from '../../../utils/utility';
import "./AvatarEditor.scss";

class AvatarEditor extends Component {

    state = {
        isVisible: false,
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1.5,
        rotate: 0,
        borderRadius: 0,
        preview: null,
        width: 200,
        height: 200,
        image: null
    }

    handleNewImage = e => {
        this.setState({ image: e.target.files[0] })
    }

    handleSave = debounce((data) => {
        const img = this.editor.getImageScaledToCanvas().toDataURL()
        const rect = this.editor.getCroppingRect()
        this.setState({
            preview: {
                img,
                rect,
                scale: this.state.scale,
                width: this.state.width,
                height: this.state.height,
                borderRadius: this.state.borderRadius,
            },
        })
    })

    handleScale = e => {
        const scale = parseFloat(e.target.value)
        this.setState({ scale });
        this.handleSave();
    }

    handleClickScale = (value) => {
        if ((value < 0 && this.state.scale <= 1)
            || (value > 0 && this.state.scale >= 2)) {
            return;
        }
        this.setState({ scale: this.state.scale + value });
        this.handleSave();
    }

    logCallback(e) {
        // eslint-disable-next-line
        // console.log('callback', e)
    }

    setEditorRef = editor => {
        if (editor) this.editor = editor
    }

    handlePositionChange = position => {
        this.setState({ position });
        this.handleSave();
    }

    handleDrop = acceptedFiles => {
        this.setState({ image: acceptedFiles[0] })
    }

    visibleHandler = () => {
        this.setState({ isVisible: !this.state.isVisible });
    }

    cpOnClickHandler = () => {
        if (!this.state.isVisible) {
            this.visibleHandler();
        }
    }

    onSelectImageHandler = (file) => {
        const image = Array.isArray(file) ? file[0] : file;
        this.setState({ image });
        this.handleSave();
    }

    resetImage = () => {
        this.setState({
            position: { x: 0.5, y: 0.5 },
            scale: 1.5,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 200,
            height: 200,
            image: null
        });
    }

    submitHandler = () => {
        const image = this.editor.getImageScaledToCanvas().toDataURL();
        this.props.onUpload(data2blob(image));
        this.resetImage();
        this.setState({ isVisible: false });
    }

    render() {
        const { backTitle, cancelTitle, saveTitle, previewTitle, dropTitle, clickUploadTitle } = this.props;
        return (
            <React.Fragment>
                <Modal backdrop="static" size="avatar" isOpen={this.state.isVisible} toggle={this.visibleHandler}>
                    <ModalBody className="avatar-editor vue-image-crop-upload">
                        <div className="editor-container vicp-wrap">
                            <div className="vicp-close"><i className="vicp-icon4" onClick={this.visibleHandler}></i></div>
                            {this.state.image ?
                                <React.Fragment>
                                    <div className="vicp-step2">
                                        <div className="vicp-crop">
                                            <div className="vicp-crop-left">
                                                <div className="vicp-img-container" style={{
                                                    height: `${this.state.height + 50}px`
                                                }}>
                                                    <AvEditor
                                                        image={this.state.image}
                                                        ref={this.setEditorRef}
                                                        color={[255, 255, 255, 0.4]}
                                                        scale={parseFloat(this.state.scale)}
                                                        width={this.state.width}
                                                        height={this.state.height}
                                                        position={this.state.position}
                                                        onPositionChange={this.handlePositionChange}
                                                        rotate={parseFloat(this.state.rotate)}
                                                        borderRadius={this.state.width / (100 / this.state.borderRadius)}
                                                        onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                                                        onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                                                        onImageReady={this.logCallback.bind(this, 'onImageReady')}
                                                        className="editor-canvas"
                                                    />
                                                </div>
                                                <div className="vicp-range">
                                                    <i className="vicp-icon5" onClick={() => this.handleClickScale(-0.1)}></i>
                                                    <input
                                                        name="scale"
                                                        type="range"
                                                        onChange={this.handleScale}
                                                        min={this.state.allowZoomOut ? '0.1' : '1'}
                                                        max="2"
                                                        step="0.01"
                                                        value={this.state.scale}
                                                    />
                                                    <i className="vicp-icon6" onClick={() => this.handleClickScale(0.1)}></i>
                                                </div>
                                            </div>
                                            <div className="vicp-crop-right">
                                                <div className="vicp-preview">
                                                    <div className="vicp-preview-item">
                                                        {!!this.state.preview && (
                                                            <img
                                                                alt=""
                                                                src={this.state.preview.img}
                                                                className="img-preview"
                                                            />
                                                        )}
                                                        <span>{previewTitle ? previewTitle : "Preview"}</span>
                                                    </div>
                                                    <div className="vicp-preview-item vicp-preview-item-circle">
                                                        {!!this.state.preview && (
                                                            <img
                                                                alt=""
                                                                src={this.state.preview.img}
                                                                className="img-preview"
                                                            />
                                                        )}
                                                        <span>{previewTitle ? previewTitle : "Preview"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="vicp-operate">
                                                <a onClick={this.resetImage}>{backTitle ? backTitle : 'Back'}</a>
                                                <a onClick={this.submitHandler} className="vicp-operate-btn">{saveTitle ? saveTitle : 'Save'}</a>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Dropzone className="drop-block" fileType="image/*" onFilesAdded={(file) => this.onSelectImageHandler(file)}>
                                        <i className="el-icon-upload"></i>
                                        <div className="el-upload__text">{dropTitle ? dropTitle : "Drop file here or"}<em> {clickUploadTitle ? clickUploadTitle : "click to upload"}</em></div>
                                    </Dropzone>
                                    <div className="vicp-operate"><a onClick={this.visibleHandler}>{cancelTitle ? cancelTitle : "Cancel"}</a></div>
                                </React.Fragment>
                            }
                        </div>
                    </ModalBody>
                </Modal>
                <div onClick={this.cpOnClickHandler}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

export default AvatarEditor;