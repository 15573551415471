import React from 'react';
import { Table } from 'reactstrap';
import { TableActions } from '../Common';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from "../../components/Layout/ContentWrapper";

import permission from 'utils/permission';

export const WorkingGroupList = (props) => {
    const { workingGroups, t } = props;
    return (
        <div style={{ 'marginTop': '45px' }}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th>Name EN</th>
                        <th>Name JP</th>
                    </tr>
                </thead>
                <tbody>
                    {workingGroups.map((workingGroup, idx) => (
                        <tr key={idx}>
                            <td align="center">{workingGroup.id}</td>
                            <td>{workingGroup.name_en}</td>
                            <td>{workingGroup.name_jp}</td>
                        </tr>
                    ))}
                    {!workingGroups.length &&
                        <tr>
                            <td colSpan="5" align="center">{t('global.notMatchingRecordsFound')}</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default withNamespaces()(WorkingGroupList);
