import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from 'element-react';
import "./styles.scss";

class MessageBox extends React.Component {
    state = {
        open: false,
        focusAfterClose: true
    }

    componentDidMount() {
        this.setState({ open: true });
    }

    handleAction = (action) => {
        this.setState(({ open }) => ({ open: !open }));
        const { modal, promise, showInput, willUnmount } = this.props;

        if (modal) {
            switch (action) {
                case 'cancel':
                    promise.reject();
                    break;
                case 'confirm':
                    if (modal === 'prompt') {
                        if (this.validate(this.state.inputValue || '')) {
                            if (showInput) {
                                promise.resolve({ value: this.state.inputValue, action });
                            } else {
                                promise.resolve(action);
                            }
                        } else {
                            return;
                        }
                    } else {
                        promise.resolve();
                    }
                    break;
                default:
                    break;
            }
        } else {
            promise.resolve(action);
        }
        setTimeout(() => {
            willUnmount && willUnmount();
        })
    }

    confirmButtonText() {
        return this.props.confirmButtonText || "Confirm";
    }

    cancelButtonText() {
        return this.props.cancelButtonText || "Cancel";
    }

    render() {
        const { title, message, showCancelButton, showConfirmButton } = this.props;

        return (
            <Modal centered className="message-box" isOpen={this.state.open}>
                {title &&
                    <ModalHeader toggle={() => this.handleAction('cancel')}>
                        {title}
                    </ModalHeader>
                }
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    {showCancelButton && <Button onClick={() => this.handleAction('cancel')}>{this.cancelButtonText()}</Button>}
                    {showConfirmButton && <Button type="primary" onClick={() => this.handleAction('confirm')}>{this.confirmButtonText()}</Button>}
                </ModalFooter>
            </Modal>
        )
    }
}

MessageBox.defaultProps = {
    showInput: false,
    showClose: true,
    showCancelButton: false,
    showConfirmButton: true
}

export default MessageBox;