import { apiGetProductSubCategory } from '../../api/product-sub-category';

import { FETCH_SUB_CATEGORY_REQUEST, FETCH_SUB_CATEGORY_SUCCESS, FETCH_SUB_CATEGORY_FAILURE, FILTER_SUB_CATEGORY } from './product-sub-category.action.types';

const fetchProductSubCategoryRequest = () => ({
    type: FETCH_SUB_CATEGORY_REQUEST
});

const fetchProductSubCategorySuccess = (result) => ({
    type: FETCH_SUB_CATEGORY_SUCCESS,
    payload: result.data,
    totalPage: result.last_page,
    total: result.total
});

const fetchProductSubCategoryFailure = (error) => ({
    type: FETCH_SUB_CATEGORY_FAILURE,
    payload: error
});

export const fetchProductSubCategory = (filterData) => {
    return async dispatch => {
        dispatch(fetchProductSubCategoryRequest());
        await apiGetProductSubCategory(filterData).then((res) => {
            dispatch(fetchProductSubCategorySuccess(res));
        })
        .catch((error) => {
            dispatch(fetchProductSubCategoryFailure(error.message));
        })
    };
};

export const setFilterProductSubCategory = (filterData) => ({
    type: FILTER_SUB_CATEGORY,
    filterData
});
