import React from 'react';
import {Button, Form, Input, Layout} from 'element-react';
import {withNamespaces} from 'react-i18next';

function FeeDetails({t, item, id, defaultTitle, removeFee, updateFee}) {
	if (!item.title && defaultTitle) {
		updateFee('title', defaultTitle, id);
	}
	return (
		<React.Fragment>
			<Layout.Row>
				<div className="remove-icon">
					<Button type="text" onClick={removeFee}>
						<em className="mr-2 fas fa-minus-circle text-danger"/>
					</Button>
					<span>{t('factoryEvaluation.feeAlias')} {id + 1}</span>
				</div>
				<hr className="mt-0" />
				<Layout.Col span="20">
					<Form.Item label={t('factoryEvaluation.title')}>
						<Input value={item.title ? item.title : defaultTitle} onChange={value => updateFee('title', value, id)}/>
					</Form.Item>
					<Form.Item label={t('factoryEvaluation.fee')}>
						<Input value={item.fee} onChange={value => updateFee('fee', value, id)}/>
					</Form.Item>
				</Layout.Col>
			</Layout.Row>
		</React.Fragment>
	)
}

export default withNamespaces()(FeeDetails);
