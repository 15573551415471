import PropTypes from 'prop-types';
import React from 'react';
import ContactFields from './ContactFields';
import {withNamespaces} from 'react-i18next';
import {Layout, Button} from 'element-react';

const Contacts = (props) => {
	const {t, onAdd, onRemove, onChange, contacts} = props;
	return (
		<Layout.Row>
	        <Layout.Col>
                <Button type="text" className="pull-right button-add-icon" onClick={onAdd}>
                    <em className="mr-2 fas fa-plus-circle"/>
                    {t('global.addContact')}
                </Button>
		        {contacts.map((contact, idx) => (!contact.isDeleted &&
			        <React.Fragment key={`${contact.id}-${idx}`}>
				        <div className="remove-icon">
					        <Button type="text" onClick={() => onRemove(idx)}>
						        <em className="mr-2 fas fa-minus-circle text-danger"/>
					        </Button>
					        <span>
						        {t('global.contact')} {idx + 1}
					        </span>
				        </div>
				        <hr className="mt-0" />
				        <ContactFields
					        contact={contact}
					        contactIndex={idx}
					        onChange={onChange}
									isTypeRequired={props.isTypeRequired}
				        />
			        </React.Fragment>
		        ))}
	        </Layout.Col>
		</Layout.Row>
	);
}

Contacts.propTypes = {
	contacts: PropTypes.array.isRequired,
	onAdd: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default withNamespaces()(Contacts);
