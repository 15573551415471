import { updateObject, paginationData } from '../../utils/utility';
import { FETCH_SUB_CATEGORY_REQUEST, FETCH_SUB_CATEGORY_SUCCESS, FETCH_SUB_CATEGORY_FAILURE, FILTER_SUB_CATEGORY } from './product-sub-category.action.types';

const initialState = {
    filterData: {
        ...paginationData
    },
    loading: true,
    productSubCategories: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUB_CATEGORY_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_SUB_CATEGORY_SUCCESS:
        return getSubCategory(state, action);
    case FETCH_SUB_CATEGORY_FAILURE:
        return {
            ...state,
            loading: true,
            productSubCategories: [],
            error: action.payload,
        }
    case FILTER_SUB_CATEGORY: return filterSubCategory(state, action);
    default: return state;
  }
};

const getSubCategory = (state, action) => updateObject(state, {
    productSubCategories: action.payload,
    loading: false,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterSubCategory = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
