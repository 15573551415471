import React from 'react';
import File from './File/File';

const FileList = (props) => {
    const { files, keyId, name, t, hrefName } = props;

    if (!files) {
        return null;
    }

    return (
        <ul className={'el-upload-list el-upload-list--text'}>
            {files.map((file, idx) => (
                <li className={'el-upload-list__item is-ready'} key={keyId ? file[keyId] : `${file[name]}-${idx}`}>
                    <File name={file[name]} link={file[hrefName]} t={t} isWarning={!file['is_valid_name']}/>
                    <label className="el-upload-list__item-status-label">
                        <i className={'el-icon-upload-success el-icon-circle-check'} />
                    </label>
                    <i className="el-icon-close" onClick={() => props.onRemoveFile(file[keyId])} />
                </li>
            ))}
        </ul>
    )
}

export default FileList;
