import axios from '../config/axios';
import { convertFormData } from '../utils/utility';

export function apiGetAllProductSubCategory(type) {
  return axios.get('product-sub-categories', { params: { type: type } });
};

export function apiGetProductSubCategory(params) {
  return axios.get('product-sub-categories/sub-categories', { params });
};

export function apiGetWorkingGroup(params) {
  return axios.get('product-sub-categories/working-groups', { params });
};

export function apiFindProductSubCategory(id) {
  return axios.get(`product-sub-categories/${id}`);
};

export function apiSaveProductSubCategory(data) {
  if (data.parent_id) {
    return axios.post('product-sub-categories', convertFormData(data));
  } else {
    return axios.put(`product-sub-categories/${data.id}`, data);
  }
};

export function apiDeleteProductSubCategory(id) {
  return axios.delete(`product-sub-categories/${id}`);
};

export function apiGetFilters(name, params) {
    return axios.get(`product-sub-categories/filters/${name}`, {params});
};
