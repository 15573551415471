import React, {Component} from 'react';
import Popup from 'reactjs-popup';

class Image extends Component {

    constructor(props) {
        super(props);
        this.state = {height: 0};
        this.imageRef = React.createRef();
        this.imageAction = this.imageAction.bind(this);
    }

    imageAction() {
        this.setState({
            height: this.imageRef.current.height
        })
    }

    render() {
        const { baseUrl, link, name, t, isWarning } = this.props;
        const triggerElement = <span className="file-item"><i className="el-icon-picture" /> {name} {isWarning && <i className="el-icon-warning file-warning invalid"/>}</span>;
        return (
          <Popup
            contentStyle={{'height': `${this.state.height + 12}px`}}
            trigger={triggerElement}
            modal
            closeOnDocumentClick>
              {close => (
                <div onLoad={this.imageAction}>
                    <img ref={this.imageRef} src={`${baseUrl}/${link}`} alt={name}/>
                    <a href={`${baseUrl}/download?file=${link}`}
                       className="el-button el-button--default float-right mt-1"
                       onClick={close}
                       download>
                        {t('global.download')}
                    </a>
                </div>
              )}
          </Popup>
        );
    }
}

export default Image;
