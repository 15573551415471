import axios from '../config/axios';
import { convertFormData } from '../utils/utility';

export function apiGetUser(params) {
  return axios.get('users', { params });
};

export function apiDeleteUser(id) {
  return axios.delete(`users/${id}`);
};

export function apiUnlockUser(id) {
	return axios.put('users/unlock', { id: id });
};

export function apiSaveUser(data) {
  if (data.id) {
    const formData = convertFormData(data);
    formData.append('_method', 'PUT');
    return axios.post(`users/${data.id}`, formData);
  } else {
    return axios.post('users', convertFormData(data));
  }
};

export function apiGetFilters(name, params) {
    return axios.get(`users/filters/${name}`, {params});
};

export function apiAddTag(params) {
    return axios.put(`users/add-tag`, { params });
}

export function apiRemoveTag(params) {
    return axios.delete(`users/remove-tag`, { params });
}
