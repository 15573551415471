import { apiGetActivity } from '../../api/activity';

export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FILTER_ACTIVITY = 'FILTER_ACTIVITY';

const fetchActivitySuccess = (result) => ({
    type: FETCH_ACTIVITY,
    activities: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchActivity = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetActivity(filterData);
            dispatch(fetchActivitySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterActivity = (filterData) => ({
    type: FILTER_ACTIVITY,
    filterData
});
