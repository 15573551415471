import React, {Component} from "react";
import Message from "../../utils/Message";
import ContentWrapper from "../../components/Layout/ContentWrapper";
import { Button,Loading } from 'element-react';
import { ProductList, ProductForm, ProductEditForm } from "../../components/Product";
import { Pagination } from '../../components/Common';
import permission from "../../utils/permission";
import * as actions from './product.actions';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import { withFilter } from '../../hoc';
import { apiGetProducts, apiDeleteProduct } from "../../api/product"

class Product extends Component {
	_isMounted = false;
	state = {
			isLoading: true,
			selectedItem: null,
			filters: {},
			isAddModalOpen: false
	}

	async componentDidMount() {
			this._isMounted = true;
      this.filter();
	}

	onOpenAddModal = () => {
			this.setState({ isAddModalOpen: true });
	}

	onOpenEditModal = (product) => {
			this.setState({ isEditModalOpen: true, selectedItem: product });
	}

	onCloseEditModal = () => {
			this.setState ({ isEditModalOpen: false });
	}

	onCloseAddModal = () => {
			this.setState ({ isAddModalOpen: false });
	}

  onDeleteProduct = async (id) => {
      await this.props.onDelete(id, apiDeleteProduct);
			await this.filter();
  }

  filter = async (params) => {
      const filterColumns = [
          'product_sub_category'
      ];

      const newFilter = { ...this.props.filterData, ...params };
      let filter = {
          limit: newFilter.limit,
          page: newFilter.page,
          ...this.props.likeFilter('name', newFilter.name)
      };
      filterColumns.forEach(column => filter = {
          ...filter,
          ...this.props.whereInFilter(column, newFilter[column])
      });
      await this.props.fetchProduct(filter);
      if (this._isMounted) {
          this.setState({
              filters: filter,
              firstLoading: false,
							isLoading: false
          });
      }
  }

  onChangeWithFilter = (value, key) => {
      this.props.onChangeWithFilterAndResetPage(value, key);
      this.setState(prevState => ({
          isRenderList: !prevState.isRenderList
      }));
  };

	onChangePage = async (page) => {
			await this.setState({ isLoading: true });
			console.log(this.props.isLoading)
			await this.props.onPageChange(page);
			await this.setState({ isLoading: false });
			console.log(this.props.isLoading)
	}


	render() {
    const { t, filterData } = this.props;
		return (
			<div>
          <Loading loading={ !this.props.products.length || this.props.isLoading }>
							<ProductForm
									isOpen={this.state.isAddModalOpen}
									lang={this.props.language}
									onSubmit={this.props.onSubmit}
									onCancel={this.onCloseAddModal}
									refresh={this.filter}
							/>
							{this.state.selectedItem && <ProductEditForm
									isOpen={this.state.isEditModalOpen}
									lang={this.props.language}
									onSubmit={this.props.onSubmit}
									product={this.state.selectedItem}
									onCancel={this.onCloseEditModal}
									refresh={this.filter}
							/>}
							<ProductList
	                products={this.props.products}
                  filterData={filterData}
                  onChangeWithFilter={this.onChangeWithFilter}
									onOpenAddModal={this.onOpenAddModal}
									onOpenEditModal={this.onOpenEditModal}
									onDelete={this.onDeleteProduct}
									lang={this.props.language}
							/>
              <Pagination
									pageCount={filterData.totalPage}
									total={filterData.total}
									pageSize={filterData.limit}
									initialPage={filterData.page}
									onPageChange={this.props.onPageChange}
							/>
					</Loading>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return {
				isLoading: state.product.loading,
        products: state.product.products,
        filterData: state.product.filterData,
				language: state.settings.language
    };
};

const mapDispatchToProps = dispatch => ({
    fetchProduct: (params) => dispatch(actions.fetchProduct(params)),
    setReduxFilter: (params) => dispatch(actions.setFilterProduct(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(Product)));
