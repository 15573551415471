import { REHYDRATE } from 'redux-persist';

import { 
    FETCH_COUNTRY,
    FETCH_ALL_COUNTRY,
    FILTER_COUNTRY
} from './country.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    countries: [],
    allCountries: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: return rehydrate(state, action);
        case FETCH_COUNTRY: return getCountry(state, action);
        case FETCH_ALL_COUNTRY: return getAllCountry(state, action);
        case FILTER_COUNTRY: return filterCountry(state, action);
        default: return state;
    }
};

const rehydrate = (state, action) => {
    return updateObject(state, action.payload ?
        { ...state, ...action.payload } : state);
};

const getCountry = (state, action) => updateObject(state, {
    countries: action.countries,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const getAllCountry = (state, action) => updateObject(state, {
    allCountries: action.allCountries
});

const filterCountry = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
