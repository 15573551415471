import React from 'react';
import Select from './Select';
import PropTypes from 'prop-types';
import {withNamespaces} from 'react-i18next';

class FormCollection extends React.Component {
	render() {
		const {t, field, items, options, onChange, onAdd, onRemove, updateData, required} = this.props;
		const isRequired = (required && items.length > 1) || !required;
		return (
			<div>
				{items.map((item, index) => (
					<div className="mb-2" key={index}>
						<Select
							isClearable={false}
							value={item.id}
							onChange={(id, item) => onChange(id, item, index, field, updateData)}
							options={options}
							subLabel="code"
						/>
						{isRequired &&
							<button
								className="el-button el-button--default el-button--mini mt-1"
								onClick={(event) => onRemove(event, index, field, updateData)}>
								{t('global.delete')}
							</button>
						}
					</div>
				))}
				<button
					className="el-button el-button--default"
					onClick={(event) => onAdd(event, field)}>
					{t('global.add')}
				</button>
			</div>
		);
	}
}

FormCollection.defaultProps = {
	items: [],
	options: [],
	required: true,
	updateData: () => {},
};

FormCollection.propTypes = {
	field: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	options: PropTypes.array.isRequired,
	onAdd: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	required: PropTypes.bool.isRequired,
	updateData: PropTypes.func.isRequired,
};

export default withNamespaces()(FormCollection);
