import React from 'react';
import Image from './Image';
import Document from './Document';
import { isImage, isExplorer, isPdf } from '../../../utils/utility';

const File = (props) => {

    const { link, name, t, isWarning } = props;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    return (
        <div>
            {isImage(link) ?
                (<Image baseUrl={baseUrl} link={link} name={name} t={t} isWarning={isWarning} />) :
                (<Document
	                baseUrl={baseUrl}
	                link={link}
	                name={name}
	                t={t}
	                isWarning={isWarning}
	                direct={isExplorer() || !isPdf(link)}
                />)
            }
        </div>
    );
}

export default File;
