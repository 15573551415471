import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'element-react';
import { withNamespaces } from 'react-i18next';

function ConfirmationModal(props) {
		const { t, type } = props;
		return (
			<React.Fragment>
				<Modal backdrop="static" size="sm" isOpen={props.isOpen}>
						<ModalHeader />
						<ModalBody className="user-container scrollable-modal" style={{textAlign: 'center'}}>
							<h4>{props.message}</h4>
						</ModalBody>
						<ModalFooter style={{justifyContent: 'center'}}>
								<Button onClick={props.handleCancel}>
									{t('global.cancel')}
								</Button>
								<Button type="primary" nativeType="submit" onClick={props.handleConfirm}>
									{type === 'confirm' ? t('factoryEvaluation.judgementConfirm') : t('factoryEvaluation.judgementChange')}
								</Button>
						</ModalFooter>
				</Modal>
			</React.Fragment>
		)
	}

export default withNamespaces()(ConfirmationModal); 