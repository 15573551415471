import { getToken } from '../utils/auth';
import Echo from 'laravel-echo';
import io from 'socket.io-client';

class Websocket {
	static run(callback) {
		if (this.isEnable()) {
			window.io = io;
			callback(new Echo({
				broadcaster: 'socket.io',
				host: window.location.hostname + ':6001',
				auth: {
					headers: {
						Authorization: `Bearer ${getToken()}`
					}
				}
			}));
		}
	}
	static isEnable() {
		return 'true' === process.env.REACT_APP_WEBSOCKET;
	}
}

export default Websocket;
