import { FETCH_ROOT_PRODUCT_CATEGORY, FILTER_ROOT_PRODUCT_CATEGORY } from './root-product-category.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    rootProductCategories: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROOT_PRODUCT_CATEGORY: return getRootProductCategory(state, action);
    case FILTER_ROOT_PRODUCT_CATEGORY: return filterRootProductCategory(state, action);
    default: return state;
  }
};

const getRootProductCategory = (state, action) => updateObject(state, { 
    rootProductCategories: action.rootProductCategories,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterRootProductCategory = (state, action) => updateObject(state, { 
    filterData: action.filterData
});

export default reducer;
