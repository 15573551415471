import { REHYDRATE } from 'redux-persist';

import { 
    FETCH_ROLE,
    FETCH_ALL_ROLE,
    FILTER_ROLE
} from './role.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    roles: [],
    allRoles: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: return rehydrate(state, action);
        case FETCH_ROLE: return getRole(state, action);
        case FETCH_ALL_ROLE: return getAllRole(state, action);
        case FILTER_ROLE: return filterRole(state, action);
        default: return state;
    }
};

const rehydrate = (state, action) => {
    return updateObject(state, action.payload ?
        { ...state, ...action.payload } : state);
};

const getRole = (state, action) => updateObject(state, {
    roles: action.roles,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const getAllRole = (state, action) => updateObject(state, {
    allRoles: action.allRoles
});

const filterRole = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
