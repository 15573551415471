import React from 'react';
import { Button } from 'element-react';
import { withNamespaces } from 'react-i18next';

import permission from '../../utils/permission';

const TableActions = ({ roleName, ...props }) => {
    return (
        <div className="nowrap">
            {permission(`${roleName}:update`) &&
                <Button type="primary" icon="edit" size="mini" onClick={props.onSelect}>
                    {props.t('global.edit')}
                </Button>
            }
            {!props.hideDelete && permission(`${roleName}:delete`) &&
                <Button type="danger" icon="delete" size="mini" onClick={props.onDelete}>
                    {props.t('global.delete')}
                </Button>
            }
        </div>
    )
}

export default withNamespaces()(TableActions);