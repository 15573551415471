import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { withNamespaces } from 'react-i18next';
import { i18n } from 'element-react';
import localeEN from 'element-react/src/locale/lang/en';
import localeJA from 'element-react/src/locale/lang/ja';
import Routes from './Routes';
import { store, persistor } from './store/store';

import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';
import './i18n';
import "./Vendor";
import './styles/bootstrap.scss';
import './styles/app.scss';
import 'element-theme-default';

class App extends Component {

    componentDidMount() {
        const lng = localStorage.getItem('7andi-csr-lng');
        if (lng) {
            this.props.i18n.changeLanguage(lng);
        }
        if (lng === 'jp') {
            i18n.use(localeJA);
        } else {
            i18n.use(localeEN);
        }
    }

    render() {
        // specify base href from env varible 'PUBLIC_URL'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global PUBLIC_URL */
        const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter basename={basename}>
                        <Routes />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        );
    }
}

export default withNamespaces('translations')(App);
