import React, { Component } from 'react';
import { Checkbox } from 'element-react';

export default class DateFilterGroup extends Component {
    state = {
    }

/******************* Get DATA *********************/

    getYears = (data) => {
        let years = data.map(el => {
            if (el[this.props.label] && el[this.props.label] !== null) {
                return {name: el[this.props.label].split('-')[0], checked: false};
            } else {
                return {name: null};
            }
        })
        years = years.filter((year, index, self) =>
          index === self.findIndex((y) => (
            y.name === year.name && y.name !== null
          ))
        )
	    years.sort(function(a,b) {
		    a = a.name;
		    b = b.name;
		    return b.localeCompare(a);
	    });
        return years
    }

    getMonths = (data, year) => {
        let months = data.map(el => {
            if (el[this.props.label] && el[this.props.label] !== null && el[this.props.label].split('-')[0] === year) {
                return {name: el[this.props.label].split('-')[1], checked: false};
            } else {
                return {name: null};
            }
        })

        months = months.filter((month, index, self) =>
          index === self.findIndex((m) => (
            m.name === month.name && m.name !== null
          ))
        )
        months.sort(function(a,b) {
          a = a.name;
          b = b.name;
          return b.localeCompare(a);
        });
        return months
    }

    getDays = (data, year, month) => {
        let days = data.map(el => {
            if (el[this.props.label] && el[this.props.label].split('-')[0] === year && el[this.props.label].split('-')[1] === month) {
                return el
            } else {
                return null;
            }
        })
        days = new Set(days)
        days.has(null) && days.delete(null)
        days = [...days]
        days.sort(function(a,b) {
          a = a.name.split('-').reverse().join('');
          b = b.name.split('-').reverse().join('');
          return b.localeCompare(a);
        });
        return days
    }

/*******************************************************/

    generateTree = (data) => {
        let years = this.getYears(data);
        let empty = data.map((el, idx) => (
            el.name === null &&
            <li key={`null-${idx}`}>
                <Checkbox
                    checked={el.checked}
                    label=''
                    onChange={() => this.props.handleFilterNull(el)} />
            </li>
        ))
        years = years && years.map((year, idx) => (
            <div key={`y-${idx}`}>
                <li>
                    <Checkbox
                        checked={this.isCheckedYear(year.name)}
                        indeterminate={!this.isCheckedYear(year.name) && this.isCheckedInYear(year.name)}
                        label=''
                        onChange={(val) => this.props.filterByYear(val, year)} />
                    <span onClick={(el) => this.toggle(year, year.name, idx)}>{year.name}年</span>
                </li>
                <ul className='collapse' id={`${year.name}-${idx}`}>{this.generateMonths(data, year)}</ul>
            </div>
        ))
        let tree = [empty, years]
        return tree
    }

    generateMonths = (data, year) => {
        let months = this.getMonths(data, year.name);
        months = months.map((month, idx) => (
            <div key={`m-${idx}`}>
                <li>
                    <Checkbox
                        checked={this.isCheckedMonth(year.name, month.name)}
                        indeterminate={!this.isCheckedMonth(year.name, month.name) && this.isCheckedInMonth(year.name, month.name)}
                        label=''
                        onChange={(value) => this.props.filterByMonth(value, year, month)} />
                    <span onClick={() => this.toggle(month, `${year.name}-${month.name}`, idx)}>{month.name}月</span>
                </li>
                <ul className='collapse' id={`${year.name}-${month.name}-${idx}`}>{this.generateDays(data, year.name, month.name)}</ul>
            </div>
        ))
        return months
    }

    generateDays = (data, year, month) => {
        let days = this.getDays(data, year, month);
        days = days.map((day, idx) => (
            <li key={`d-${idx}`}>
                <Checkbox
                    checked={day.checked}
                    label={`${day.name.split('-')[2]}日`}
                    onChange={() => this.props.handleFilterChange(day)} />
            </li>
        ))
        return days
    }

    toggle = (val, dist, idx) => {
        let el = document.getElementById(`${dist}-${idx}`)
        el.classList.toggle('show')
    }

    isCheckedYear = (year) => {
        for (let i=0; i < this.props.filtersData.length; i++) {
            if (this.props.filtersData[i][this.props.label] && this.props.filtersData[i][this.props.label] !== null && this.props.filtersData[i][this.props.label].split('-')[0] === year) {
                if (this.props.filtersData[i].checked) {
                    continue
                } else {
                    return false
                }
            }
        }
        return true
    }

    isCheckedInYear = (year) => {
        for (let i=0; i < this.props.filtersData.length; i++) {
            if (this.props.filtersData[i][this.props.label] && this.props.filtersData[i][this.props.label] !== null && this.props.filtersData[i][this.props.label].split('-')[0] === year) {
                if (this.props.filtersData[i].checked) {
                    return true
                } else {
                    continue
                }
            }
        }
        return false
    }

    isCheckedMonth = (year, month) => {
        for (let i=0; i < this.props.filtersData.length; i++) {
            if (this.props.filtersData[i][this.props.label] && this.props.filtersData[i][this.props.label] !== null && this.props.filtersData[i][this.props.label].split('-')[0] === year && this.props.filtersData[i][this.props.label].split('-')[1] === month) {
                if (this.props.filtersData[i].checked) {
                    continue
                } else {
                    return false
                }
            }
        }
        return true
    }

    isCheckedInMonth = (year, month) => {
        for (let i=0; i < this.props.filtersData.length; i++) {
            if (this.props.filtersData[i][this.props.label] && this.props.filtersData[i][this.props.label] !== null && this.props.filtersData[i][this.props.label].split('-')[0] === year && this.props.filtersData[i][this.props.label].split('-')[1] === month) {
                if (this.props.filtersData[i].checked) {
                    return true
                } else {
                    continue
                }
            }
        }
        return false
    }

    render() {
        const { filtersData } = this.props;
        return (
          <ul className='tree-view'>
          {this.generateTree(filtersData)}
          </ul>
        )
    }
}
