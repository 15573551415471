import { FETCH_VENDOR_FACTORY, FETCH_ONE_VENDOR_FACTORY, FILTER_VENDOR_FACTORY } from './vendor-factory.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    vendorFactories: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_FACTORY: return getVendorFactory(state, action);
    case FETCH_ONE_VENDOR_FACTORY: return getVendorFactory(state, action);
    case FILTER_VENDOR_FACTORY: return filterVendorFactory(state, action);
    default: return state;
  }
};

const getVendorFactory = (state, action) => updateObject(state, {
    vendorFactories: action.vendorFactories,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterVendorFactory = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
