import React, { Component } from 'react';
import './Dropzone.css';

class Dropzone extends Component {
    constructor(props) {
        super(props);
        this.state = { hightlight: false, fileList: [], isWarning: false };
        this.fileInputRef = React.createRef();

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded = (evt) => {
        if (this.props.disabled) return;
        const files = evt.target.files;

		if (this.props.validateName) {
			let warnings = [];
			const items = Array.from(files);
			items.forEach((item) => {
				this.props.validateName(item.name).then((state) => {
					if (!state) {
						warnings.push(item.name);
						this.setState({warnings});
					}
				});
			});
		}

        if (this.props.onFilesAdded) {
            this.addFiles(files);
        }
    }

    addFiles(files) {
        if (this.props.multiple) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
            this.setState({ fileList: array });
        } else {
            this.props.onFilesAdded(files[0]);
            this.setState({ fileList: [files[0]] });
        }
    }

    onDragOver(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({ hightlight: true });
    }

    onDragLeave() {
        this.setState({ hightlight: false });
    }

    onDrop(event) {
        event.preventDefault();
        if (this.props.disabled) return;

        const files = event.dataTransfer.files;
        try {
            this.addFiles(files);
            this.setState({ hightlight: false });
        } catch(err) {}
    }

    onRemoveFile = (idx) => {
        const fileList = [...this.state.fileList];
        fileList.splice(idx, 1);
        if (this.props.onFilesAdded) {
            if (this.props.multiple) {
                this.props.onFilesAdded(fileList);
                this.setState({ fileList });
            } else {
                this.props.onFilesAdded(null);
                this.setState({ fileList: [] });
            }
        }
    }

    fileListToArray(list) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }

    reset = () => {
        if (this.props.multiple) {
            this.setState({ fileList: [] });
        } else {
            this.props.onFilesAdded(null);
            this.setState({ fileList: [] });
        }
    }

	createWarning = (name) => {
		if (this.props.files && this.props.files.includes(name)) {
			return <i className="el-icon-warning file-warning double"/>;
		}
		if (this.state.warnings && this.state.warnings.includes(name)) {
			return <i className="el-icon-warning file-warning invalid"/>;
		}
		return null;
	}

    render() {
        const { fileList } = this.state;
        return (
            <div className="upload-demo">
                {!this.props.disabled && <div className="el-upload el-upload--text">
                    <div
                        className={`el-upload-dragger ${this.state.hightlight && 'is-dragover'} ${this.props.className}`}
                        onDragOver={this.onDragOver}
                        onDragLeave={this.onDragLeave}
                        onDrop={this.onDrop}
                        onClick={this.openFileDialog}
                        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
                    >
                        <input
                            ref={this.fileInputRef}
                            className="FileInput"
                            type="file"
                            accept={this.props.fileType}
                            multiple={this.props.multiple}
                            onChange={this.onFilesAdded}
                        />
                        {this.props.children}
                    </div>
                </div>}
                {this.props.showList !== false &&
                    <ul className={'el-upload-list el-upload-list--text'}>
                        {fileList.map((file, idx) => (
                            file && <li
                                className={'el-upload-list__item is-ready'}
                                key={`${file.name}-${idx}`}
                            >
                                <a
                                    className="el-upload-list__item-name"
                                >
                                    <i className="el-icon-document" />{file.name} {this.createWarning(file.name)}
	                                {/*{warnings && warnings.includes(file.name) && <i className="el-icon-warning file-warning"/>}*/}
	                                {/*{this.props.files && this.props.files.includes(file.name) && <i className="el-icon-warning file-warning"/>}*/}
                                </a>
                                <label
                                    className="el-upload-list__item-status-label"
                                >
                                    <i
                                        className={'el-icon-upload-success el-icon-circle-check'}
                                    />

                                </label>
                                <i className="el-icon-close" onClick={() => this.onRemoveFile(idx)} />
                            </li>
                        ))}
                    </ul>}
            </div>
        );
    }
}

export default Dropzone;
