import axios from '../config/axios';

export function apiCode(data) {
	return axios.post('auth/code', data);
}

export function signIn(data) {
    return axios.post('auth/login', data);
}

export function apiForgotPassword(data) {
    return axios.post('/password/create', data);
}

export function apiFindUserPassword(token) {
    return axios.get(`/password/find/${token}`);
}

export function apiResetPassword(data) {
    return axios.post('/password/reset', data);
}

export function apiUserAuthenticated() {
    return axios.get('/auth/user');
}

export function apiUpdatePassword(id, data) {
    return axios.put(`/users/${id}/update-password`, data);
}

export function apiUpdateProfile(id, data) {
    return axios.put(`/users/${id}/update-profile`, data);
}

export function apiUploadAvatar(id, data) {
    return axios.post(`/users/${id}/upload-avatar`, data);
}

export function logout() {
    return axios.get('/auth/logout');
}

export function fetchUser(params) {
    return axios.get('/users', { params });
}

const createUser = function createUser(data) {
    return axios.post('/users', data);
}

const updateUser = function updateUser(data, id) {
    data.append('_method', 'PUT');
    return axios.post(`/users/${id}`, data);
}

export function saveUser(data, id = null) {
    if (id) {
        return updateUser(data, id);
    } else {
        return createUser(data);
    }
}

export function deleteUser(id) {
    return axios.delete(`/users/${id}`);
}
