import { apiGetWorkingGroup } from '../../api/product-sub-category';

import { FETCH_WORKING_GROUP_REQUEST, FETCH_WORKING_GROUP_SUCCESS, FETCH_WORKING_GROUP_FAILURE, FILTER_WORKING_GROUP } from './working-group.action.types';

const fetchWorkingGroupRequest = () => ({
    type: FETCH_WORKING_GROUP_REQUEST,
});

const fetchWorkingGroupSuccess = (result) => ({
    type: FETCH_WORKING_GROUP_SUCCESS,
    payload: result.data,
    totalPage: result.last_page,
    total: result.total
});

const fetchWorkingGroupFailure = (error) => ({
    type: FETCH_WORKING_GROUP_FAILURE,
    error: error
});

export const fetchWorkingGroup = (filterData) => {
    return async dispatch => {
        dispatch(fetchWorkingGroupRequest());
        await apiGetWorkingGroup(filterData).then((res) => {
            dispatch(fetchWorkingGroupSuccess(res));
        })
        .catch((error) => {
            dispatch(fetchWorkingGroupFailure(error.message));
        })
    };
};

export const setFilterWorkingGroup = (filterData) => ({
    type: FILTER_WORKING_GROUP,
    filterData
});
