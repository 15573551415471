import React from "react";
import {withNamespaces} from "react-i18next";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import {Button} from "element-react";

const ConfirmAction = ({isOpen, onToggle, onSubmit, t, message, isClosing, isSubmitting}) => {
	return (
		<Modal backdrop="static" size="sm" isOpen={isOpen}>
			<ModalBody className="scrollable-modal text-center">
				<h4>{message}</h4>
			</ModalBody>
			<ModalFooter className="justify-content-center">
				<Button onClick={() => onToggle()}>
					{ isClosing ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.cancel') }
				</Button>
				<Button type="primary" nativeType="submit" onClick={onSubmit}>
					{ isSubmitting ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.submit') }
				</Button>
			</ModalFooter>
		</Modal>
	)
}

ConfirmAction.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	message: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isClosing: PropTypes.bool,
	isSubmitting: PropTypes.bool
};

export default withNamespaces()(ConfirmAction);
