import axios from '../config/axios';
import { convertFormData } from '../utils/utility';

export function apiGetProducts(params) {
  return axios.get('products', { params });
};

export function apiSaveProduct(data) {
  if (data.product_sub_category_id) {
    return axios.post('products', convertFormData(data));
  } else {
    return axios.put(`products/${data.id}`, data);
  }
};

export function apiDeleteProduct(id) {
  return axios.delete(`products/${id}`);
};

export function apiGetFilters(name, params) {
    return axios.get(`products/filters/${name}`, {params});
};
