import { REHYDRATE } from 'redux-persist';

import { FETCH_USER, FILTER_USER } from './user.action.types';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    users: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: return rehydrate(state, action);
        case FETCH_USER: return getUser(state, action);
        case FILTER_USER: return filterUser(state, action);
        default: return state;
    }
};

const rehydrate = (state, action) => {
    return updateObject(state, action.payload ?
        { ...state, ...action.payload } : state);
};

const getUser = (state, action) => updateObject(state, {
    users: action.users,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterUser = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
