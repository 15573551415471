import React, { Suspense, lazy, Component } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import NProgress from 'nprogress';
import { connect } from 'react-redux';

import { getToken } from './utils/auth';
import 'nprogress/nprogress.css'; // progress bar style
import { getProfile, setAccessToken, signOut } from './store/actions/actions';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Login = lazy(() => import('./containers/Authentication/Login'));
const AuthCode = lazy(() => import('./containers/Authentication/AuthCode'));
const ForgotPassword = lazy(() => import('./containers/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('./containers/Authentication/ResetPassword'));

const Profile = lazy(() => import('./containers/Profile/Profile'));
const User = lazy(() => import('./containers/User/User'));
const Role = lazy(() => import('./containers/Role/Role'));
const Company = lazy(() => import('./containers/Company/Company'));
const VariousMaterials = lazy(() => import('./containers/VariousMaterials/VariousMaterials'));
const Activity = lazy(() => import('./containers/Activity/Activity'));
const BusinessCompany = lazy(() => import('./containers/BusinessCompany/BusinessCompany'));
const Country = lazy(() => import('./containers/Country/Country'));
const RootProductCategory = lazy(() => import('./containers/RootProductCategory/RootProductCategory'));
const ProductCategory = lazy(() => import('./containers/ProductCategory/ProductCategory'));
const ProductCombined = lazy(() => import('./containers/ProductCombined/ProductCombined'));
const Contact = lazy(() => import('./containers/Contact/Contact'));
const VendorFactory = lazy(() => import('./containers/VendorFactory/VendorFactory'));
const VendorCompany = lazy(() => import('./containers/VendorCompany/VendorCompany'));
const FactoryEvaluation = lazy(() => import('./containers/FactoryEvaluation/FactoryEvaluation'));
const FactoryEvaluationForm = lazy(() => import('./containers/FactoryEvaluationForm/FactoryEvaluationForm'));
const CertificateIssuance = lazy(() => import('./containers/CertificateIssuance/CertificateIssuance'));
const Invoice = lazy(() => import('./containers/Invoice/Invoice'));
const InvoiceUser = lazy(() => import('./containers/InvoiceUser/InvoiceUser'));
const SCAP = lazy(() => import('./containers/SCAP/SCAP'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));

const whiteList = ['/login', '/login-code', '/auth-redirect', '/forgot-password', '/reset-password']; // no redirect whitelist

NProgress.configure({ showSpinner: false });

export const routeList = [
  {
      name: 'Administrative task',
      translate: 'sidebar.nav.administrativeTask',
      submenu: [
          {
              name: 'Certification issuance',
              path: '/certification-issuance',
              component: CertificateIssuance,
              exact: true,
              translate: 'sidebar.nav.certificateIssuance',
              meta: {
          			roles: ['certificate']
          		}
          },
	      {
		      name: 'Invoice',
		      path: '/invoice',
		      component: Invoice,
		      exact: true,
		      translate: 'sidebar.nav.invoiceManagement',
		      meta: {
			      roles: ['invoice']
		      }
	      },
    ]
  },
  {
  		name: 'Certification issuance',
  		path: '/certification-issuance/:id',
  		component: CertificateIssuance,
  		hidden: true,
  		translate: 'sidebar.nav.certificateIssuance',
  },
	{
		name: 'Invoice',
		path: '/invoice-users',
		hidden: true,
		component: InvoiceUser,
		meta: {
			roles: ['invoice-user']
		}
	},
    {
        name: 'Evaluation List (CSR)',
        translate: 'sidebar.nav.evaluationListCsr',
        path: '/evaluation-list',
        exact: true,
        component: FactoryEvaluation,
        meta: {
            icon: 'evaluation',
            roles: ['evaluation-list']
        },
        section: 'csr'
    },
    {
      name: 'Evaluation List (QC)',
      translate: 'sidebar.nav.evaluationListQc',
      path: '/evaluation-list',
      exact: true,
      component: FactoryEvaluation,
      section: 'qc'
    },
    {
      name: 'Evaluation List',
      path: '/evaluation-list/:id',
      component: FactoryEvaluation,
      hidden: true,
      translate: 'sidebar.nav.evaluationList',
      meta: {
        icon: 'evaluation',
        roles: ['evaluation-list']
      }
    },
    {
        name: 'Factory Evaluation (Common)',
        path: '/factory-evaluation',
        exact: true,
        component: FactoryEvaluationForm,
        translate: 'sidebar.nav.factoryEvaluationCommon',
        meta: {
            icon: 'factory-evaluation',
            roles: ['factory-evaluation:create']
        },
        section: 'common'
    },
    {
          name: 'Factory Evaluation (CSR)',
          path: '/factory-evaluation',
          exact: true,
          component: FactoryEvaluationForm,
          translate: 'sidebar.nav.factoryEvaluationCsr',
          meta: {
              icon: 'factory-evaluation',
              roles: ['factory-evaluation:create']
          },
          section: 'csr'
    },
    {
          name: 'Factory Evaluation (QC)',
          path: '/factory-evaluation',
          exact: true,
          component: FactoryEvaluationForm,
          translate: 'sidebar.nav.factoryEvaluationQc',
          meta: {
              icon: 'factory-evaluation',
              roles: ['factory-evaluation:create']
          },
          section: 'qc'
    },
    {
        name: 'Factory Evaluation',
        path: '/factory-evaluation/:id',
        component: FactoryEvaluationForm,
        hidden: true,
        translate: 'sidebar.nav.factoryEvaluation',
        meta: {
            icon: 'factory-evaluation',
            roles: ['factory-evaluation:update']
        }
    },
    {
      name: 'Various Materials',
      path: '/various-materials',
      exact: true,
      component: VariousMaterials,
      //hidden: true,
      translate: 'sidebar.nav.variousMaterials',
    },
    {
        name: 'Various Materials',
        path: '/various-materials/:id',
        component: VariousMaterials,
        hidden: true,
        translate: 'sidebar.nav.variousMaterials',
    },
    {
        name: 'Master Tables',
        translate: 'sidebar.nav.masterTables',
        additional: true,
        submenu: [
            {
                name: 'Company Master',
                path: '/company-master',
                component: Company,
                translate: 'sidebar.nav.companyMaster',
                meta: {
                    icon: 'company',
                    roles: ['company']
                }
            },
            {
                name: 'Business Company Master',
                path: '/business-companies',
                component: BusinessCompany,
                translate: 'sidebar.nav.businessCompanyMaster',
                meta: {
                    icon: 'business-company',
                    roles: ['business-company']
                }
            },
            {
                name: 'Country Master',
                path: '/countries',
                component: Country,
                translate: 'sidebar.nav.countryMaster',
                meta: {
                    icon: 'country',
                    roles: ['country']
                }
            },
            {
                name: 'Root Product Category Master',
                path: '/root-product-categories',
                component: RootProductCategory,
                translate: 'sidebar.nav.rootProductCategoryMaster',
                meta: {
                    icon: 'product-category',
                    roles: ['root-product-category']
                }
            },
            {
                name: 'Product Category Master',
                path: '/product-categories',
                component: ProductCategory,
                translate: 'sidebar.nav.productCategoryMaster',
                meta: {
                    icon: 'product-category',
                    roles: ['product-category']
                }
            },
            // {
            //     name: 'Product Combined Master',
            //     path: '/products',
            //     component: ProductCombined,
            //     translate: 'sidebar.nav.productsAndGroups',
            //     meta: {
            //         icon: 'product-category',
            //         roles: ['product-category']
            //     }
            // },
            {
                name: 'Contact Master',
                path: '/contacts',
                component: Contact,
                translate: 'sidebar.nav.contactMaster',
                meta: {
                    icon: 'vendor-factory',
                    roles: ['contact']
                }
            },
            {
                name: 'Vendor Company Master',
                path: '/vendor-companies',
                exact: true,
                component: VendorCompany,
                translate: 'sidebar.nav.vendorCompanyMaster',
                meta: {
                    icon: 'vendor-company',
                    roles: ['vendor-company']
                }
            },
            {
                name: 'Vendor Company Master',
                path: '/vendor-companies/:id',
                component: VendorCompany,
                hidden: true,
                translate: 'sidebar.nav.vendorCompanyMaster',
                meta: {
                    icon: 'vendor-company',
                    roles: ['vendor-company']
                }
            },
            {
                name: 'Vendor Factory Master',
                path: '/vendor-factories',
                exact: true,
                component: VendorFactory,
                translate: 'sidebar.nav.vendorFactoryMaster',
                meta: {
                    icon: 'vendor-factory',
                    roles: ['vendor-factory']
                }
            },
            {
                name: 'Vendor Factory Master',
                path: '/vendor-factories/:id',
                component: VendorFactory,
                hidden: true,
                translate: 'sidebar.nav.vendorFactoryMaster',
                meta: {
                    icon: 'vendor-factory',
                    roles: ['vendor-factory']
                }
            }
        ]
    },
    {
        name: 'User Master',
        translate: 'sidebar.nav.userMaster',
        additional: true,
        submenu: [
            {
                name: 'User Master',
                path: '/users',
                component: User,
                translate: 'sidebar.nav.usersData',
                meta: {
                    icon: 'user',
                    roles: ['user']
                }
            },
            {
                name: 'Role Master',
                path: '/roles',
                component: Role,
                translate: 'sidebar.nav.roleMaster',
                meta: {
                    icon: 'role',
                    roles: ['role']
                }
            },
        ]
    },
    {
        name: 'Activity',
        path: '/activities',
        component: Activity,
        translate: 'sidebar.nav.activity',
        additional: true,
        meta: {
            icon: 'activity',
            roles: ['activity']
        }
    },
    {
        name: 'Profile',
        path: '/profile',
        component: Profile,
        hidden: true
    },
];

class Routes extends Component {
    state = {
	    session: null,
        redirectLogin: false,
        isAuthenticated: false,
        cookieToken: getToken()
    }

    componentDidMount() {
        if (!this.state.cookieToken) {
            NProgress.done();
            this.setState({ redirectLogin: true });
        } else {
            this.props.setAccessToken(this.state.cookieToken);
        }
    }

    componentDidUpdate(preProps) {
        if (this.props.token && !preProps.token) {
            this.setState({ cookieToken: null });
            this.requireAuth();
        }
    }

    requireAuth = async () => {
        NProgress.start();
        try {
            await this.props.getAuthenticatedUser();
            if (whiteList.indexOf(this.props.location.pathname) !== -1) {
                this.props.history.push('/');
            }
        } catch (error) {
            this.props.signOut();
            this.setState({ redirectLogin: true });
        }
        NProgress.done();
    }

    renderRedirect = () => {
        if (this.state.redirectLogin && whiteList.indexOf(this.props.location.pathname) !== -1) {
            return <Redirect to={{pathname: '/login', next: this.props.location.pathname}} />
        }
    }

    generateRoutes = (routes) => {
        let accessedRoutes = [];
        routes.forEach(route => {
            if (route.component) {
                accessedRoutes.push(<Route key={route.path} types={route.type} exact={route.exact} path={route.path} component={waitFor(route.component)} />);
            }
            if (route.submenu) {
                accessedRoutes = [...accessedRoutes, ...this.generateRoutes(route.submenu)];
            }
        });
        return accessedRoutes;
    }

    getRedirectPage = (routes) => {
        if (!routes) return;
        let redirectPage;
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            if (route.path && !route.hidden) {
                redirectPage = route.path;
                break;
            }
            if (route.submenu) {
                redirectPage = this.getRedirectPage(route.submenu);
                if (redirectPage) {
                    break;
                }
            }
        }
        return redirectPage;
    }

    render() {
        const { location, isAuthenticated, token, accessedRoutes } = this.props;
        const currentKey = location.pathname.split('/')[1] || '/';
        const timeout = { enter: 500, exit: 500 };
        const animationName = 'rag-fadeIn'; // 'rag-fadeIn', 'rag-fadeInRight', 'rag-fadeInLeft'
        const redirectPage = this.getRedirectPage(accessedRoutes);
        // Page Layout component wrapper
        const baseRoutes = (
            <BasePage>
	            {location.pathname.includes('login-code') && !this.props.session && <Redirect to={{pathname: '/login', next: location.pathname + this.props.location.search}} />}
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route exact path="/login" component={waitFor(Login)} />
	                    <Route exact path="/login-code" component={waitFor(AuthCode)} />
                        <Route exact path="/forgot-password" component={waitFor(ForgotPassword)} />
                        <Route exact path="/reset-password" component={waitFor(ResetPassword)} />
	                    {this.props.session ?
		                    <Redirect to={{pathname: '/login-code', next: location.pathname + this.props.location.search}}></Redirect> :
		                    <Redirect to={{pathname: '/login', next: location.pathname + this.props.location.search}}></Redirect>
	                    }
                    </Switch>
                </Suspense>
            </BasePage>
        )

        const scapRoutes = (
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/404" component={waitFor(NotFound)} />
                        <Route path="/scap" component={waitFor(SCAP)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
        if ((!token && !this.state.cookieToken) || this.props.session) {
            return baseRoutes;
        } else if (location.pathname.includes('scap') || '/404' === location.pathname) {
            return scapRoutes;
         } else {
            return (
                // Layout component wrapper
                // Use <BaseHorizontal> to change layout
                <Route path="/" render={() => {
                    this.renderRedirect();
                    if (!isAuthenticated) {
                        return null;
                    }
                    return (
                        <Base>
                            <TransitionGroup>
                                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                                    <div>
                                        <Suspense fallback={<PageLoader />}>
                                            <Switch location={location}>
                                                {this.generateRoutes(accessedRoutes)}
                                                <Redirect to={{pathname: redirectPage, view: location.pathname.split('/')[1] || '/' }} />
                                            </Switch>
                                        </Suspense>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </Base>
                    )
                }} />
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.access_token,
        roles: state.auth.permissions,
	    session: state.auth.authCodeSession,
        accessedRoutes: state.auth.accessedRoutes
    };
};

const mapDispatchToProps = dispatch => ({
    getAuthenticatedUser: () => dispatch(getProfile()),
    setAccessToken: (token) => dispatch(setAccessToken(token)),
    signOut: () => dispatch(signOut())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
