import React from "react";
import Fee from "../Invoice/Common/Fee";

const EvaluationFee = ({data}) => {
	return (
		<React.Fragment>
			{Number.isInteger(data.fee) &&
				<div className="evaluation-fee">
					<p>{data.date ? data.date.substr(0, 7) : ''}</p>
					<p><Fee alignRight={false} value={data.fee}/></p>
					<input
						type="checkbox"
						className="fee-paid"
						checked={data.is_paid}
						disabled={true}
					/>
					<input
						type="checkbox"
						className="fee-received"
						checked={data.is_received}
						disabled={true}
					/>
				</div>
			}
		</React.Fragment>
	)
}

export default EvaluationFee;
