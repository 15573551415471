import { apiGetProducts } from '../../api/product';

import { FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE, FILTER_PRODUCT } from './product.action.types';

const fetchProductRequest = () => ({
    type: FETCH_PRODUCT_REQUEST
});

const fetchProductSuccess = (result) => ({
    type: FETCH_PRODUCT_SUCCESS,
    payload: result.data,
    loading: false,
    totalPage: result.last_page,
    total: result.total
});

const fetchProductFailure = (error) => ({
    type: FETCH_PRODUCT_FAILURE,
    payload: error
});

export const fetchProduct = (filterData) => {
    return async dispatch => {
        dispatch(fetchProductRequest());
        await apiGetProducts(filterData).then((res) => {
            dispatch(fetchProductSuccess(res));
        })
        .catch((error) => {
            dispatch(fetchProductFailure(error.message));
        })
    };
};

export const setFilterProduct = (filterData) => ({
    type: FILTER_PRODUCT,
    filterData
});
