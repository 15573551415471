import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { connect } from 'react-redux';
import { Button, Layout, Form, Input, Loading } from 'element-react';
import { withFilter } from '../../hoc';
import { Pagination } from '../../components/Common';
import * as actions from './vendor-company.actions';
import { apiDeleteVendorCompany, apiSaveVendorCompany } from '../../api/vendor-company';
import { apiSaveBusinessCompany } from '../../api/business-company';
import { VendorCompanyForm, VendorCompanyList } from '../../components/VendorCompany';
import { BusinessCompanyForm } from '../../components/BusinessCompany';
import { VendorCompanyModel, BusinessCompanyModel } from '../../models';
import permission from '../../utils/permission';
import DownloadCsv from '../../components/Common/DownloadCsv';
import Message from '../../utils/Message';

class VendorCompany extends Component {
    _isMounted = false;
		_id = this.props.match.params.id;
    state = {
	    filters: {},
        firstLoading: true,
        businessDialogVisible: false,
        businessCompany: new BusinessCompanyModel()
    }

    componentDidMount() {
        this._isMounted = true;
        this._id ?
        this.props.fetchOneVendorCompany(this._id) :
        this.filter();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSubmit = async () => {
        await this.props.onSubmit(VendorCompanyModel, apiSaveVendorCompany);
        this.filter();
    }

    onSubmitBusinessCompany = async () => {
        const { t } = this.props;
        try {
            const result = await apiSaveBusinessCompany(new BusinessCompanyModel(this.state.businessCompany));
            this.setState({ businessDialogVisible: false });
	        Message.success(
		        t('global.saveSuccess'),
		        t('global.success')
	        );
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    onDelete = async (id) => {
        await this.props.onDelete(id, apiDeleteVendorCompany);
        this.filter();
    }

    filter = async (params) => {
        const filterColumns = [
            'company_name',
            'address',
            'business_company',
            'product_category',
            'country_id'
        ];

        const newFilter = { ...this.props.filterData, ...params };
        let filter = {
            limit: newFilter.limit,
            page: newFilter.page,
            ...this.props.likeFilter('name', newFilter.name)
        };
        filterColumns.forEach(column => filter = {
            ...filter,
            ...this.props.whereInFilter(column, newFilter[column])
        });
        await this.props.fetchVendorCompany(filter);
        if (this._isMounted) {
            this.setState({
                filters: filter,
                firstLoading: false
            });
        }
    }

    onChangeHandler = (value, key, variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem[key] = value;
        this.setState({ [variableKey]: selectedItem });
    }

    onChangeContactHandler = (value, contactKey, index, variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem.contacts[index][contactKey] = value;
        this.setState({ [variableKey]: selectedItem });
    }

    dialogHandler = (modal) => {
        this.setState({ [modal]: !this.state[modal] });
    }

    onAddContact = (variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem.contacts.push({});
        this.setState({ [variableKey]: selectedItem });
    }

    onRemoveContact = (index, variableKey) => {
        const selectedItem = { ...this.state[variableKey] };
        selectedItem.contacts.splice(index, 1);
        this.setState({ [variableKey]: selectedItem });
    }

    onAddBusinessCompany = () => {
        this.setState({
            businessCompany: new BusinessCompanyModel(),
            businessDialogVisible: true
        });
    }

    onChangeWithFilter = (value, key) => {
        this.props.onChangeWithFilterAndResetPage(value, key);
        this.setState(prevState => ({
            isRenderList: !prevState.isRenderList
        }));
    };

    getContactType = (type) => {
        if (type === 0) {
            return ""
        } else {
            return type
        }
    }

    render() {
        const { filterData, vendorCompanies, t } = this.props;
        return (
            <ContentWrapper>
                <VendorCompanyForm
                    newBusinessCompanyButton={true}
                    data={this.props.selectedItem}
                    visible={this.props.dialogVisible}
                    businessDialogVisible={this.state.businessDialogVisible}
                    onChangeHandler={this.props.onChangeHandler}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.dialogHandler}
                    onAddBusinessCompany={this.onAddBusinessCompany}
                    onAddContact={this.props.onAddContact}
                    onRemoveContact={this.props.onRemoveContact}
                    onChangeContactHandler={this.props.onChangeContactHandler}
                />
                <BusinessCompanyForm
                    data={this.state.businessCompany}
                    visible={this.state.businessDialogVisible}
                    onChangeHandler={(value, key) => this.onChangeHandler(value, key, 'businessCompany')}
                    countries={this.props.countries}
                    onSubmit={this.onSubmitBusinessCompany}
                    onCancel={() => this.dialogHandler('businessDialogVisible')}
                    onAddContact={() => this.onAddContact('businessCompany')}
                    onRemoveContact={(index) => this.onRemoveContact(index, 'businessCompany')}
                    onChangeContactHandler={(value, contactKey, contactIndex) => this.onChangeContactHandler(value, contactKey, contactIndex, 'businessCompany')}
                />
                <div className="content-heading">
                    <div>
	                    {t('sidebar.nav.vendorCompanyMaster')}
                    </div>
	                {permission('vendor-company-export:view') &&
		                <DownloadCsv
			                lang={this.props.lng}
			                subject={'vendor_companies'}
			                filters={this.state.filters}
		                />
	                }
                </div>
                <Layout.Row>
                    <Layout.Col sm="12">
                        <Form inline={true}>
                            <Form.Item>
                                <Input
                                    value={this.props.filterData.name}
                                    icon="search"
                                    placeholder={t('vendorCompany.search')}
                                    onChange={this.props.onTextSearch}
                                />
                            </Form.Item>
                            <Form.Item>
                                {permission('vendor-company:create') &&
                                    <Button type="primary" onClick={() => this.props.onCreate(VendorCompanyModel)}>
                                        {t('vendorCompany.add')}
                                    </Button>
                                }
                            </Form.Item>
                        </Form>
                    </Layout.Col>
                    <Layout.Col sm="12">
                        <div className="pagination-right">
                            <Pagination
                                pageCount={filterData.totalPage}
                                total={filterData.total}
                                pageSize={filterData.limit}
                                initialPage={filterData.page}
                                onPageChange={this.props.onPageChange}
                            />
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Loading loading={this.state.firstLoading && !this.props.vendorCompanies.length}>
                    <VendorCompanyList
                        vendorCompanies={vendorCompanies}
                        filterData={filterData}
                        onSelect={this.props.onSelect}
                        onDelete={this.onDelete}
                        onChangeWithFilter={this.onChangeWithFilter}
                        getContactType={this.getContactType}
                    />
                </Loading>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        vendorCompanies: state.vendorCompany.vendorCompanies,
        filterData: state.vendorCompany.filterData
    };
};

const mapDispatchToProps = dispatch => ({
    fetchVendorCompany: (params) => dispatch(actions.fetchVendorCompany(params)),
    fetchOneVendorCompany: (params) => dispatch(actions.fetchOneVendorCompany(params)),
    setReduxFilter: (params) => dispatch(actions.setFilterVendorCompany(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(VendorCompany)));
