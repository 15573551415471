import React, {Component} from "react";
import Message from "../../utils/Message";
import ContentWrapper from "../../components/Layout/ContentWrapper";
import { Button,Loading } from 'element-react';
import { WorkingGroupList } from "../../components/WorkingGroup";
import { Pagination } from '../../components/Common';
import permission from "../../utils/permission";
import * as actions from './working-group.actions';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import { withFilter } from '../../hoc';
import { apiGetAllProductSubCategory, apiDeleteProductSubCategory } from "../../api/product-sub-category"

class WorkingGroup extends Component {
	_isMounted = false;
	state = {
			isLoading: true,
			workingGroups: [],
			selectedItem: null,
			filters: {},
			isAddModalOpen: false
	}

	async componentDidMount() {
			this._isMounted = true;
			this.filter();
	}

	onOpenAddModal = () => {
			this.setState({ isAddModalOpen: true });
	}

	onOpenEditModal = (product) => {
			this.setState({ isEditModalOpen: true, selectedItem: product });
	}

	onCloseEditModal = () => {
			this.setState ({ isEditModalOpen: false });
	}

	onCloseAddModal = () => {
			this.setState ({ isAddModalOpen: false });
	}

  filter = async (params) => {
      const filterColumns = [
          'name_en'
      ];

      const newFilter = { ...this.props.filterData, ...params };
      let filter = {
          limit: newFilter.limit,
          page: newFilter.page,
          ...this.props.likeFilter('name', newFilter.name)
      };
      filterColumns.forEach(column => filter = {
          ...filter,
          ...this.props.whereInFilter(column, newFilter[column])
      });
      await this.props.fetchWorkingGroup(filter);
      if (this._isMounted) {
          this.setState({
              filters: filter,
              firstLoading: false
          });
      }
  }

  onChangeWithFilter = (value, key) => {
      this.props.onChangeWithFilterAndResetPage(value, key);
      this.setState(prevState => ({
          isRenderList: !prevState.isRenderList
      }));
  };


	render() {
    const { t, filterData } = this.props;
		return (
			<div>
          <Loading loading={(!this.props.workingGroups.length || this.props.isLoading)}>
							<WorkingGroupList
	                workingGroups={this.props.workingGroups}
                  filterData={filterData}
                  onChangeWithFilter={this.onChangeWithFilter}
									lang={this.props.language}
							/>
              <Pagination
									pageCount={filterData.totalPage}
									total={filterData.total}
									pageSize={filterData.limit}
									initialPage={filterData.page}
									onPageChange={this.props.onPageChange}
							/>
					</Loading>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return {
				isLoading: state.workingGroup.loading,
        workingGroups: state.workingGroup.workingGroups,
        filterData: state.workingGroup.filterData,
				language: state.settings.language
    };
};

const mapDispatchToProps = dispatch => ({
    fetchWorkingGroup: (params) => dispatch(actions.fetchWorkingGroup(params)),
    setReduxFilter: (params) => dispatch(actions.setFilterWorkingGroup(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(WorkingGroup)));
