import React from 'react';
import { Form, Input, Layout } from 'element-react';
import { Select } from '../Common';
import { withNamespaces } from 'react-i18next';
import { contactTypes } from '../../utils/variables';

const ContactFields = (props) => {
    const { contact, contactIndex, nameLength, t, onChange } = props;

    const validateContactName = (value, callback) => {
        value = value.name;
        if (!value) {
            callback(new Error(t('contact.pleaseInputName')));
        }
        if (value && nameLength && value.length > nameLength) {
            callback(new Error(t('contact.validMaxNameLength')));
        }
        callback();
    }

    return (
        <Layout.Row>
            <Layout.Col span="22">
                <Form.Item label={t('global.name')} prop={`contacts:${contactIndex}`} required
                    rules={{ validator: (rule, value, callback) => validateContactName(value, callback) }}>
                    <Input
	                    value={contact.name}
	                    onChange={(value) => props.onChange(value, 'name', contactIndex)}
                    />
                </Form.Item>
                <Form.Item label={t('contact.organization')} prop="organization">
                    <Input
	                    value={contact.organization}
	                    onChange={(value) => onChange(value, 'organization', contactIndex)}
                    />
                </Form.Item>
                <Form.Item label={t('contact.title')} prop="title">
                    <Input
	                    value={contact.title}
	                    onChange={(value) => onChange(value, 'title', contactIndex)}
                    />
                </Form.Item>
                <Layout.Row>
                    <Layout.Col lg="12">
                        <Form.Item label={t('contact.phone')} prop="phone_number">
                            <Input
	                            value={contact.phone_number}
	                            onChange={(value) => onChange(value, 'phone_number', contactIndex)}
                            />
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col lg="12">
                        <Form.Item label={t('contact.mobile')} prop="mobile_number">
                            <Input
	                            value={contact.mobile_number}
	                            onChange={(value) => onChange(value, 'mobile_number', contactIndex)}
                            />
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row>
                    <Layout.Col lg="12">
                        <Form.Item label={t('contact.email')} prop={`contacts:${contactIndex}`}>
                            <Input
                              type="email"
	                            value={contact.email}
	                            onChange={(value) => onChange(value, 'email', contactIndex)}
                            />
                        </Form.Item>
                    </Layout.Col>
                    {props.isTypeRequired &&
                      <Layout.Col lg="12">
                          <Form.Item label='type' prop="type">
                              <Select
                                  value={contact.type}
                                  onChange={(value) => onChange(value, 'type', contactIndex)}
            											options={contactTypes ? contactTypes : []}
                                  menuPlacement='top'
            											label="name"
                              />
                          </Form.Item>
                      </Layout.Col>
                    }
                </Layout.Row>
            </Layout.Col>
        </Layout.Row>
    )
}

export default withNamespaces()(ContactFields);
