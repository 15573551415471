import axios from '../config/axios';

export function apiGetVendorCompany(params) {
  return axios.get('vendor-companies', { params });
};

export function apiFindVendorCompany(id) {
  return axios.get(`vendor-companies/${id}`);
};

export function apiDeleteVendorCompany(id) {
  return axios.delete(`vendor-companies/${id}`);
};

export function apiSaveVendorCompany(data) {
  if (data.id) {
    return axios.put(`vendor-companies/${data.id}`, data);
  } else {
    return axios.post('vendor-companies', data);
  }
};

export function apiGetVendorCompanyContacts(id) {
  return axios.get('vendor-companies/get-contacts', {params: {id: id }});
};

export function apiGetFilters(name, params) {
    return axios.get(`vendor-companies/filters/${name}`, {params});
};
