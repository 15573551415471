import React, { Component } from 'react';
import {
    Popover as BSPopover,
    PopoverHeader,
    PopoverBody
} from 'reactstrap';
import { Loading } from 'element-react';
import { apiFindProductSubCategory } from '../../../api/product-sub-category';

class ParentPopover extends Component {
    state = {
        popoverOpen: false,
        id: this.props.id,
        workingGroup: {},
        loading: true,
        error: false,
    }

    toggle = async () => {
      await this.setState({ loading: true });
      await this.setState({ popoverOpen: !this.state.popoverOpen });
        let parent = {};
        parent = await apiFindProductSubCategory(this.props.parent_id);
        parent=parent.data;

        this.setState({
            workingGroup: parent,
            loading: false,
            error: false,
        });
    }

    render() {
        const { id, t } = this.props;
        return (
            <React.Fragment>
                <span onClick={typeof this.props.children === 'object' ? this.toggle : undefined}>
                    {this.props.children}
                </span>
            {typeof this.props.children === 'object' &&
                <BSPopover className="edit-content-container" placement={'bottom'} isOpen={this.state.popoverOpen} target={'parentPopover-' + id} toggle={this.toggle}>
                    <PopoverHeader></PopoverHeader>
                    <Loading loading={this.state.loading}>
                        <PopoverBody className={'el-table-edit'} style={{padding: '20px', maxWidth: '250px'}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>ID: {this.state.workingGroup.id}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('global.name_jp')}: {this.state.workingGroup.name_jp}</td>
                                    </tr>
                                    <tr>
                                       <td>{t('global.name_en')}: {this.state.workingGroup.name_en}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </PopoverBody>
                    </Loading>
                </BSPopover>}
            </React.Fragment>
        )
    }
}

export default ParentPopover;
