import ContactModel from './ContactModel';

export default class VendorFactoryModel {
	constructor(data = {}) {
		this.id = data.id;
        this.fy2020 = data.fy2020;
		this.name_jp = data.name_jp;
		this.name_en = data.name_en;
		this.name_ch = data.name_ch;
		this.address_jp = data.address_jp;
		this.address_en = data.address_en;
		this.address_ch = data.address_ch;
		this.country_id = data.country_id;

		this.vendor_companies = this.removeEmptyObjects(data.vendor_companies || []);
		this.business_companies = this.removeEmptyObjects(data.business_companies || []);
		this.product_categories = this.removeEmptyObjects(data.product_categories || []);
		this.vendor_company_contacts = this.removeEmptyObjects(data.vendor_company_contacts || []);
		this.contacts = data.contacts || [ new ContactModel() ];
	}

	removeEmptyObjects(data) {
		return data.filter(item => {
			return Object.keys(item).length;
		});
	}
}
