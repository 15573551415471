import axios from '../config/axios';

export function apiAddHash(id) {
  return axios.post(`blockchain/certificate/add-hash/${id}`);
}

export function apiHasHash(id) {
  return axios.get(`blockchain/certificate/has-hash/${id}`);
}

export function apiGetProfile() {
  return axios.get(`blockchain/certificate/profile`);
}

export function apiCheckProof(id) {
  return axios.get(`blockchain/certificate/proof/${id}`);
}

export function apiSubmitProof(data) {
  return axios.post(`blockchain/certificate/proof`, data);
}
