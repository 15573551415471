import { apiGetRole } from '../../api/role';
import { 
    FETCH_ROLE,
    FETCH_ALL_ROLE,
    FILTER_ROLE
} from './role.action.types';

const fetchRoleSuccess = (result) => ({
    type: FETCH_ROLE,
    roles: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchRole = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetRole(filterData);
            dispatch(fetchRoleSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const fetchAllRoleSuccess = (result) => ({
    type: FETCH_ALL_ROLE,
    allRoles: result.data
});

export const fetchAllRole = () => {
    return async dispatch => {
        try {
            const result = await apiGetRole({ limit: 'unlimited' });
            dispatch(fetchAllRoleSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterRole = (filterData) => ({
    type: FILTER_ROLE,
    filterData
});
