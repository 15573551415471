import { apiGetCountry } from '../../api/country';
import { 
    FETCH_COUNTRY,
    FETCH_ALL_COUNTRY,
    FILTER_COUNTRY
} from './country.action.types';

const fetchCountrySuccess = (result) => ({
    type: FETCH_COUNTRY,
    countries: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchCountry = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetCountry(filterData);
            dispatch(fetchCountrySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const fetchAllCountrySuccess = (result) => ({
    type: FETCH_ALL_COUNTRY,
    allCountries: result.data
});

export const fetchAllCountry = () => {
    return async dispatch => {
        try {
            const result = await apiGetCountry({ limit: 'unlimited' });
            dispatch(fetchAllCountrySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterCountry = (filterData) => ({
    type: FILTER_COUNTRY,
    filterData
});