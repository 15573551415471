import axios from "../config/axios";

export function apiFindOtherBusinessDetails(id, evaluationId, lang) {
	return axios.get(`other-businesses/details/${id}/${evaluationId}`, {params: {lang: lang}});
}

export function apiAddOtherBusiness(params) {
	return axios.post(`other-businesses/store`, params);
}

export function apiDeleteOtherBusiness(id) {
	return axios.delete(`other-businesses/${id}`);
}
