import React from 'react';
import CKEditor from 'ckeditor4-react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.CKEditor = null;
        this.instance = null;
    }

    componentDidMount() {
        let name = this.props.name;
        let id = setInterval(() => {
            if (this.CKEditor && this.props.name) {
                for (let i in this.CKEditor.instances) {
                    if (this.CKEditor.instances.hasOwnProperty(i) &&
	                    name === this.CKEditor.instances[i].config.name) {
                        clearInterval(id);
                        this.instance = this.CKEditor.instances[i];
                        this.props.pull(() => {
                            return this.instance.getData();
                        });
	                    this.readOnly();
                    }
                }
            }
        }, 100);
    }

    componentWillReceiveProps(nextProps, nextContext) {
	    if (false === this.props.sync && true === nextProps.sync) {
		    this.props.pull(() => {
			    return this.instance.getData();
		    });
	    }
    }

	readOnly() {
		if (this.instance &&
			typeof this.props.lock === 'boolean' &&
			this.instance.setReadOnly &&
			typeof this.instance.setReadOnly === 'function') {
			this.instance.setReadOnly(this.props.lock)
		}
    }

    timestamp = async (e) => {
  		e.preventDefault();
  		let content = await this.instance.getData();
  		let date = this.getFormattedDate();
      await this.setState({ text: `<p><b>${date} ${this.props.userName}:</b>&nbsp;</p> ${content ? content : ''}`});
    }

  	getFormattedDate = () => {
  		let now = new Date();
  		let [year, month, day] = now.toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }).split('/');
  		month  = month < 10 ? '0' + month : month;
  		day  = day < 10 ? '0' + day : day;
   		return [year, month, day].join('.');
  	}

	render() {
        return (
            <div style={{display: 'flex', position: 'relative'}}>
		        <button disabled={this.props.lock} className='timestampButton' onClick={this.timestamp}>{this.props.t('factoryEvaluation.addTimestamp')}</button>
	            {this.readOnly()}
	            {this.props.lock && this.props.user && !this.props.sync ? <p style={{'color': 'red', position: 'absolute', top: '-20px'}}>{this.props.userName} is editing ...</p> : ''}
	            {this.props.sync ? <p style={{'color': 'blue', position: 'absolute', top: '-20px'}}>Synchronising ...</p> : ''}
                <CKEditor
                    onBeforeLoad={cke => {
                        this.CKEditor = cke;
                        return cke.disableAutoInline = true;
                    }}
                    data={this.state ? this.state.text : this.props.text}
                    config={{
                        name: this.props.name,
                        versionCheck: false,
                        extraPlugins: ['colorbutton', 'font'],
                        removePlugins: ["CKFinder", "Heading", "TextTransformation", "MediaEmbed", "PasteFromOffice", "Table", "TableToolbar", "TableProperties", "TableCellProperties", "TextTransformation"],
                        removeButtons: 'About,Image,Link,Scayt,Anchor,Magicline,HorizontalRule,Font',
                    }}
                    onFocus={this.props.onFocus}
                />
            </div>
        )
    }
}

Editor.propTypes = {
    pull: PropTypes.func,
    lock: PropTypes.bool,
    sync: PropTypes.bool,
    name: PropTypes.string,
    text: PropTypes.string,
    user: PropTypes.string,
    onFocus: PropTypes.func,
    userName: PropTypes.string
};

export default withNamespaces()(Editor);
