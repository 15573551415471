import React, { Component } from 'react';
import { Select } from 'element-react';

class SelectSearch extends Component {

    state = {
        isHover: false,
        isShowClearIcon: false
    }

    onMouseEnter = () => {
        if (this.props.value) {
            this.setState({ isHover: true });
        }
    }

    onMouseLeave = () => {
        if (this.state.isHover) {
            this.setState({ isHover: false });
        }
    }

    onMouseEnterIcon = () => {
        if (this.props.value) {
            this.setState({ isShowClearIcon: true });
        }
    }

    onMouseLeaveIcon = () => {
        if (this.state.isShowClearIcon) {
            this.setState({ isShowClearIcon: false });
        }
    }

    onIconClickHandle = () => {
        this.setState({ isShowClearIcon: false, isHover: false });
        this.props.onChange("");
    }

    render() {
        const {
            firstLable,
            secondLable,
            valueKey,
            value,
            placeholder,
            remoteMethod,
            onChange,
            loading,
            data,
            remote,
            ...otherProps
        } = this.props;
        return (
            <div className="select-search"
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <Select value={value}
                    filterable remote={remote || true}
                    clearable
                    placeholder={placeholder ? placeholder : " "}
                    remoteMethod={remoteMethod}
                    onChange={onChange}
                    loading={loading}
                    {...otherProps}
                >
                    {
                        data.map(el => {
                            return <Select.Option
                                key={el.id}
                                label={firstLable ? el[firstLable] : el.name}
                                value={valueKey ? el[valueKey] : el.id}
                            >
                                {secondLable &&
                                    <React.Fragment>
                                        <span>{el[firstLable]}</span>
                                        <span className="right-part">{el[secondLable]}</span>
                                    </React.Fragment>
                                }
                            </Select.Option>
                        })
                    }
                </Select>
                {(!this.state.isHover || !this.props.value)  &&
                    <i
                        className="el-input__icon el-icon-search"
                        onMouseEnter={this.onMouseEnterIcon}
                    >
                    </i>
                }
                {this.state.isShowClearIcon &&
                    <i
                        onMouseLeave={this.onMouseLeaveIcon}
                        onClick={this.onIconClickHandle}
                        className="el-input__icon el-icon-circle-close"></i>
                }
            </div>
        )
    }
}

export default SelectSearch;
