import React from 'react';
import { Input as RTInput } from 'reactstrap';

const Input = (props) => {
    const { value, onChange, ...otherProps } = props;
    return (
        <RTInput 
            value={value || ''} 
            onChange={(e) => onChange(e.target.value)} 
            {...otherProps} 
        />
    )
}

export default Input;