import { apiGetContact } from '../../api/contact';

export const FETCH_CONTACT = 'FETCH_CONTACT';
export const FILTER_CONTACT = 'FILTER_CONTACT';

const fetchContactSuccess = (result) => ({
    type: FETCH_CONTACT,
    contacts: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchContact = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetContact(filterData);
            dispatch(fetchContactSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterContact = (filterData) => ({
    type: FILTER_CONTACT,
    filterData
});