import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'element-react';
import { withNamespaces } from 'react-i18next';
import { Form } from '../Common';
import ConfirmationModal from '../Common/ConfirmationModal';
import FactoryEvaluationDefault from './default/FactoryEvaluationList.default';
import { apiSaveFeeDetail } from '../../api/fee-detail';
import { apiFindFactoryEvaluation, apiQuickUpdateFactoryEvaluation } from '../../api/factory-evaluation';
import { apiQuickUpdateFactoryEvaluationFee } from '../../api/factory-evaluation-fee';
import EditorSync from '../Common/EditorSync';
import Websocket from '../../config/echo';
import UploadFileModal from '../Common/UploadFileModal';
import Draggable from 'react-draggable'
import FeeDetailsModal from "../Common/FeeDetailsModal";
import Message from "../../utils/Message";
import CancelFeeModal from "../Common/CancelFeeModal";

class FactoryEvaluationList extends Component {
	constructor(props) {
		super(props);
		this.getEditorData = null;
		this.state = {
			fee: {details: []},
			feeId: null,
			feeType: null,
			cancelFee: null,
			cancelFeeId: null,
			openConfirmationModal: false,
			submitLock: false,
			isOpenModal: false,
			isCancelFeeModal: false,
			isOpenFileUploadModal: false,
			isOpenFeeDetailsModal: false,
			modalTitle: '',
			type: '',
			evaluation: null,
			evaluationId: null,
			editorTitle: null,
			editorField: null,
			editorContent: null,
		}
	}

	toggle = () => {
		this.setState(prevState => {
			return {
				isOpenModal: !prevState.isOpenModal
			}
		});
	};

	handleEvalResultChange = async (value, key, id) => {
		await apiFindFactoryEvaluation(id).then((item) => {
			if (!this.state.previousEval) {
				this.setState({previousEval: item.data.evaluation_result_id});
				if (!item.data.evaluation_result_id) {
					this.setState({initialNull: true})
				}
			}
		});
		this.props.addFreezeId(id);
		await apiQuickUpdateFactoryEvaluation(id, {[key]: value});
		this.props.refresh();
	};

	handleConfirm = async () => {
		if (this.state.type === 'confirm') {
			await apiQuickUpdateFactoryEvaluation(this.state.evalId, {'judgement_confirmed_at': 'true'})
			this.setState({previousEval: null, openConfirmationModal: false, type: null, evalId: null})
			this.props.refresh()
		} else {
			await apiQuickUpdateFactoryEvaluation(this.state.evalId, {'judgement_confirmed_at': null})
			this.setState({openConfirmationModal: false, type: null, evalId: null})
			this.props.refresh()
		}
	}
	handleCancel = async () => {
		this.setState({openConfirmationModal: false, type: null, evalId: null})
	}

	// ------------------ judgement buttons functions -----------------

	confirmJudgement = async (id) => {
		this.setState({
			openConfirmationModal: true,
			modalMessage: this.props.t('factoryEvaluation.judgementConfirmMsg'),
			type: 'confirm',
			evalId: id
		})
	}
	updateJudgement = async (id) => {
		this.setState({
			openConfirmationModal: true,
			modalMessage: this.props.t('factoryEvaluation.judgementUpdateMsg'),
			type: 'update',
			evalId: id
		})
	}

	cancelEdit = async (id, key) => {
		if (this.state.previousEval && !this.state.initialNull) {
			await apiQuickUpdateFactoryEvaluation(id, {[key]: this.state.previousEval, 'judgement_confirmed_at': 'true'})
		} else if (this.state.initialNull) {
			await apiQuickUpdateFactoryEvaluation(id, {[key]: null, 'judgement_confirmed_at': null})
		} else {
			await apiQuickUpdateFactoryEvaluation(id, {'judgement_confirmed_at': 'true'})
		}
		this.setState({previousEval: null, initialNull: false})
		this.props.refresh();
	}

	// --------------- end jugement buttons fuctions ------------------

	toggleUploadFileModal = () => {
		this.setState(prevState => {
			return {
				isOpenFileUploadModal: !prevState.isOpenFileUploadModal
			}
		});
	};

	toggleFeeDetailsModal = () => {
		this.setState(prevState => {
			return {
				isOpenFeeDetailsModal: !prevState.isOpenFeeDetailsModal
			}
		});
	}

	toggleCancelFeeModal = () => {
		this.setState(prevState => {
			return {
				isCancelFeeModal: !prevState.isCancelFeeModal
			}
		});
	};

	handleEditorSubmit = async (e) => {
		e.preventDefault();
		this.setState({editorContent: this.getEditorData()}, () => {
			this.props.onChangeHandler(
				this.state.editorContent,
				this.state.editorField,
				this.state.evaluationId
			);
			this.toggle();
		});
	};

	openUploadFileModal = (evaluationId, type, modalTitle) => {
		this.setState({ evaluationId, type, modalTitle }, () => this.toggleUploadFileModal());
	};

	openFeeDetailsModal = (data, type, feeId) => {
		const fee = {details: [], type, ...data};
		this.setState({ fee, feeId }, () => {
			this.toggleFeeDetailsModal();
			if (!this.state.fee.details.length) {
				this.addFee();
			}
		});
	}

	openCancelFeeModal = (cancel) => {
		if (cancel.id) {
			this.setState({cancelFee: cancel.fee, cancelFeeId: cancel.id}, () => {
				this.toggleCancelFeeModal();
			});
		}
	}

	openEditor = (evaluationId, evaluation, editorField, editorTitle) => {
		apiFindFactoryEvaluation(evaluationId).then(item => {
			let editorContent = item.data[editorField];
			this.setState({
				...{submitLock: false},
				evaluationId,
				evaluation,
				editorField,
				editorContent,
				editorTitle,
			}, () => this.toggle());
		});
	};

	addFee = () => {
		if (this.state.fee) {
			let fee = {...this.state.fee};
			fee.details.push({id: null, title: null, fee : null});
			this.setState({fee});
		}
	}

	updateFee = (field, value, key) => {
		if (this.state.fee) {
			let fee = {...this.state.fee};
			fee.details[key][field] = value;
			this.setState({fee});
		}
	}

	removeFee = (key) => {
		if (this.state.fee) {
			let fee = {...this.state.fee};
			fee.details.splice(key, 1);
			this.setState({fee});
		}
	}

	submitFee = (data) => {
		apiSaveFeeDetail(this.state.fee, this.state.feeId).then(() => {
			this.props.refresh();
			Message.success(
				this.props.t('global.saveSuccess'),
				this.props.t('global.success')
			);
			this.toggleFeeDetailsModal();
		});
	}

	updateCancelFee = (fee) => {
		this.setState({cancelFee: fee})
	}

	submitCancelFee = (data) => {
		apiQuickUpdateFactoryEvaluationFee(this.state.cancelFeeId, {fee: this.state.cancelFee}).then(() => {
			this.props.refresh();
			Message.success(
				this.props.t('global.saveSuccess'),
				this.props.t('global.success')
			);
			this.toggleCancelFeeModal();
		});
	}

	render() {
		const { t, ...otherProps } = this.props;
		const { editorContent, editorTitle, editorField, evaluationId } = this.state;
		return (
			<React.Fragment>
				<Draggable handle='.modal-header'>
				<Modal backdrop="static" size="xl" isOpen={this.state.isOpenModal} toggle={this.toggle}>
					<Form onSubmit={this.handleEditorSubmit} ref="form" labelWidth="150">
						<ModalHeader toggle={this.toggle} style={{cursor: 'grab'}}>
							{editorTitle}
						</ModalHeader>
						<ModalBody className="user-container scrollable-modal">
							{this.state.evaluationId && <EditorSync
								t={t}
								id={evaluationId}
								name={editorField}
								text={editorContent}
								pull={callback => this.getEditorData = callback}
								mute={!Websocket.isEnable()}
								lock={() => this.setState({submitLock: true})}
								unlock={() => {
									apiFindFactoryEvaluation(evaluationId).then(item => {
										this.setState({
											submitLock: false,
											editorContent: item.data[editorField]
										});
									});
								}}
								wsEvent={'EvaluationFieldEditing'}
								wsChannel={`evaluation_editing_${editorField}.${evaluationId}`}
								userName={this.props.userName}
							/>}
						</ModalBody>
						<ModalFooter>
							<Form.Item>
								<Button onClick={this.toggle}>
									{t('global.cancel')}
								</Button>
								<Button type="primary" nativeType="submit" disabled={this.state.submitLock}>
									{t('global.submit')}
								</Button>
							</Form.Item>
						</ModalFooter>
					</Form>
				</Modal>
				</Draggable>
				<ConfirmationModal
					isOpen={this.state.openConfirmationModal}
					handleConfirm={this.handleConfirm}
					handleCancel={this.handleCancel}
					message={this.state.modalMessage}
					type={this.state.type}
				/>
				{this.state.isOpenFileUploadModal && <UploadFileModal
					id={evaluationId}
					type={this.state.type}
					refresh={this.props.refresh}
					modalTitle={this.state.modalTitle}
					isOpen={this.state.isOpenFileUploadModal}
					toggleUploadModal={this.toggleUploadFileModal}
				/>}
				<FeeDetailsModal
					fee={this.state.fee}
					title={'misc' === this.state.fee.type ? t('factoryEvaluation.miscFeeTitle') : t('factoryEvaluation.careFeeTitle')}
					isOpen={this.state.isOpenFeeDetailsModal}
					toggle={this.toggleFeeDetailsModal}
					addFee={this.addFee}
					submitFee={this.submitFee}
					updateFee={this.updateFee}
					removeFee={this.removeFee}
					refresh={this.props.refresh}
				/>
				<CancelFeeModal
					isOpen={this.state.isCancelFeeModal}
					cancelFee={this.state.cancelFee}
					update={this.updateCancelFee}
					toggle={this.toggleCancelFeeModal}
					submit={this.submitCancelFee}
				/>
				<FactoryEvaluationDefault
					preferences={this.props.preferences}
					handleEvalResultChange={this.handleEvalResultChange}
					confirmJudgement={this.confirmJudgement}
					updateJudgement={this.updateJudgement}
					cancelEdit={this.cancelEdit}
					openEditor={this.openEditor}
					openCancelFeeModal={this.openCancelFeeModal}
					openUploadFileModal={this.openUploadFileModal}
					openFeeDetailsModal={this.openFeeDetailsModal}
					{...otherProps}
				/>
			</React.Fragment>
		)
	}
}

export default withNamespaces()(FactoryEvaluationList);
