import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input } from '../Common';
import { apiSaveProductSubCategory } from "../../api/product-sub-category";
import Message from '../../utils/Message';

export class ProductSubCategoryEditForm extends Component {
    state = {
        isSubmitting: false,
        data: {
            id: this.props.productSubCategory.id,
            name_jp: this.props.productSubCategory.name_jp,
            name_en: this.props.productSubCategory.name_en
        },
        error: {
            hasErrors: false,
            fields: []
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            let data = { ...this.state.data };
            data.id = this.props.productSubCategory.id;
            data.name_jp = this.props.productSubCategory.name_jp;
            data.name_en = this.props.productSubCategory.name_en;
            await this.setState({ data })
        }
    }

    onChangeName = (value, lang) => {
        const data = { ...this.state.data };
        data[`name_${lang}`] = value;
        this.setState({ data })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ isSubmitting: true });

        if (this.state.data.name_en && this.state.data.name_jp) {
    	      await apiSaveProductSubCategory(this.state.data).then((res) => {
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                    error: {
                        hasErrors: false,
                        fields: []
                    }
                })
                Message.success(this.props.t('global.success'))
            })
            await this.props.onCancel();
            await this.props.refresh();

        } else {
            let invalidFields= [];
            if (!this.state.data.name_en) {
                invalidFields.push('name_en');
            }
            if (!this.state.data.name_jp) {
                invalidFields.push('name_jp');
            }
            await this.setState({
                isSubmitting: false,
                error: {
                    hasErrors: true,
                    fields: invalidFields
                }
            })
        }
    }

    render() {
        const { t } = this.props;
        const { error } = this.state;
        const data = this.state.data;

        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.isOpen} toggle={this.props.onCancel}>
                <ModalHeader toggle={this.props.onCancel}>Edit Product Sub Category</ModalHeader>
                <ModalBody>
                    <Layout.Row>
                        <Layout.Col span="3" offset="2" style={{ textAlign: 'center' }}>
                            <span style={{ lineHeight: '38px' }}>{t('global.name_jp')}</span>
                        </Layout.Col>
                        <Layout.Col span="16">
                            <Input
                                value={data.name_jp ? data.name_jp : ''}
                                onChange={(value) => this.onChangeName(value, 'jp')}
                            />
                            {error.hasErrors && error.fields.includes('name_jp') &&
                                <span style={{ color: 'red' }}>*This field is required!</span>
                            }
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                        <Layout.Col span="3" offset="2" style={{ textAlign: 'center' }}>
                            <span style={{ lineHeight: '38px' }}>{t('global.name_en')}</span>
                        </Layout.Col>
                        <Layout.Col span="16">
                            <Input
                                value={data.name_en ? data.name_en : ''}
                                onChange={(value) => this.onChangeName(value, 'en')}
                            />
                            {error.hasErrors && error.fields.includes('name_en') &&
                                <span style={{ color: 'red' }}>*This field is required!</span>
                            }
                        </Layout.Col>
                    </Layout.Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.onCancel}>{t('global.cancel')}</Button>
                    <Button type="primary" onClick={this.handleSubmit}>
                        {this.state.isSubmitting ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.submit')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withNamespaces()(ProductSubCategoryEditForm);
