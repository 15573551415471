export const BASE_API = process.env.REACT_APP_BASE_API;
export const filePath = process.env.REACT_APP_BASE_URL;

export const contactTypes = [
    {id: 1, name: 'CP1'},
    {id: 2, name: 'CP2'},
    {id: 3, name: 'CP3'},
    {id: 4, name: 'CP4'}
];

export const SEVENPDepartments = [
    {id: 1, name: 'セブンプレミアム開発本部', type: 'default'},
    {id: 2, name: 'セブンプレミアム品質管理部', type: 'other'}
];
