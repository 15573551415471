import React, { Component } from 'react';
import { DatePicker as DPicker } from 'element-react';

class DatePicker extends Component {

    onBlurHandler = (e) => {
        if (e && e.state && e.state.value === null && this.props.value !== null) {
            this.props.onChange(null);
        }
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }
    
    render() {
        return (
            <DPicker
                {...this.props}
                onBlur={this.onBlurHandler}
            />
        )
    }
}

export default DatePicker;
