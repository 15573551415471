import { updateObject, paginationData } from '../../utils/utility';
import { FETCH_WORKING_GROUP_REQUEST, FETCH_WORKING_GROUP_SUCCESS, FETCH_WORKING_GROUP_FAILURE, FILTER_WORKING_GROUP } from './working-group.action.types';

const initialState = {
    filterData: {
        ...paginationData
    },
    loading: true,
    workingGroups: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKING_GROUP_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_WORKING_GROUP_SUCCESS:
        return getWorkingGroup(state, action);
    case FETCH_WORKING_GROUP_FAILURE:
        return {
            ...state,
            loading: true,
            workingGroups: [],
            error: action.payload,
        }
    case FILTER_WORKING_GROUP: return filterWorkingGroup(state, action);
    default: return state;
  }
};

const getWorkingGroup = (state, action) => updateObject(state, {
    workingGroups: action.payload,
    loading: false,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterWorkingGroup = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;
