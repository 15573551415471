import React from 'react';
import { Table } from 'reactstrap';
import { TableActions } from '../Common';
import { withNamespaces } from 'react-i18next';
import permission from 'utils/permission';

export const BusinessCompanyList = (props) => {
    const { businessCompanies, t } = props;
    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>{t('global.code')}</th>
                    <th>{t('global.name')}</th>
                    <th>{t('global.address')}</th>
                    <th>{t('company.country')}</th>
                    {permission('business-company:update,delete') && <th/>}
                </tr>
            </thead>
            <tbody>
                {businessCompanies.map((business) => (
                    <tr key={business.id}>
                        <td>{business.id}</td>
                        <td>{business.code}</td>
                        <td>{business.name}</td>
                        <td>{business.address}</td>
                        <td>{business.country && business.country.name}</td>
                        {permission('business-company:update,delete') &&
                            <td align="center">
                                <TableActions
                                    roleName="business-company"
                                    onSelect={() => props.onSelect(business)}
                                    onDelete={() => props.onDelete(business.id)}
                                />
                            </td>
                        }
                    </tr>
                ))}
                {!businessCompanies.length &&
                    <tr>
                        <td colSpan="8" align="center">
                            {t('global.notMatchingRecordsFound')}
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}

export default withNamespaces()(BusinessCompanyList);
