import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Button, Form, Layout} from 'element-react';
import { withNamespaces } from 'react-i18next';
import FeeDetails from "./FeeDetails";

function FeeDetailsModal({t, isOpen, toggle, fee, addFee, removeFee, updateFee, submitFee, title}) {
	return (
		<React.Fragment>
			<Form model={{}} rules={{}} labelWidth="160">
				<Modal backdrop="static" size="lg" isOpen={isOpen}>
					<ModalHeader>
						{title}
					</ModalHeader>
					<ModalBody className="user-container scrollable-modal">
						<Layout.Row>
							{fee.type && fee.details.map((item, key) => <FeeDetails
								key={key}
								defaultTitle={'care' === fee.type ? '交通費' : null}
								id={key}
								item={item}
								removeFee={removeFee}
								updateFee={updateFee}
							/>)}
							<Layout.Col span="20">
								<Button type="text" className="pull-right button-add-icon" onClick={addFee}>
									<em className="mr-2 fas fa-plus-circle"/>
									{t('factoryEvaluation.addFee')}
								</Button>
							</Layout.Col>
						</Layout.Row>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'center'}}>
						<Button onClick={toggle}>
							{t('global.cancel')}
						</Button>
						<Button type="primary" nativeType="submit" onClick={submitFee}>
							{t('global.submit')}
						</Button>
					</ModalFooter>
				</Modal>
			</Form>
		</React.Fragment>
	)
}

export default withNamespaces()(FeeDetailsModal);
