
export default function (user, name) {
	let result = false;
	user.tags.forEach(tag => {
		if (name === tag) {
			result = true;
		}
	});
	return result;
};
