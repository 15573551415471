import axios from '../config/axios';

export function apiGetVendorFactory(params) {
  return axios.get('vendor-factories', { params });
}

export function apiFindVendorFactory(id) {
  return axios.get(`vendor-factories/${id}`);
};

export function apiDeleteVendorFactory(id) {
  return axios.delete(`vendor-factories/${id}`);
}

export function apiSaveVendorFactory(data) {
  if (data.id) {
    return axios.put(`vendor-factories/${data.id}`, data);
  } else {
    return axios.post('vendor-factories', data);
  }
}

export function apiGetVendorCompanyContacts(ids) {
	return axios.get('vendor-factories/get-vendor-company-contacts', {params: {id: ids.join(',')}});
}

export function apiGetFilters(name, params) {
    return axios.get(`vendor-factories/filters/${name}`, {params});
};
