import React from "react";
import {withNamespaces} from "react-i18next";
import PropTypes from "prop-types";

const Fee = ({value, alignRight}) => {
	return (
		<span className={alignRight ? 'fee-align-right' : ''}>
			{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(value)}
		</span>
	)
}


Fee.propTypes = {
	fee: PropTypes.number,
	alignRight: PropTypes.bool
}

Fee.defaultProps = {
	alignRight: true
};

export default withNamespaces()(Fee);
