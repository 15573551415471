import { isEuropeOrUs, isCertificateValid, isDomestic } from "./utility";

let options = [];

function idToIndex(id) {
    return id.toString().slice(1)-1;
}

export function filterEvaluationResult(items, original) {
    if (isEuropeOrUs(original.vendor_factory.country) && original.vendor_factory.code) {
        options = [items[0]];
        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
        return options;
    }
    if (isCertificateValid(original.expiration_date)) {
        options = [items[1]];
        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
        return options;
    }
    if (original.exception_flag_id === 10) {
        options = [items[2]];
        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
        return options;
    }
    // Total status exceptional case:
    if (24 === original.total_status_id) {
        options = [items[20], items[21], items[22], items[23], items[24]];
        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
        return options;
    }
    //Audit Category: Regular Audit (First time) [1] && Regular Audit (Renewal) [2]
    if ([1,2].includes(original.audit_category_id)) {
        //Audit type 1 and 3
        if ([1,3].includes(original.audit_type_id)) {
            //Base on latest_evaluation_score
            if ([8, 9, 10, 11, 12, 13, 14, 15].includes(original.latest_evaluation_score_id)) {
                //Base on location
                if (isDomestic(original)) {
                    //Base on total status
                    if ([8,9,10,11,12,13].includes(original.total_status_id)) {
                        options = [items[7], items[13], items[8], items[9]];
                        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                        return options;
                    }
                    if (14 === original.total_status_id) {
                        options = [items[10], items[11], items[12], items[13], items[8], items[9]];
                        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                        return options;
                    }
                }
                if (!isDomestic(original)) {
                    //Base on total status
                    if ([8,9,10,11,12,13,14,23].includes(original.total_status_id)) {
                        options = [items[15], items[16], items[17], items[18], items[19], items[13], items[8], items[9]];
                        original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                        return options;
                    }
                }
            }
            //Base on total status
            if ([2, 3, 4, 5, 6, 7].includes(original.total_status_id)) {
                options = [items[3], items[4], items[5], items[6], items[13], items[8], items[9]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
            if ([8, 9, 10, 11, 12, 13].includes(original.total_status_id)) {
                options = [items[7], items[13], items[8], items[9]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
        }
        //Audit type exceptional case
        if (1 === original.audit_type_id && [14, 15, 16, 17, 18, 19, 20, 21].includes(original.total_status_id)) {
            options = [items[10], items[11], items[12],  items[13], items[8], items[9]];
            original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
            return options;
        }
        if (3 === original.audit_type_id && [14,21].includes(original.total_status_id)) {
            options = [items[14], items[15], items[13], items[8], items[9]];
            original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
            return options;
        }
    }
    //Audit Category: Re-audit
    if (3 === original.audit_category_id) {
        if (1 === original.audit_type_id) {
            if ([8, 9, 10, 11, 12, 13, 14, 15].includes(original.latest_evaluation_score_id)) {
                options = [items[29], items[30], items[31], items[32], items[13], items[26], items[27]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
            if ([14,15,21].includes(original.total_status_id)) {
                options = [items[12], items[13], items[26], items[27]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
        }
        if (3 === original.audit_type_id) {
            if ([14,21].includes(original.total_status_id)) {
                options = [items[28], items[13], items[26], items[27]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
        }
        if ([1,3].includes(original.audit_type_id)) {
            if ([22, 25, 26].includes(original.total_status_id)) {
                options = [items[33], items[20], items[21], items[22], items[23], items[24], items[13], items[26], items[27]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
            if ([27, 28, 30].includes(original.total_status_id)) {
                options = [items[25], items[13], items[26], items[27]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
        }
    }
    //Audit Category: Re-re-audit
    if (4 === original.audit_category_id) {
        if (1 === original.audit_type_id) {
            if ([14,15,16].includes(original.total_status_id)) {
                options = [items[12], items[13], items[8], items[9]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
        }
        if ([1,3].includes(original.audit_type_id)) {
            if ([8, 9, 10, 11, 12, 13, 14, 15].includes(original.latest_evaluation_score_id)) {
                options = [items[13], items[8], items[9]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
            if ([29, 30, 31, 32].includes(original.total_status_id)) {
                options = [items[33], items[34], items[35], items[13], items[8], items[9]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
            if ([33, 34, 35].includes(original.total_status_id)) {
                options = [items[36], items[13], items[8], items[9]];
                original.evaluation_result_id && !options.includes(items[idToIndex(original.evaluation_result_id)]) && options.unshift(items[idToIndex(original.evaluation_result_id)]);
                return options;
            }
        }
    }
}
