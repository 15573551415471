export const TOGGLE_SETTING = 'TOGGLE_SETTING';
export const CHANGE_SETTING = 'CHANGE_SETTING';
export const CHANGE_LANGUAGGE = 'CHANGE_LANGUAGGE';
export const CHANGE_EVALUATION_FREEZE_PANE = 'CHANGE_EVALUATION_FREEZE_PANE';


/**
 * Change a setting value
 * payload.name: name of the setting prop to change
 * payload.value: new value to apply
 */
export function changeSetting(name, value) {
    return { type: CHANGE_SETTING, name, value };
}

/**
 * Toggle a setting value (only boolean)
 */
export function toggleSetting(name) {
    return { type: TOGGLE_SETTING, name };
}

export function changeLanguage(language) {
    return { type: CHANGE_LANGUAGGE, language };
}

/**
 * Toggle a setting value (only boolean)
 */
export function changeFreezePane(freezePaneIndex) {
    return { type: CHANGE_EVALUATION_FREEZE_PANE, freezePaneIndex };
}