import { whereInFilter } from './utility';

export class FilterHierarchy {
	constructor(data) {
		this.data = data;
		this.hierarchies = [];
	}
	addHierarchy(name) {
		this.hierarchies.push(name);
	}
	getQuery(name) {
		let query = {};
		if (typeof name === 'undefined') {
			this.hierarchies.map(item => {
				if (this.data.hasOwnProperty(item)) {
					query = {...query, ...whereInFilter(item, this.data[item])};
				}
				return item;
			});
			return query;
		}

		if (this.hierarchies.includes(name)) {
			this.hierarchies.map(item => {
				const isNotSelf = this.data.hasOwnProperty(item) && item !== name;
				const isNotHigher = this.calcPriority(name) > this.calcPriority(item);
				if (isNotSelf && isNotHigher) {
					query = {...query, ...whereInFilter(item, this.data[item])};
				}
				return item;
			});
		}
		return query;
	}
	calcPriority(name) {
		let level = null;
		this.hierarchies.forEach((item, i) => {
			if (name === item) {
				level = i;
			}
		});
		return level;
	}
}
