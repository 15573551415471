import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Editor from './Editor';
import Websocket from '../../config/echo';
import { apiSend } from '../../api/live';

class EditorSync extends React.Component {
	timeout = null;
	interval = null;
	initiator = false;

	state = {
		user: '',
		lock: false,
		sync: false,
	};

	constructor(props) {
		super(props);
		this.ping = this.ping.bind(this);
		this.listen = this.listen.bind(this);
		this.runProcess = this.runProcess.bind(this);
	}

	componentDidMount() {
		if (!this.props.mute) {
			this.listen();
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (!this.props.commit && nextProps.commit) {
			this.destroyPing();
		}
	}

	componentWillUnmount() {
		this.destroyPing();
	}

	destroyPing() {
		clearInterval(this.interval);
		this.interval = null;
		this.initiator = false;
	}

	ping() {
		const { id, name, subject } = this.props;
		if (!this.interval && !this.props.mute) {
			this.initiator = true;
			apiSend(id, name, subject);
			this.interval = setInterval(() => apiSend(id, name, subject), 2000);
		}
	}

	listen() {
		Websocket.run(websocket => {
			const { wsChannel, wsEvent } = this.props;
			websocket
				.private(wsChannel)
				.listen(wsEvent, (e) => {
					return !this.initiator && this.runProcess(e);
				});
		});
	}

	runProcess(e) {
		this.setState({lock: true, user: e.user}, () => {
			this.props.lock();
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.setState({lock: false, sync: true}, () => {
					setTimeout(() => {
						this.setState({sync: false, user: ''});
						this.props.unlock();
					}, 2000);
				});
			}, 8000);
		});
	}

	render() {
		const { lock, sync, user } = this.state;
		const { text, name, pull, share, onlyRead, userName, t } = this.props;
		return (
			<div>
				<Editor
					t={t}
					text={text}
					name={name}
					pull={pull}
					lock={onlyRead || (typeof share === 'boolean' && share) || lock}
					sync={sync}
					user={user}
					userName={userName}
					onFocus={this.ping}
				/>
			<p style={{ color: '#ff0000', padding: '10px 0' }}>※コメント入力の際にベージュ色の箇所をクリックして頂くと入力欄の一番上に入力年月日、氏名が自動入力されます。</p>
			</div>
		)
	}
}

EditorSync.propTypes = {
	id: PropTypes.number,
	mute: PropTypes.bool,
	commit: PropTypes.bool,
	share: PropTypes.bool,
	name: PropTypes.string,
	text: PropTypes.string,
	wsEvent: PropTypes.string,
	wsChannel: PropTypes.string,
	pull: PropTypes.func,
	lock: PropTypes.func,
	unlock: PropTypes.func,
	onlyRead: PropTypes.bool,
	userName: PropTypes.string,
	subject: PropTypes.string,
};

EditorSync.defaultProps  = {
	subject: 'evaluation',
	onlyRead: false,
	name: 'comment',
	mute: false,
	share: false,
};

export default withNamespaces()(EditorSync);
