import { apiGetBusinessCompany } from '../../api/business-company';
import { FETCH_BUSINESS_COMPANY, FILTER_BUSINESS_COMPANY } from './business-company.action.types';

const fetchBusinessCompanySuccess = (result) => ({
    type: FETCH_BUSINESS_COMPANY,
    businessCompanies: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchBusinessCompany = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetBusinessCompany(filterData);
            dispatch(fetchBusinessCompanySuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterBusinessCompany = (filterData) => ({
    type: FILTER_BUSINESS_COMPANY,
    filterData
});