import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { connect } from 'react-redux';
import { Button, Layout, Form, Input, Loading } from 'element-react';
import { withFilter } from '../../hoc';
import { Pagination } from '../../components/Common';
import * as actions from './user.actions';
import { apiDeleteUser, apiSaveUser, apiUnlockUser } from '../../api/user';
import { UserForm, UserList } from '../../components/User';
import { UserModel } from '../../models';
import permission from '../../utils/permission';
import Message from '../../utils/Message';
import DownloadCsv from '../../components/Common/DownloadCsv';

class User extends Component {
    _isMounted = false;
    state = {
        filters: {},
        firstLoading: true
    }

    componentDidMount() {
        this._isMounted = true;
        this.filter();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDelete = async (id) => {
        await this.props.onDelete(id, apiDeleteUser);
        this.filter();
    }

	onUnlock = (event, user) => {
		event.preventDefault();
    	apiUnlockUser(user.id).then(() => {
		    this.filter();
		    Message.success(
			    this.props.t('global.saveSuccess'),
			    this.props.t('global.success')
		    );
	    });
	}

    onSubmit = async () => {
        await this.props.onSubmit(UserModel, apiSaveUser);
        this.filter();
    }

    onSelect = (selectedItem) => {
        const temp = { ...selectedItem };
        if (temp.birthday) {
            temp.birthday = new Date(temp.birthday);
        }
        if (temp.business_company_ids.length) {
            temp.assigned_roles = temp.business_company_ids.map((bc_id, idx) => {
                const product_categories = temp.product_categories;
                const product_category_ids = product_categories.map(item => item.id);
                const vendor_companies = temp.vendor_companies;
                const vendor_company_ids = vendor_companies.map(item => item.id);
                const vendor_factories = temp.vendor_factories;
                const vendor_factory_ids = vendor_factories.map(item => item.id);
                return {
                    business_company_id: bc_id,
                    business_companies: temp.business_companies[idx],
                    product_categories,
                    product_category_ids,
                    vendor_company_ids,
                    vendor_companies,
                    vendor_factory_ids,
                    vendor_factories,
                    productCategoryList: []
                }
            });
        } else {
            temp.assigned_roles = [{
                business_company_id: null,
                vendor_companies: [],
                vendor_company_ids: [],
                vendor_factories: [],
                vendor_factory_ids: [],
                product_categories: [],
                product_category_ids: [],
                productCategoryList: []
            }];
        }
        this.props.onSelect(temp);
    }

    filter = async (params) => {
        const filterColumns = [
            'company_name',
            'user_name',
            'image',
            'email',
            'phone',
            'gender'
        ];

        const newFilter = { ...this.props.filterData, ...params };
        let filter = {
            limit: newFilter.limit,
            page: newFilter.page,
            ...this.props.likeFilter('name', newFilter.name)
        };
        filterColumns.forEach(column => filter = {
            ...filter,
            ...this.props.whereInFilter(column, newFilter[column])
        });
        await this.props.fetchUser(filter);
        if (this._isMounted) {
            this.setState({ filters: filter, firstLoading: false });
        }
    }

    onChangeWithFilter = (value, key) => {
        this.props.onChangeWithFilterAndResetPage(value, key);
        this.setState(prevState => ({
            isRenderList: !prevState.isRenderList
        }));
    };

    render() {
        const { filterData, users, t } = this.props;
        return (
            <ContentWrapper>
                <UserForm
                    data={this.props.selectedItem}
                    visible={this.props.dialogVisible}
                    onChangeHandler={this.props.onChangeHandler}
                    onChangeState={this.props.onChangeState}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.dialogHandler}
                />
                <div className="content-heading">
                    <div>
	                    {t('sidebar.nav.userMaster')}
                    </div>
                </div>
                <div className='user-list'>
                <Layout.Row>
                    <Layout.Col sm="12">
                        <Form inline={true}>
                            <Form.Item>
                                <Input
	                                value={this.props.filterData.name}
	                                icon="search"
	                                placeholder={t('global.search')}
	                                onChange={this.props.onTextSearch}
                                />
                            </Form.Item>
                            <Form.Item>
                                {permission('user:create') &&
                                    <Button type="primary" onClick={() => this.props.onCreate(UserModel)}>
	                                    {t('user.add')}
                                    </Button>
                                }
                            </Form.Item>
                        </Form>
                    </Layout.Col>
                    <Layout.Col sm="12">
                        <div className="pagination-right">
                            <Pagination
                                pageCount={filterData.totalPage}
                                total={filterData.total}
                                pageSize={filterData.limit}
                                initialPage={filterData.page}
                                onPageChange={this.props.onPageChange}
                            />
                            {permission('user-export:view') &&
                                <div className="ml-2">
                                    <DownloadCsv
                                        lang={this.props.lng}
                                        subject={'users'}
                                        filters={this.state.filters}
                                    />
                                </div>
                            }
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Loading loading={this.state.firstLoading && !this.props.users.length}>
                    <UserList
                        users={users}
                        filterData={filterData}
                        onSelect={this.onSelect}
                        onDelete={this.onDelete}
                        onUnlock={this.onUnlock}
                        refresh={this.filter}
                        onChangeWithFilter={this.onChangeWithFilter}
                        language={this.props.language}
                    />
                </Loading>
                </div>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.user.users,
        language: state.settings.language,
        filterData: state.user.filterData
    };
};

const mapDispatchToProps = dispatch => ({
    fetchUser: (params) => dispatch(actions.fetchUser(params)),
    setReduxFilter: (params) => dispatch(actions.setFilterUser(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(User)));
