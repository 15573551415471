import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares';
import { persistStore, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { updateClasses } from './middlewares/settings.middleware';
// import { persistedState, saveState } from './persisted.store.js';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const encryptor = createEncryptor({
    secretKey: process.env.REACT_APP_PERSIT_SECRET_KEY,
    onError: function(error) {
        console.log(error)
    }
});

const persistConfig = {
    transforms: [encryptor],
    key: 'root',
    storage,
    whitelist: ['settings', 'theme']
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
    persistedReducer,
    // persistedState, // second argument overrides the initial state
    {},
    composeEnhancers(applyMiddleware(thunk, ...middlewares))
);

updateClasses(store.getState());

const persistor = persistStore(store);

export {
    store,
    persistor
};

export default store;