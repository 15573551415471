import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'element-react';
import Draggable from 'react-draggable';

const ScapPreviewModal = (props) => {
    const { t } = props
    return (
        <React.Fragment>
          <Draggable handle='.modal-header'>
          <Modal size='xl' backdrop={false} isOpen={props.isOpen} className="cert-preview">
              <ModalHeader style={{ justifyContent: 'flex-end', paddingBottom: '0px' }}>
                  <Button onClick={() => props.toggle()}>{t('certificationIssuance.close')}</Button>
              </ModalHeader>
              <ModalBody className="user-container scrollable-modal">
                  <div style={{ width: '100%', height: '100%', minHeight: '600px' }}>
                  <iframe
                      title="some_unique_name_1"
                      style={{ minHeight: '1200px' }}
                      src={`/scap?file=/pdf?file=${props.path}`}
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                      toolbar='0'
                  />
                  </div>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
          </Modal>
          </Draggable>
        </React.Fragment>
    );
}

export default ScapPreviewModal;
